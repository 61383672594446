import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { openDialog, closeDialog } from '../../store/reducers/dialogReducer';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { mecGrey, mecPrimary } from '../../constants/theme/colors';

interface CustomDialogProps {
  dialogId: string;
  dialogButtonElement?: React.ReactNode;
  dialogTitle?: string;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  showDeleteButton?: boolean;
  onCancelButtonClick?: () => void;
  onConfirmButtonClick?: () => void;
  onDeleteButtonClick?: () => void;
  onClose?: () => void;
  handleCloseFromTop?: () => void;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  loader?: boolean;
  disableButton?: boolean;
  disableElementButton?: boolean;
  dialogWidth?: string;
  dialogHeight?: string;
  submitButtonType?: 'button' | 'submit' | 'reset' | undefined;
  disableBackDropClick?: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    borderRadius: '0.5rem',
    overflowY: 'auto',
    // maxHeight: "70vh",
    scrollbarWidth: 'auto',
    marginRight: '10px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    width: '100%',
    '&::-webkit-scrollbar-track': {
      borderRadius: '0.5rem',
      backgroundColor: mecGrey[250],
      padding: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mecPrimary[400],
      borderRadius: '0.5rem',
      padding: '3px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: mecPrimary[400],
    },
  },
  '& .MuiDialog-paper': {
    borderRadius: '0.5rem',
    minWidth: 'min-content',
    maxWidth: '100%',
    overflowY: 'visible',
  },
}));

const CustomDialog: React.FC<CustomDialogProps> = ({
  dialogId,
  dialogButtonElement,
  dialogTitle = '',
  children,
  showCloseButton = false,
  showCancelButton = false,
  showConfirmButton = false,
  showDeleteButton = false,
  onCancelButtonClick = () => null,
  onConfirmButtonClick = () => null,
  onDeleteButtonClick = () => null,
  onClose = () => null,
  cancelButtonLabel = 'Cancel',
  loader = false,
  confirmButtonLabel = 'Confirm',
  disableButton = false,
  disableElementButton = false,
  handleCloseFromTop = () => null,
  dialogHeight,
  dialogWidth,
  submitButtonType = 'button',
  disableBackDropClick = false,
}) => {
  const dispatch = useAppDispatch();
  const openDialogIds = useAppSelector(state => state.dialogReducer.openDialogIds);
  const open = openDialogIds.includes(dialogId);

  const handleClickOpen = () => {
    if (!disableElementButton) dispatch(openDialog(dialogId));
  };

  const handleClose = () => {
    if (onClose() !== null) {
      onClose();
    } else {
      dispatch(closeDialog(dialogId));
    }
  };

  return (
    <>
      <div className="w-max h-max" onClick={handleClickOpen}>
        {dialogButtonElement}
      </div>
      <BootstrapDialog
        onClose={(e, reason) => {
          console.log(e, reason);
          if (disableBackDropClick && reason === 'backdropClick') {
            return;
          } else {
            handleClose();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: { width: dialogWidth, height: dialogHeight },
        }}
      >
        {dialogTitle && (
          <DialogTitle
            sx={{ m: 0, p: 2, fontWeight: '700', textTransform: 'capitalize' }}
            id="customized-dialog-title"
          >
            {dialogTitle}
          </DialogTitle>
        )}
        {showCloseButton && (
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
              handleCloseFromTop();
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {showDeleteButton && (
          <IconButton
            aria-label="close"
            onClick={() => {
              onDeleteButtonClick();
            }}
            sx={{
              position: 'absolute',
              right: 48,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        <DialogContent sx={{ overflowY: 'auto !important' }}>{children}</DialogContent>
        {(showCancelButton || showConfirmButton) && (
          <div className="flex justify-end space-x-2 w-full p-5">
            {showCancelButton && (
              <Button variant="outlined" color="primary" onClick={onCancelButtonClick}>
                {cancelButtonLabel}
              </Button>
            )}
            {showConfirmButton && (
              <Button
                type={submitButtonType}
                variant="contained"
                color="primary"
                onClick={onConfirmButtonClick}
                disabled={disableButton || loader}
                startIcon={loader && <CircularProgress size={16} sx={{ color: 'white' }} />}
              >
                {confirmButtonLabel}
              </Button>
            )}
          </div>
        )}
      </BootstrapDialog>
    </>
  );
};

export default CustomDialog;
