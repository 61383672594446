import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { USER_ROLE } from "../../constants/app-constant";

export interface UserInterface {
  deleted?: boolean;
  Integer?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  role?: USER_ROLE;
  resourceId?: number;
  id?: number;
}

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  user: UserInterface | null;
  userPhoto: string | null;
  project: any | null;
  isAuthenticated: boolean;
  lastLoggedInAt: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  user: null,
  userPhoto: null,
  project: null,
  isAuthenticated: false,
  lastLoggedInAt: null,
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokens: (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isAuthenticated = true;
    },
    setUser: (state, action: PayloadAction<UserInterface>) => {
      state.user = action.payload;
    },
    setUserPhoto: (state, action: PayloadAction<string>) => {
      state.userPhoto = action.payload;
    },
    setProject: (state, action: PayloadAction<any>) => {
      state.project = action.payload;
    },
    setLastLoggedInAt: (state, action: PayloadAction<string>) => {
      state.lastLoggedInAt = action.payload;
    },
    clearAuth: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.userPhoto = null;
      state.project = null;
      state.isAuthenticated = false;
      state.lastLoggedInAt = null;
    },
  },
});

export const {
  setTokens,
  setUser,
  setUserPhoto,
  setProject,
  setLastLoggedInAt,
  clearAuth,
} = authReducer.actions;
export default authReducer.reducer;
