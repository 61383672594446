// import axios from "axios // Update the import path as necessary
import axiosInstance from "../utils/httpInterceptor";
import { authUserUrl, connectJiraUrl, getLoggedInUserUrl, userAccessTokenUrl } from "../constants/api-urls";

const isLoggedIn: boolean = false;

export interface UserInterface {
  deleted?: boolean;
  Integer?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  role?: string;
  resourceId?: number;
}

export function login(loginObject: any) {
  const headers = {
    Authorization: "Bearer " + loginObject?.idToken,
    skipToken: "true",
  };
  return axiosInstance.get(authUserUrl, { headers });
}

export function connectJira(obj: any) {
  return axiosInstance.post(
    connectJiraUrl,
    obj
  );
}

export async function setToken(accessToken: any) {
  await localStorage.setItem("accessToken", accessToken);
}

export async function setUser(user: any) {
  await localStorage.setItem("user", JSON.stringify(user));
}

export async function setUserPhoto(photoUrl: any) {
  await localStorage.setItem("photoUrl", photoUrl);
}

export async function setProjectDetails(project: any) {
  await localStorage.setItem("project", project);
}

export async function setRefreshToken(refreshToken: any) {
  await localStorage.setItem("refreshToken", refreshToken);
}

export async function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

export async function getUserPhoto() {
  return localStorage.getItem("photoUrl");
}

export async function deleteToken() {
  await localStorage.removeItem("accessToken");
  await localStorage.removeItem("refreshToken");
}

export async function getToken() {
  return localStorage.getItem("accessToken");
}

export function getUser() {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
}

export async function findUser() {
  return axiosInstance.get(getLoggedInUserUrl);
}

export async function getProjectDetails() {
  return localStorage.getItem("project");
}

export async function setAuthenticated(isLoggedIn: string) {
  await localStorage.setItem("isLoggedIn", isLoggedIn);
}

export async function setLastLoggedInAt(date: any) {
  await localStorage.setItem("lastLoggedInAt", date);
}

export async function getLastLoggedInAt() {
  return localStorage.getItem("lastLoggedInAt");
}

export async function updateToken() {
  return axiosInstance.get(userAccessTokenUrl);
}

export async function authUpdateAndReload(pageUrl?: any) {
  try {
    const res = await updateToken();
    if (res?.data?.accessToken && res?.data?.refreshToken) {
      await setToken(res.data.accessToken);
      await setRefreshToken(res.data.refreshToken);
      window.location.reload();
    } else {
      window.location.href = "/sign-in";
    }
  } catch (error) {
    window.location.href = "/sign-in";
  }
}

export async function clearStorage() {
  await localStorage.clear();
}
