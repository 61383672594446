// src/features/example/exampleSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getEmailListUrl, getNomineeListUrl, getStatCountUrl } from '../../constants/api-urls';
import axiosInstance from '../../utils/httpInterceptor';
import { authUpdateAndReload } from '../../services/authServices';
import { UserDetails } from '../../types/project';
import { FAIL_TO_FETCH_DATA } from '../../constants/messages';
import { FETCH_EMAIL_LIST, FETCH_STATE_COUNT } from '../../constants/reducer-constant';

interface StatInterface {
  projectCount: number;
  resourceCount: number;
  repoCount: number;
  qaCount: number;
  pmCount: number;
  designerCount: number;
  developerCount: number;
  vendorCount: number;
  devopsCount: number;
  hrCount: number;
  merketingCount: number;
  salesCount: number;
}

export interface NomineeList {
  nominatedBy?: string;
  nomineeName?: string;
  nominatedByName?: string;
  nominee?: string;
  nomineeResId?: number;
  reason?: string;
  award?: 'RISING_STAR' | 'SUPER_STAR';
}

interface DashboardState {
  statCount: StatInterface;
  emailList: UserDetails[];
  nomineeList: any[];
  loading: boolean;
  emailsLoading: boolean;
  error: string | null;
}

const initialState: DashboardState = {
  nomineeList: [],
  emailList: [],
  statCount: {
    projectCount: 0,
    resourceCount: 0,
    repoCount: 0,
    qaCount: 0,
    pmCount: 0,
    designerCount: 0,
    developerCount: 0,
    vendorCount: 0,
    devopsCount: 0,
    hrCount: 0,
    merketingCount: 0,
    salesCount: 0,
  },
  loading: false,
  emailsLoading: false,
  error: null,
};

// Define async thunk for API call
export const fetchStatCount = createAsyncThunk(FETCH_STATE_COUNT, async () => {
  const response = await axiosInstance.get<any>(getStatCountUrl);

  if (response.data?.tokenExpire) {
    authUpdateAndReload();
    return;
  }

  return response.data.data;
});

// Define async thunk for API call
export const fetchNomineeList = createAsyncThunk(
  'dashboard/fetchNomineeList',
  async (params: { month: number; year: string | number }) => {
    const response = await axiosInstance.post(getNomineeListUrl, params);
    return response.data.data;
  },
);

// Define async thunk for API call
export const fetchEmailList = createAsyncThunk(FETCH_EMAIL_LIST, async () => {
  const response = await axiosInstance.get(getEmailListUrl);
  return response.data.data;
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStatCount.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchStatCount.fulfilled, (state, action: PayloadAction<StatInterface>) => {
      state.loading = false;
      state.statCount = action.payload;
    });
    builder.addCase(fetchStatCount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });

    builder.addCase(fetchNomineeList.pending, state => {
      state.error = null;
    });
    builder.addCase(fetchNomineeList.fulfilled, (state, action: PayloadAction<NomineeList[]>) => {
      state.nomineeList = action.payload;
    });
    builder.addCase(fetchNomineeList.rejected, (state, action) => {
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });

    builder.addCase(fetchEmailList.pending, state => {
      state.emailsLoading = true;
      state.error = null;
    });
    builder.addCase(fetchEmailList.fulfilled, (state, action: PayloadAction<[]>) => {
      state.emailsLoading = false;
      state.emailList = action.payload;
    });
    builder.addCase(fetchEmailList.rejected, (state, action) => {
      state.emailsLoading = false;
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });
  },
});

export default dashboardSlice.reducer;
