import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import ActionBar from "../../components/ActionBar";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import CustomDialog from "../../components/Dialog";
import { DialogConstants } from "../../constants/app-constant";
import { closeDialog } from "../../store/reducers/dialogReducer";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import KraKpiTable from "./KraKpiTable";
import { useNavigate } from "react-router-dom";
import {
  getAllDesignation,
  getAllDesignationWithNoKraKpi,
} from "../../services/kraAndKpiServices";
import MuiBasicOutlinedSelect from "../../components/MuiBasicOutlinedSelect";
import {
  getKraAndKpiByDesignationAsync,
  setOperation,
} from "../../store/slices/Kra-Kpi/kra-kpi.slice";
import Breadcrumbs from "../../components/Breadcrumbs";
import svg from "../../assets";

export const KraAndKpi: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.kraAndKpiState.isLoading);
  const kraKpiByDesignation = useAppSelector(
    (state) => state.kraAndKpiState.kraKpiByDesignation
  );

  const [allDesignation, setAllDesignation] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);
  const [newKraKpiOptions, setNewKraKpiOptions] = useState([]);
  const [designation, setDesignation] = useState(menuOptions[0] || "");
  const [newDesignation, setNewDesignation] = useState(
    newKraKpiOptions[0] || ""
  );
  const findSingleDesignation = (roleAbbreviation: string) => {
    return allDesignation.find(
      (designation: any) => designation?.roleAbbreviation === roleAbbreviation
    );
  };

  useEffect(() => {
    getAllDesignation().then((response) => {
      setAllDesignation(response);
      const options = response.map((role: any) => ({
        label: role.role,
        value: role.roleAbbreviation,
      }));
      options.sort((a: any, b: any) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setMenuOptions(options);
      setDesignation(options[0]?.value || "");
      dispatch(
        getKraAndKpiByDesignationAsync({ designation: options[0]?.value })
      );
    });
    getAllDesignationWithNoKraKpi().then((response) => {
      const options = response.map((role: any) => ({
        label: role.role,
        value: role.roleAbbreviation,
      }));
      options.sort((a: any, b: any) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setNewKraKpiOptions(options);
      setNewDesignation(options[0]?.value || "");
    });
  }, []);
  return (
    <Layout>
      <ActionBar>
        <div className="w-full flex justify-between items-center text-sm">
          <div className="flex flex-row justify-center items-center space-x-2">
            <img
              src={svg["kraKpiIcon"]}
              alt="kra-kpi-icon"
              height={20}
              width={20}
            />
            <Breadcrumbs customCrumb={{ label: "KRA-KPI", path: "/kra-kpi" }} />
          </div>
          <CustomDialog
            dialogButtonElement={
              <Button variant="contained" color="primary" size="small">
                Add New
              </Button>
            }
            children={
              <MuiBasicOutlinedSelect
                sx={{ width: "100%" }}
                value={newDesignation}
                label="Designation"
                options={newKraKpiOptions}
                onChange={(e: any) => setNewDesignation(e.target.value)}
              />
            }
            showCancelButton={true}
            showConfirmButton={true}
            showCloseButton={false}
            dialogTitle="Create New KRAs & KPIs"
            dialogId={DialogConstants.dialogIds.CREATE_NEW_KRA_KPI_DIALOG}
            onCancelButtonClick={() =>
              dispatch(
                closeDialog(DialogConstants.dialogIds.CREATE_NEW_KRA_KPI_DIALOG)
              )
            }
            onConfirmButtonClick={() => {
              const additionalData = {
                operation: `Create`,
                designation: findSingleDesignation(newDesignation),
              };
              navigate(`/kra-kpi/${newDesignation}`, { state: additionalData });
              dispatch(setOperation("create"));
              dispatch(
                closeDialog(DialogConstants.dialogIds.CREATE_NEW_KRA_KPI_DIALOG)
              );
            }}
          />
        </div>
      </ActionBar>
      <div className="px-8">
        {/* table components  */}
        <div className="flex flex-row space-x-4 space-y-4 space-y-0 justify-end items-end">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              const additionalData = {
                operation: `Edit`,
                designation: findSingleDesignation(designation),
              };
              navigate(`/kra-kpi/${designation}`, { state: additionalData });
            }}
          >
            Edit
          </Button>
          <MuiBasicOutlinedSelect
            value={designation}
            label="Designation"
            sx={{ width: "256px" }}
            options={menuOptions}
            onChange={(e: any) => {
              setDesignation(e.target.value);
              dispatch(
                getKraAndKpiByDesignationAsync({ designation: e.target.value })
              );
            }}
          />
        </div>
        {isLoading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"80vh"}
          >
            <CircularProgress color={"primary"} />
          </Box>
        ) : (
          <Stack className="my-5">
            <KraKpiTable tableData={kraKpiByDesignation} />
          </Stack>
        )}
      </div>
    </Layout>
  );
};
