import {InputBase, MenuItem, styled as MuiStyled} from "@mui/material";
import { mecPrimary } from "../../constants/theme/colors";

export const BootstrapInput = MuiStyled(InputBase)(({ theme }) => ({
  // 'label + &': {
  //   marginTop: theme.spacing(3),
  // },
  "& .MuiInputBase-input": {
    borderRadius: theme.spacing(1),
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.common.black}`,
    fontSize: 16,
    height: "auto",
    padding: "0px 26px 0px 12px",
    fontFamily: ["Inter", "sans-serif"].join(","),
    "&:focus": {
      borderRadius: theme.spacing(1),
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export const StyledMenuItem = MuiStyled(MenuItem)(({ theme }) => ({
  minHeight: "38px",
  fontSize: "14px",
  padding: theme.spacing(1),
  "&.Mui-selected": {
    backgroundColor: mecPrimary[200],
    color: mecPrimary[500],
  },
  "&.MuiButtonBase-root:hover": {
    backgroundColor: mecPrimary[200],
    color: mecPrimary[500],
  },
  borderRadius: "10px",
}));