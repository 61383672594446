import React from "react";
import { LinearProgress } from "@mui/material";
import { RootState } from "../../store/rootReducer";
import { useSelector } from "react-redux";

const LoadingBar: React.FC = () => {
  const { show, mode, progress } = useSelector(
    (state: RootState) => state.loadingBar
  );
  if (!show) return null;

  return (
    <LinearProgress 
      sx={{
        position: 'absolute',
        width: '100vw',
        height: '4px',
        top: 0,
      }} 
      variant={mode}
      value={progress} 
      color="secondary"
    />
);
};

export default LoadingBar;
