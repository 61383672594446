import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserDetails } from "../../services";
import { HTTP_OK } from "../../constants/status-code-constant";
import { defaultExceptionHandler } from "../../utils/defaultExcpetionHandler";

interface UserState {
  userDetails: any;
  isLoading: boolean;
}

export const getUserDetailsAsync = createAsyncThunk(
  "user/getUserDetailsAsync",
  async () => {
    try {
      const response = await getUserDetails();

      if (response?.status !== HTTP_OK) {
        throw new Error(response?.data?.data?.error);
      }
      return response?.data?.data;
    } catch (error: any) {
      throw defaultExceptionHandler(error);
    }
  }
);
const initialState: UserState = {
  userDetails: null,
  isLoading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetailsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserDetailsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action?.payload;
      })
      .addCase(getUserDetailsAsync.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

// export const {  } = userSlice.actions;
export default userSlice.reducer;
