import {
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  TextFieldVariants,
  Typography,
} from '@mui/material';
import React, {
  ChangeEventHandler,
  ClipboardEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
} from 'react';
import { red, themePalette } from '../../constants/theme/colors';
interface Props {
  id?: string;
  isLabel?: boolean;
  label?: string;
  name?: string;
  isRequired?: boolean;
  type?: string;
  rows?: string | number;
  multiline?: boolean;
  className?: string;
  classes?: Object;
  style?: Object;
  sx?: Object;
  variant?: TextFieldVariants;
  autofocus?: boolean;
  color?: any;
  disabled?: boolean;
  endAdornment?: any;
  startAdornment?: any;
  inputProps?: any;
  placeholder: string;
  onChange: Function;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPaste?: ClipboardEventHandler<HTMLDivElement>;
  value?: string | number;
  fullWidth?: boolean;
  helperText?: string | undefined | any;
  error?: boolean;
  readOnly?: boolean;
  inputRef?: any;
  defaultValue?: string;
}
const InputField = ({
  id,
  isLabel = false,
  label = '',
  name = '',
  isRequired = false,
  type,
  rows,
  multiline = false,
  className,
  classes,
  style,
  sx,
  variant,
  autofocus,
  color,
  disabled = false,
  endAdornment,
  startAdornment,
  inputProps,
  placeholder = '',
  onChange = () => {},
  onKeyDown = () => {},
  onBlur,
  onFocus,
  onPaste,
  value = '',
  fullWidth,
  helperText = '',
  error = false,
  readOnly = false,
  inputRef,
  defaultValue,
}: Props) => {
  return (
    <Stack sx={{ width: 1 }}>
      <Typography variant="mec_body1" className="!mb-1">
        {label}
        {isRequired ? (
          <Typography variant="mec_body1" component="span" color={red[650]}>
            {'*'}
          </Typography>
        ) : null}
      </Typography>
      <TextField
        id={id ? id : name}
        type={type}
        className={className}
        classes={classes}
        error={error}
        style={{
          fontFamily: 'Inter var',
          ...(style && style),
        }}
        sx={sx}
        inputRef={inputRef}
        variant={variant ? variant : 'outlined'}
        autoComplete="off"
        autoFocus={autofocus}
        color={color}
        disabled={disabled}
        multiline={multiline}
        {...(rows && { rows: rows })}
        InputProps={{
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
        }}
        inputProps={{
          ...inputProps,
          form: {
            autocomplete: 'off',
          },
        }}
        placeholder={placeholder}
        onChange={(e: any) => {
          onChange(e);
        }}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={onPaste}
        value={value ? value : ''}
        fullWidth={fullWidth}
        name={name}
        helperText={
          <>
            {error && helperText ? (
              <Stack className="h-3">
                <Typography variant="mec_errorHelperText">{helperText}</Typography>
              </Stack>
            ) : null}
          </>
        }
        defaultValue={defaultValue}
      />
    </Stack>
  );
};
export default InputField;
