// src/features/example/exampleSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { formByIdUrl, getFormsUrl, projectByIDUrl, projectListUrl } from "../../constants/api-urls";
import axiosInstance from "../../utils/httpInterceptor";
import { authUpdateAndReload } from "../../services/authServices";
import { Project, ProjectAuthUser, ProjectSettings, TeamMember } from "../../types/project";
import { Form } from "../../types/form";
import { FAIL_TO_FETCH_DATA } from '../../constants/messages';

export const FORMS_PER_PAGE = 10;

interface ProjectState {
  formList: Form[];
  formData: {
    form: Form;
  } | null;
  totalForms: number;
  page: number;
  loading: boolean;
  hasMore: boolean;
  searching: boolean;
  searchString: string | null;
  initialFetched: boolean;
  error: string | null;
}

const initialState: ProjectState = {
  formList: [],
  formData: null,
  hasMore: false,
  page: 0,
  totalForms: 0,
  loading: false,
  error: null,
  searching: false,
  searchString: null,
  initialFetched: false,
};

// Define async thunk for API call
export const fetchFormList = createAsyncThunk(
  'form/fetchList',
  async (params: { page: number; size: number; name: string } | any) => {
    const response = await axiosInstance.get<any>(getFormsUrl, { params });

    if (response.data?.tokenExpire) {
      authUpdateAndReload();
      return;
    }

    return response.data.data;
  },
);

export const fetchForm = createAsyncThunk('form/fetch', async (id: number) => {
  const response = await axiosInstance.get<any>(`${formByIdUrl}/${id}`);

  if (response.data?.tokenExpire) {
    authUpdateAndReload();
    return;
  }

  return response.data.data;
});

const formReducer = createSlice({
  name: 'form',
  initialState,
  reducers: {
    resetForm: state => initialState,
    setFormPage: (state, action) => {
      return { ...state, page: action.payload };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFormList.pending, state => {
      state.error = null;
    });
    builder.addCase(fetchFormList.fulfilled, (state, action: PayloadAction<any>) => {
      state.formList = action.payload.isFirst
        ? action.payload.models
        : [...state.formList, ...action.payload.models];
      state.searching = false;
      state.initialFetched = true;
      state.hasMore = !action.payload.isLast;
      state.totalForms = action.payload.totalElements;
    });
    builder.addCase(fetchFormList.rejected, (state, action) => {
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });

    builder.addCase(fetchForm.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchForm.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.formData = {
        form: action.payload,
      };
    });
    builder.addCase(fetchForm.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });
  },
});

export const { resetForm, setFormPage } = formReducer.actions;
export default formReducer.reducer;
