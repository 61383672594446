import { Popper, TextField, styled } from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { mecPrimary } from "../../constants/theme/colors";

export const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    marginTop: "8px",
    boxSizing: "border-box",
    overFlowY: "auto",
    maxHeight: "350px",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: mecPrimary[500],
      borderRadius: "10px",
      height: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: mecPrimary[500],
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#F1F1F1",
    },
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "38px",
    paddingLeft: "40px",
    display: "flex",
    alignItems: "center",
    "& .MuiAutocomplete-input": {
      padding: "0px !important",
    },
  },
  "& .MuiInputAdornment-root": {
    position: "absolute",
    left: 8,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiAutocomplete-input": {
    height: "100%",
    lineHeight: "38px",
    fontSize: "14px",
    padding: "0px",
  },
  "& .MuiTextField-root": {
    height: "38px",
    margin: 0,
  },
});
