import React from 'react';
import MenuItemsList from '../MenuItemList';
import CustomTabs from '../CustomTabs';
import Badge from '../Badge';
import Button from '../Button';

import { useAppSelector } from '../../store/hooks';

import { closeProfileDrawer } from '../../store/reducers/profileDrawerReducer';

import {
  KeyboardArrowDownOutlined,
  PersonOutlineRounded,
  LogoutOutlined,
  ManageAccountsOutlined,
  Close,
} from '@mui/icons-material';
import { useAppDispatch } from '../../store/hooks';
import { logout } from '../../services/auth/auth.service';
import { RouteConstants } from '../../constants/route-constant';

const ProfileDrawer: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="w-[700px] h-screen bg-white relative">
        <div className="space-y-8 p-8 bg-white border-t-4 border-primary">
          <div className="flex space-x-5 w-full items-start">
            <div className="bg-primary rounded-full h-16 w-16 text-white flex justify-center items-center text-xl uppercase">
              mb
            </div>
            <div className="space-y-4 flex-1">
              <div className="flex justify-between">
                <h2 className="capitalize text-lg">mindbowser</h2>
                <Badge className="bg-green-100 text-green-600" title="online" />
              </div>
            </div>
          </div>
          <Button
            className="w-full rounded-lg py-1.5 text-sm"
            label="write standup"
            variant="primary"
          />
        </div>
      </div>
      <div
        className="absolute top-2 left-1 hover:bg-gray-extralight p-1 rounded-full"
        onClick={() => dispatch(closeProfileDrawer())}
      >
        <Close />
      </div>
    </>
  );
};

export const AccountButton: React.FC = () => {
  return (
    <div className="flex items-center space-x-1 p-1 border border-secondary-light rounded-full">
      <p className="h-6 w-6 text-xs text-white flex bg-primary rounded-full justify-center items-center">
        MB
      </p>
      <KeyboardArrowDownOutlined fontSize="small" className="text-white" />
    </div>
  );
};

export const AccountMenuItems: React.FC = () => {
  const user = useAppSelector(state => state.auth.user);
  const accountMenuItems: {
    label: string;
    icon: React.ReactNode;
    to?: string;
    onClick?: () => void;
  }[] = [
    {
      label: 'profile',
      icon: <PersonOutlineRounded fontSize="small" />,
      to: `/profile/details/${user?.resourceId}`,
    },
    {
      label: 'change password',
      icon: <ManageAccountsOutlined fontSize="small" />,
      to: RouteConstants.ROUTE_HOME,
    },
    {
      label: 'logout',
      icon: <LogoutOutlined fontSize="small" />,
      onClick() {
        logout();
      },
    },
  ];

  return (
    <div className="p-2 space-y-3">
      <div className="flex items-center space-x-2 px-2">
        <p className="h-9 w-9 text-sm font-bold text-white flex bg-primary rounded-full justify-center items-center">
          MB
        </p>
        <div className="space-y-1">
          <p className="text-sm font-medium">Mindbowser</p>
          <p className="text-xs font-light text-mecGrey-600">Online</p>
        </div>
      </div>
      <hr className="w-full" />
      <div className="w-full">
        <MenuItemsList items={accountMenuItems} />
      </div>
    </div>
  );
};
