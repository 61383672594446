import React from "react";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

interface TableSkeletonProps {
  columns: number;
  rows: number;
  cellHeight?: number | string;
  cellWidth?: number | string;
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({
  columns,
  rows,
  cellHeight = 40,
  cellWidth = "100%",
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {[...Array(columns)].map((_, index) => (
            <TableCell key={index}>
              <Skeleton height={cellHeight} width={cellWidth} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(rows)].map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {[...Array(columns)].map((_, colIndex) => (
              <TableCell key={colIndex}>
                <Skeleton height={cellHeight} width={cellWidth} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableSkeleton;
