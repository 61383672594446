import React, { ReactNode } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTableContentCell,
  StyledTableHeadCell,
} from "../../common/styles/TableStyle";
import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import { Theme } from "@emotion/react";
import InfiniteScroll from "react-infinite-scroll-component";
import TableSkeleton from "../TableSkeleton";
import DataNotFound from "../../assets/Svg/notfound.svg";

export interface Data {
  id: number | string;
  [key: string]: any;
}

export interface Column {
  id: string;
  label: any;
  align?: "inherit" | "left" | "right" | "center" | "justify";
  icon?: ReactNode;
  cellRenderer?: (data: any, rowData: Data) => ReactNode;
  colWidth?: string | number;
  colMaxWidth?: string | number;
  visible?: boolean;
}

interface TableComponentProps {
  columns: Column[];
  data: Data[];
  onRowClick?: (rowData: any) => void | null;
  sx?: SxProps<Theme> | undefined;
  infiniteScroll?: boolean;
  handleScroll?: () => any;
  currentLength?: number;
  totalLength?: number;
  hasMore?: boolean;
  loading?: Boolean;
  noDataMessage?: string;
  height?: string;
}

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  data,
  onRowClick = null,
  sx = {},
  handleScroll = null,
  infiniteScroll = false,
  hasMore,
  currentLength = 0,
  totalLength = 0,
  loading = false,
  noDataMessage = "No data found",
  height = "auto",
}) => {
  const MainTable = () => {
    return (
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow sx={{ position: "relative" }}>
            {columns.map((column) =>
              column?.visible !== false ? (
                <StyledTableHeadCell
                  sx={{
                    width: column?.colWidth,
                    maxWidth: column?.colMaxWidth,
                  }}
                  key={column.id}
                  align={column.align || "left"}
                >
                  <div className="flex items-center">
                    {column.label}{" "}
                    {column.icon && (
                      <span style={{ marginLeft: 8 }}>{column.icon}</span>
                    )}
                  </div>
                </StyledTableHeadCell>
              ) : null
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row.id.toString()}
              onClick={onRowClick ? () => onRowClick(row) : () => {}}
              sx={{
                cursor: onRowClick ? "pointer" : "default",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              {columns.map((column) =>
                column?.visible !== false ? (
                  <StyledTableContentCell
                    key={column.id}
                    align={column.align || "left"}
                    sx={{
                      width: column.colWidth,
                      maxWidth: column?.colMaxWidth,
                    }}
                  >
                    {column.cellRenderer
                      ? column.cellRenderer(row[column.id], row)
                      : row[column.id]}
                  </StyledTableContentCell>
                ) : null
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className="w-full" style={{ height }}>
      {data.length > 0 ? (
        <TableContainer sx={sx} id="scrollableDiv">
          {loading ? (
            <TableSkeleton
              columns={columns.reduce(
                (a, c) => (c?.visible !== false ? a + 1 : a),
                0
              )}
              rows={5}
            />
          ) : infiniteScroll && handleScroll ? (
            <InfiniteScroll
              dataLength={data?.length}
              next={handleScroll}
              hasMore={hasMore ?? currentLength <= totalLength}
              loader={
                <TableSkeleton
                  columns={columns.reduce(
                    (a, c) => (c?.visible !== false ? a + 1 : a),
                    0
                  )}
                  rows={1}
                />
              }
              style={{ overflow: "unset" }}
              scrollableTarget="scrollableDiv"
            >
              <MainTable />
            </InfiniteScroll>
          ) : (
            <MainTable />
          )}
        </TableContainer>
      ) : (
        <div className="w-full text-center flex flex-col items-center gap-8">
          <MainTable />
          <img src={DataNotFound} />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
