import React, { ReactNode, MouseEvent } from "react";
import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string | ReactNode;
  variant?: "default" | "primary" | "secondary" | "outlined";
  className?: string;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  variant = "default",
  className = "",
  iconBefore,
  iconAfter,
  onClick,
  disabled,
  ...props
}) => {
  const baseClass = "capitalize flex justify-center items-center";

  const variantClasses = {
    default:
      "bg-primary text-white hover:bg-primary-dark focus:ring-primary-light",
    primary:
      "bg-primary text-white hover:bg-primary-dark focus:ring-primary-light",
    secondary: "underline",
    outlined: "border border-primary-dark px-4 py-1",  };

  const buttonClass = classNames(
    baseClass,
    variantClasses[variant],
    className,
    `${disabled ? "opacity-50 cursor-not-allowed" : ""}`
  );

  return (
    <button
      className={buttonClass}
      {...props}
      onClick={onClick}
      disabled={disabled}
    >
      {iconBefore && <span className="mr-1">{iconBefore}</span>}
      {label}
      {iconAfter && <span className="ml-1">{iconAfter}</span>}
    </button>
  );
};

export default Button;
