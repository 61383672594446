import { Box, Chip, styled, Typography, TableCell, Theme } from '@mui/material';
import { mecGrey, mecPrimary } from '../../constants/theme/colors';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import classNames from 'classnames';

export const StyledChip = styled(Chip)(({ theme }) => ({
  margin: '4px',
  backgroundColor: mecPrimary[100],
  color: mecPrimary[500],
  borderRadius: '31px',
  borderColor: mecPrimary[200],
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  '& .MuiChip-deleteIcon': {
    color: mecPrimary[500],
    '&:hover': {
      color: mecPrimary[500],
    },
  },
  '&:hover': {
    backgroundColor: mecPrimary[100],
    borderColor: mecPrimary[200],
  },
}));

export const SelectedChipsBox = styled(Box)(({ theme }) => ({
  gap: '16px',
  margin: theme.spacing('15px', 0, '3px', 0),
  maxHeight: '200px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: mecPrimary[500],
    borderRadius: '10px',
    height: '2px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: mecPrimary[500],
    width: '2px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#F1F1F1',
  },
}));

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 24px;
`;

export const TableContainer = styled(Box)`
  border: 1px solid ${mecGrey[300]};
  border-radius: 10px;
  padding: 16px;
  gap: 24px;
  min-height: 170px;
`;

export const FlexRow = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const HalfWidthContainer = styled(Box)`
  width: 50%;
  border: 1px solid ${mecGrey[100]};
  border-radius: 10px;
  padding: 16px;
  gap: 24px;
`;

export const ScrollableFilterWrapper = styled('div')(({ theme }) => ({
  overflow: 'auto',
  maxWidth: '700vh',
  scrollbarWidth: 'auto',
  marginRight: '10px',
  maxHeight: '100vh',
  position: 'relative',

  '&::-webkit-scrollbar': {
    height: '3px',
    width: '4px',
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: '0.5rem',
    backgroundColor: '#f0f0f0',
    padding: '3px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: mecPrimary[400],
    borderRadius: '0.5rem',
    padding: '3px',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: mecPrimary[400],
  },
}));

export const ScrollableDiv = styled('div')(({ theme }) => ({
  overflowX: 'auto',
  maxWidth: '700vh',
  scrollbarWidth: 'auto',
  marginRight: '10px',

  '&::-webkit-scrollbar': {
    height: '2px',
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: '0.5rem',
    backgroundColor: '#f0f0f0',
    padding: '3px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: mecPrimary[400],
    borderRadius: '0.5rem',
    padding: '3px',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: mecPrimary[400],
  },
}));

export const ProjectNameCell = styled(Typography)(({ theme }) => ({
  maxWidth: '150px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export interface StyledCellProps {
  isLast?: boolean;
}

export const StyledFilterTableContentCell = styled(TableCell)<StyledCellProps>(({ isLast }) => ({
  '&.MuiTableCell-root': {
    padding: '8px 16px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: mecGrey[800],
    borderBottom: isLast ? 'none' : `1px solid ${mecGrey[300]}`,
    height: '44px',
  },
}));

export const StyledProfitLossDialogBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: mecGrey[200],
  borderRadius: '6px',
  gap: '12px',
  padding: '10px',
  border: `1px solid ${mecGrey[300]}`,
  marginTop: '5px',
}));

export const scrollbarStyles = {
  '&::-webkit-scrollbar': {
    height: '2px',
    width: '4px',
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: '0.5rem',
    backgroundColor: '#f0f0f0',
    padding: '3px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: mecPrimary[400],
    borderRadius: '0.5rem',
    padding: '3px',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: mecPrimary[400],
  },
};
