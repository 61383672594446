import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes as RoutesReactRouterDom } from 'react-router-dom';
import { RouteConstants } from '../constants/route-constant';
import { environment } from '../environments/environment';
import { useAppSelector } from '../store/hooks';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FilterComponent } from '../pages/ProfitLoss/ProfitLossByFilter/FilterProjectsComponent';
import { KraAndKpi } from '../pages/KraAndKpi';
import { SuspenseFallback } from '../components/SuspenseFallback';
import ComingSoon from '../components/ComingSoon';
const Login = lazy(() => import('../pages/Login'));
const Home = lazy(() => import('../pages/Home'));
const Docs = lazy(() => import('../pages/Docs'));
const Error404 = lazy(() => import('../pages/Error404'));
const TimeSheet = lazy(() => import('../pages/TimeSheet'));
const ResourcesList = lazy(() => import('../pages/Resources'));
const OnboardResource = lazy(() => import('../pages/Resources/Onboard'));
const ResourceRegister = lazy(() => import('../pages/Resources/Register'));
const ResourceConsultant = lazy(() => import('../pages/Resources/Register/ResourceConsultant'));
const ResourceRegisterSuccess = lazy(() => import('../pages/Resources/Register/Success'));
const ProjectList = lazy(() => import('../pages/Project/ProjectList'));
const ProjectDetails = lazy(() => import('../pages/Project/Details'));
const KraKpiRatings = lazy(() => import('../pages/KraKPiRatings'));
const ComponentTest = lazy(() => import('../pages/ComponentTest'));
const SprintRetrospective = lazy(() => import('../pages/Project/SprintRetrospective'));
const InboxContainer = lazy(() => import('../pages/Inbox'));
const Layout = lazy(() => import('../components/Layout'));
const PlatformUsers = lazy(() => import('../pages/PlatformUsers'));

const CreateRepository = lazy(() => import('../pages/CreateRepository'));
const ViewRepository = lazy(() => import('../pages/CreateRepository/ViewRepository'));

const ProfitLossProjectsList = lazy(() => import('../pages/ProfitLoss'));
const InboxList = lazy(() => import('../components/InboxList'));
const InboxDetailed = lazy(() => import('../components/InboxDetailsCard'));
const InboxWfhList = lazy(() => import('../components/InboxWfhList'));
// const {KraAndKpi} = lazy(() => import('../pages/KraAndKpi'));
const CreateKraKpi = lazy(() => import('../pages/KraAndKpi/Kra-Kpi-Create-Edit'));
const WFHDetailed = lazy(() => import('../components/WFHDetailed'));

const MonthlyFeedback = lazy(() => import('../pages/Feedback/MonthlyFeedback'));
const ProjectFeedback = lazy(() => import('../pages/Feedback/ProjectFeedback'));
const SprintDetails = lazy(() => import('../pages/Project/SprintDetails'));
const BpcNominations = lazy(() => import('../pages/BpcNominations'));
const EditResource = lazy(() => import('../pages/Resources/Edit'));
const WfhRequest = lazy(() => import('../pages/WFHRequest'));
const AllListing = lazy(() => import('../components/WFH/ALLListing'));
const MyRequests = lazy(() => import('../components/WFH/MyRequests'));
const NewRequest = lazy(() => import('../components/WFH/NewRequest'));
const MyRequestCard = lazy(() => import('../components/WFH/MyRequestCard'));
const ProjectWorklogs = lazy(() => import('../components/Projects/ProjectWorklogs'));
const InviteResource = lazy(() => import('../pages/Resources/Invite'));
const GoalSheet = lazy(() => import('../pages/GoalSheet'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Profile = lazy(() => import('../pages/Profile'));
const ViewWorklog = lazy(() => import('../components/ViewWorklog'));
const AddJiraProject = lazy(() => import('../pages/Project/Add/Jira'));
const AddNonJiraProject = lazy(() => import('../pages/Project/Add/NonJira'));
const TeamMembersTab = lazy(() => import('../components/Projects/TeamMembersTab'));
const ProjectDetailsTab = lazy(() => import('../components/Projects/DetailsTab'));
const CardView = lazy(() => import('../pages/Project/ProjectList/CardView'));
const ListView = lazy(() => import('../pages/Project/ProjectList/List'));
const EditProfile = lazy(() => import('../components/Profile/Edit'));
const KraKpiRatingDetails = lazy(() => import('../pages/KraKpiRatingDetail'));
const CostSettingTab = lazy(() => import('../components/Projects/CostSettingTab/CostSettingTab'));
const AddKanbanProject = lazy(() => import('../pages/Project/Add/Kanban'));
const EditProject = lazy(() => import('../pages/Project/Edit'));
const KanbanBoard = lazy(() => import('../components/KanbanModule'));

const DocumentRepository = lazy(() => import('../pages/DocumentRepository'));
const BoardSetting = lazy(() => import('../components/BoardSetting'));
const ProjectSettingDialog = lazy(() => import('../components/Dialog/ProjectSettingDialog'));
const ProjectReminder = lazy(() => import('../components/ProjectReminder'));
const LeaveOrHoliday = lazy(() => import('../components/LeaveOrHoliday'));
const FormsList = lazy(() => import('../pages/Forms/FormsList'));
const AddForm = lazy(() => import('../pages/Forms/AddForm'));
const EditForm = lazy(() => import('../pages/Forms/EditForm'));
const ViewForm = lazy(() => import('../pages/Forms/ViewForm'));
const ProfitLossProjectsListComponent = lazy(
  () => import('../pages/ProfitLoss/ProfitLossAllProjects/AllProjectsComponent'),
);
const GoalsDetails = lazy(() => import('../pages/GoalsDetails'));
const ResourceTimesheet = lazy(() => import('../components/ResourceTimesheet'));
const WorklogPage = lazy(() => import('../pages/WorklogPage'));
const AddEditChangeRequest = lazy(
  () => import('../pages/Project/ChangeRequest/AddEditChangeRequest'),
);
const GoalContainer = lazy(() => import('../pages/Goals'));
const GoalInnerSideBar = lazy(() => import('../components/Goals/GoalsInnerSideBar'));
const AddGoalsContainer = lazy(() => import('../components/Goals/AddGoalsContainer'));
const EditGoalsContainer = lazy(() => import('../components/Goals/EditGoalsContainer'));
const ProfitLossProjectStatistic = lazy(
  () => import('../pages/ProfitLoss/ProfitLossDetails/ProfitLossStatDetails'),
);
const EmployeeLogin = lazy(() => import('../pages/EmployeeLogin'));
const MenteeContainer = lazy(() => import('../pages/Mentee'));

const Routes: React.FC = () => {
  const { isAuthenticated } = useAppSelector(state => state?.auth);

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <GoogleOAuthProvider clientId={environment.GoogleClientId}>
        <RoutesReactRouterDom>
          <Route path={RouteConstants.ROUTE_RESOURCE_REGISTRATION} element={<ResourceRegister />} />
          <Route
            path={RouteConstants.ROUTE_RESOURCE_REGISTRATION_SUCCESS}
            element={<ResourceRegisterSuccess />}
          />
          {!isAuthenticated ? (
            <>
              <Route path={RouteConstants.ROUTE_LOGIN} element={<EmployeeLogin />} />
              <Route
                path={RouteConstants.ROUTE_REGISTER_RESOURCE_AS_CONSULTANT}
                element={<ResourceConsultant />}
              />
              <Route path="*" element={<Navigate to={RouteConstants.ROUTE_LOGIN} />} />
            </>
          ) : (
            <>
              <Route
                path={RouteConstants.ROUTE_REGISTER_RESOURCE_AS_CONSULTANT}
                element={<ResourceConsultant />}
              />
              <Route path={RouteConstants.ROUTE_LOGIN} element={<EmployeeLogin />} />
              <Route path={RouteConstants.ROUTE_HOME} element={<Home />} />

              <Route path={RouteConstants.ROUTE_PROJECT} element={<ProjectList />}>
                <Route path={RouteConstants.ROUTE_PROJECT} element={<Navigate to="card" />} />
                <Route path={RouteConstants.ROUTE_PROJECT_CARD} element={<CardView />} />
                <Route path={RouteConstants.ROUTE_PROJECT_LIST} element={<ListView />} />
              </Route>

              <Route path={RouteConstants.ROUTE_PROFIT_LOSS} element={<ProfitLossProjectsList />}>
                <Route
                  index
                  path={RouteConstants.ROUTE_PROFIT_LOSS}
                  element={<Navigate to="all" />}
                />

                <Route
                  path={RouteConstants.ROUTE_PROFIT_LOSS_ALL_PROJECTS}
                  element={<ProfitLossProjectsListComponent />}
                />

                <Route
                  path={RouteConstants.ROUTE_PROFIT_LOSS_FILTERS}
                  element={<FilterComponent />}
                />
              </Route>

              <Route
                path={RouteConstants.ROUTE_PROFIT_LOSS_DETAIL}
                element={<ProfitLossProjectStatistic />}
              />

              <Route
                path={RouteConstants.ROUTE_INBOX}
                element={
                  <Layout>
                    <InboxContainer />
                  </Layout>
                }
              >
                <Route path={RouteConstants.ROUTE_INBOX_MONTHLY} element={<InboxList />}>
                  <Route path={RouteConstants.ROUTE_INBOX_MONTHLY_ID} element={<InboxDetailed />} />
                </Route>
                <Route path={RouteConstants.ROUTE_INBOX_PROJECT} element={<InboxList />}>
                  <Route path={RouteConstants.ROUTE_INBOX_PROJECT_ID} element={<InboxDetailed />} />
                </Route>
                <Route path={RouteConstants.ROUTE_INBOX_WFH} element={<InboxWfhList />}>
                  <Route path={RouteConstants.ROUTE_INBOX_WFH_ID} element={<WFHDetailed />} />
                </Route>
              </Route>
              <Route
                path={RouteConstants.ROUTE_MONTHLY_VIEW_FEEDBACK}
                element={
                  <Layout>
                    <MonthlyFeedback />
                  </Layout>
                }
              />
              <Route
                path={RouteConstants.ROUTE_PROJECT_VIEW_FEEDBACK}
                element={
                  <Layout>
                    <ProjectFeedback />
                  </Layout>
                }
              />
              <Route
                path={RouteConstants.ROUTE_MONTHLY_FEEDBACK}
                element={
                  <Layout>
                    <MonthlyFeedback />
                  </Layout>
                }
              />
              <Route
                path={RouteConstants.ROUTE_PROJECT_FEEDBACK}
                element={
                  <Layout>
                    <ProjectFeedback />
                  </Layout>
                }
              />
              <Route path={RouteConstants.ROUTE_DOCUMENTS_REPO}>
                <Route
                  path={RouteConstants.ROUTE_DOCUMENTS_REPO_WITH_PROJECT_ID}
                  element={<DocumentRepository />}
                />
              </Route>
              <Route
                path={RouteConstants.ROUTE_WFH_REQUEST}
                element={
                  <Layout>
                    <WfhRequest />
                  </Layout>
                }
              >
                <Route path={RouteConstants.ROUTE_WFH_SUMMARY} element={<AllListing />} />
                <Route path={RouteConstants.ROUTE_WFH_MY_REQUEST} element={<MyRequests />}>
                  <Route
                    path={RouteConstants.ROUTE_WFH_MY_REQUEST_ID}
                    element={<MyRequestCard />}
                  />
                </Route>
                <Route path={RouteConstants.ROUTE_WFH_NEW_REQUEST} element={<NewRequest />} />
              </Route>
              {/* <Route path={RouteConstants.ROUTE_DOCS} element={<Docs />} /> */}
              <Route path={RouteConstants.ROUTE_DASHBOARD} element={<Dashboard />} />
              <Route path={RouteConstants.ROUTE_PROJECT_DETAILS} element={<ProjectDetails />}>
                <Route
                  path={RouteConstants.ROUTE_PROJECT_DETAILS}
                  element={<Navigate to="details" />}
                />
                <Route
                  index
                  path={RouteConstants.ROUTE_PROJECT_DETAILS_DASHBOARD}
                  element={<ComingSoon />}
                />
                <Route
                  index
                  path={RouteConstants.ROUTE_PROJECT_DETAILS_OVERVIEW}
                  element={<ProjectDetailsTab />}
                />
                <Route
                  path={RouteConstants.ROUTE_KANBAN_PROJECT_DETAILS}
                  element={<ProjectDetailsTab />}
                />
                <Route
                  path={RouteConstants.ROUTE_PROJECT_DETAILS_TEAM_MEMBERS}
                  element={<TeamMembersTab />}
                />
                <Route
                  path={RouteConstants.ROUTE_PROJECT_DETAILS_RESOURCE_TIME_SHEET}
                  element={<ResourceTimesheet />}
                />
                <Route
                  path={RouteConstants.ROUTE_PROJECT_DETAILS_RESOURCE_TIME_SHEET}
                  element={<></>}
                />
                <Route
                  path={RouteConstants.ROUTE_PROJECT_DETAILS_COST_SETTINGS}
                  element={<CostSettingTab />}
                />
                <Route
                  path={RouteConstants?.ROUTE_PROJECT_SETTING}
                  element={<ProjectSettingDialog />}
                >
                  <Route
                    path={RouteConstants.ROUTE_SETTING_PROJECT_REMINDER}
                    element={<ProjectReminder />}
                  />
                  <Route
                    path={RouteConstants.ROUTE_SETTING_LEAVE_HOLIDAY}
                    element={<LeaveOrHoliday />}
                  />
                  <Route
                    path={RouteConstants.ROUTE_SETTING_BOARD_SETTING}
                    element={<BoardSetting />}
                  />
                </Route>
              </Route>
              <Route path={RouteConstants.ROUTE_ADD_JIRA_PROJECT} element={<AddJiraProject />} />
              <Route
                path={RouteConstants.ROUTE_ADD_NON_JIRA_PROJECT}
                element={<AddNonJiraProject />}
              />
              <Route
                path={RouteConstants.ROUTE_ADD_KANBAN_PROJECT}
                element={<AddKanbanProject />}
              />
              <Route path={RouteConstants.ROUTE_EDIT_PROJECT} element={<EditProject />} />
              <Route
                path={RouteConstants.ROUTE_PROJECT_SPRINT_RETROSPECT}
                element={<SprintRetrospective />}
              />
              <Route
                path={RouteConstants.ROUTE_PROJECT_SPRINT_DETAILS}
                element={<SprintDetails />}
              />

              <Route
                path={RouteConstants.ROUTE_PROJECT_WORKLOGS}
                element={
                  <Layout>
                    <ProjectWorklogs />
                  </Layout>
                }
              />
              <Route path={RouteConstants.ROUTE_ADD_REPOSITORY} element={<CreateRepository />} />
              <Route path={RouteConstants.ROUTE_VIEW_REPOSITORIES} element={<ViewRepository />} />

              <Route path={RouteConstants.ROUTE_TIMESHEET} element={<TimeSheet />} />
              <Route path={'/component-test'} element={<ComponentTest />} />
              <Route path={RouteConstants.ROUTE_ERROR_404} element={<Error404 />} />
              <Route path={RouteConstants.ROUTE_RESOURCES} element={<ResourcesList />} />
              <Route path={RouteConstants.ROUTE_RESOURCE_ONBOARD} element={<OnboardResource />} />
              <Route path={RouteConstants.ROUTE_RESOURCE_INVITE} element={<InviteResource />} />
              <Route path={RouteConstants.ROUTE_ONBOARD_RESOURCE_EDIT} element={<EditResource />} />
              <Route path={RouteConstants.ROUTE_KRA_KPI_RATINGS} element={<KraKpiRatings />} />
              <Route path={RouteConstants.ROUTE_KRA_KPI} element={<KraAndKpi />} />
              <Route path={RouteConstants.ROUTE_KRA_KPI_CREATE_EDIT} element={<CreateKraKpi />} />
              <Route path={RouteConstants.ROUTE_PLATFORM_USERS} element={<PlatformUsers />} />
              <Route
                path={RouteConstants.ROUTE_RESOURCES_BPC_NOMINATIONS}
                element={<BpcNominations />}
              />
              <Route path={RouteConstants.ROUTE_KRA_KPI_CREATE_EDIT} element={<CreateKraKpi />} />

              <Route path={RouteConstants.ROUTE_PLATFORM_USERS} element={<PlatformUsers />} />
              <Route
                path={RouteConstants.ROUTE_RESOURCES_BPC_NOMINATIONS}
                element={<BpcNominations />}
              />
              <Route path={RouteConstants.ROUTE_FORMS} element={<FormsList />} />
              <Route path={RouteConstants.ROUTE_ADD_FORM} element={<AddForm />} />
              <Route path={RouteConstants.ROUTE_VIEW_FORM} element={<ViewForm />} />
              <Route path={RouteConstants.ROUTE_EDIT_FORM} element={<EditForm />} />
              <Route path={RouteConstants.ROUTE_VIEW_WORKLOG_ON_SHARE} element={<ViewWorklog />} />
              <Route path={RouteConstants.ROUTE_VIEW_WORKLOG_ON_SHARE} element={<ViewWorklog />} />

              <Route
                path={RouteConstants.ROUTE_KRA_KPI_RATINGS_DETAILS}
                element={<KraKpiRatingDetails />}
              />
              <Route path={RouteConstants.ROUTE_GOALS_SHEET} element={<GoalSheet />} />
              <Route
                path={RouteConstants.ROUTE_PROFILE_DETAILS}
                element={<Profile title="Profile" />}
              />
              <Route
                path={RouteConstants.ROUTE_RESOURCE_DETAILS}
                element={<Profile title="Resources" />}
              />
              <Route
                path={RouteConstants.ROUTE_PROJECT_COST_SETTING}
                element={<CostSettingTab />}
              />
              <Route
                path={RouteConstants.ROUTE_RESOURCE_DETAILS}
                element={<Profile title="Resource" />}
              />
              <Route
                path={RouteConstants.ROUTE_PROFILE_EDIT}
                element={<EditProfile title="Profile" />}
              />
              <Route
                path={RouteConstants.ROUTE_RESOURCE_EDIT}
                element={<EditProfile title="Resource" />}
              />
              <Route
                index
                path={RouteConstants.ROUTE_PROJECT_DETAILS_OVERVIEW}
                element={<ProjectDetailsTab />}
              />
              <Route
                path={RouteConstants.ROUTE_KANBAN_PROJECT_DETAILS}
                element={<ProjectDetailsTab />}
              />
              {/* <Route
              path={RouteConstants.}
              element={<AddKanbanTask handleSubmit={false} />}
            /> */}
              <Route path={RouteConstants.ROUTE_GOALS_DETAILS} element={<GoalsDetails />} />
              <Route
                path={RouteConstants.ROUTE_RESOURCE_GOALS_DETAILS}
                element={<GoalsDetails title="Resource" />}
              />
              <Route path={RouteConstants.ROUTE_WORKLOG_PUBLIC_PAGE2} element={<WorklogPage />} />
              <Route path={RouteConstants.ROUTE_MENTEE} element={<MenteeContainer />} />
              <Route path={RouteConstants.ROUTE_MENTEE_WITH_ID} element={<MenteeContainer />} />

              <Route
                path={RouteConstants.ROUTE_PROJECT_ADD_CR}
                element={<AddEditChangeRequest />}
              />
              <Route path={RouteConstants.ROUTE_GOALS_DETAILS} element={<GoalsDetails />} />

              <Route path={RouteConstants.ROUTE_GOALS} element={<GoalContainer />}>
                <Route path={RouteConstants.ROUTE_MY_GOALS} element={<GoalInnerSideBar />}>
                  <Route path={RouteConstants.ROUTE_ADD_GOALS} element={<AddGoalsContainer />} />
                  <Route path={RouteConstants.ROUTE_EDIT_GOALS} element={<EditGoalsContainer />} />
                </Route>
              </Route>
              <Route path={'*'} element={<Error404 />} />
            </>
          )}
        </RoutesReactRouterDom>
      </GoogleOAuthProvider>
    </Suspense>
  );
};

export default Routes;
