import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfitLossFilter {
  id: number;
  title: string;
  status: string;
  resourceIds: number[];
}
interface ProfitLossFilterState {
  currentFilter: ProfitLossFilter | null;
  searchValue: string;
  projectType: any;
  formatThousandKey: boolean;
}

const initialState: ProfitLossFilterState = {
  currentFilter: null,
  searchValue: '',
  projectType: '',
  formatThousandKey: false,
};

const profitLossFilterReducer = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setCurrentFilter: (state, action: PayloadAction<ProfitLossFilter>) => {
      state.currentFilter = action.payload;
    },
    setSearchValues: (state, action: any) => {
      state.searchValue = action.payload;
    },
    clearSearchValueState: state => {
      state.searchValue = '';
    },
    clearFilterState: state => {
      state.currentFilter = null;
    },
    setProjectType: (state, action: PayloadAction<string>) => {
      state.projectType = action.payload;
    },
    clearProjectType: state => {
      state.projectType = '';
    },
    setFormatThousandKey: (state, action: PayloadAction<boolean>) => {
      state.formatThousandKey = action.payload;
    },
    clearFormatThousandKey: state => {
      state.formatThousandKey = false;
    },
  },
});

export const {
  setCurrentFilter,
  clearFilterState,
  setSearchValues,
  clearSearchValueState,
  setProjectType,
  clearProjectType,
  setFormatThousandKey,
  clearFormatThousandKey,
} = profitLossFilterReducer.actions;

export default profitLossFilterReducer.reducer;
