import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  HTTP_CREATED,
  HTTP_OK,
  HTTP_UNAUTHORIZED,
} from "../constants/status-code-constant";
import { getNewAccessToken } from "../constants/api-urls";
import store from "../store/store";
import { showLoading } from "../store/reducers/loadingBarReducer";
import { persistor } from "../store/store";
import { clearAuth, setTokens } from "../store/reducers/authReducer";
import { getRefreshToken, getAccessToken } from "../services/auth/auth.service";

const axiosInstance = axios.create();

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

interface ErrorResponse {
  message?: string;
  error?: string;
}

// Handle outgoing request
axiosInstance.interceptors.request.use((request: CustomAxiosRequestConfig) => {
  const accessToken = getAccessToken();
  if (accessToken && request.headers) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  store.dispatch(showLoading(true));
  return request;
});

// Handle response
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    store.dispatch(showLoading(false));
    return response;
  },
  (error: AxiosError<ErrorResponse>) => {
    store.dispatch(showLoading(false));
    const originalRequest = error.config as CustomAxiosRequestConfig;
    return new Promise((resolve, reject) => {
      if (
        error?.response?.status === HTTP_UNAUTHORIZED &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          persistor.purge();
          store.dispatch(clearAuth());
          window.location.pathname = "/login";
          return reject(error);
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${refreshToken}`,
          },
        };

        axios
          .get(getNewAccessToken, config)
          .then((response) => {
            if (
              response.status === HTTP_OK ||
              response.status === HTTP_CREATED
            ) {
              const { accessToken, refreshToken } = response.data.data;
              store.dispatch(setTokens({ accessToken, refreshToken }));
              axiosInstance.defaults.headers.common["Authorization"] =
                "Bearer " + accessToken;
              originalRequest.headers["Authorization"] =
                "Bearer " + accessToken;
              resolve(axiosInstance(originalRequest));
            }
          })
          .catch((error: AxiosError) => {
            persistor.purge();
            store.dispatch(clearAuth());
            window.location.pathname = "/login";
            reject(error);
          });
      } else {
        console.log("error",error);
        reject(
          error?.response?.data?.message ||
            error?.response?.data?.error ||
            error
        );
      }
    });
  }
);

export default axiosInstance;
