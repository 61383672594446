import { useEffect, useState } from 'react';
import TableComponent, { Column } from '../../../components/TableComponent';

import { formatStatus } from '../index';
import { Box, Typography } from '@mui/material';
import {
  deleteProfitLossFilterById,
  getProfitAndLossFilters,
} from '../../../services/profitLossService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { setSnackBar } from '../../../store/reducers/snackbar';
import { severity } from '../../../constants/const';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeDialog, openDialog } from '../../../store/reducers/dialogReducer';
import { setCurrentFilter } from '../../../store/reducers/profitLossFilterReducer';
import { capitalizeFirstLetter } from '../../../utils';
import CustomDialog from '../../../components/Dialog';
import { Project, formatBackendValue } from '../../../constants/app-constant';
import { RouteConstants } from '../../../constants/route-constant';
import { scrollbarStyles } from '../style';

export const FilterComponent = () => {
  const navigate = useNavigate();
  const [filterByProjectList, setFilterByProjectList] = useState<Project[]>([]);
  const [filterToDelete, setFilterToDelete] = useState<any>(null);
  const loggedInUser = useAppSelector(state => state.auth?.user);
  const dialogIds = useAppSelector(state => state?.dialogReducer?.openDialogIds);
  const isFilterDialogOpen = dialogIds?.includes('filterProjectsDialog');

  const dispatch = useAppDispatch();

  const filterByColumns: Column[] = [
    {
      id: 'title',
      label: 'Filter Name',
      colWidth: '30%',
      cellRenderer: data => (data ? formatBackendValue(data) : '-'),
    },
    {
      id: 'creatorName',
      label: 'Created By',
      colWidth: '20%',
      cellRenderer: data => (data ? capitalizeFirstLetter(data) : '-'),
    },
    {
      id: 'createdAt',
      label: 'Created At',
      colWidth: '20%',
      cellRenderer: data => (data ? moment(data).format('DD-MM-YYYY') : '-'),
    },
    {
      id: 'status',
      label: 'Access Permission',
      colWidth: '20%',
      cellRenderer: data => {
        return formatStatus(data);
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      colWidth: '20%',
      align: 'center',
      cellRenderer: rowData => {
        return (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {loggedInUser?.id === rowData?.createdBy && (
              <>
                <EditIcon
                  color="action"
                  onClick={(e: any) => handleEditClick(e, rowData)}
                  sx={{ cursor: 'pointer' }}
                />
                <DeleteOutlineOutlinedIcon
                  color="action"
                  onClick={(e: any) => handleDeleteClick(e, rowData)}
                  sx={{ cursor: 'pointer' }}
                />
              </>
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (!isFilterDialogOpen) {
      loadProfitAndLossFilters();
    }
  }, [isFilterDialogOpen]);
  const handleEditClick = (e: any, rowData: any) => {
    e.stopPropagation();
    dispatch(openDialog('filterProjectsDialog'));
    const updatedRowData = {
      id: rowData?.id,
      title: rowData?.title,
      resourceIds: rowData?.resourceIds,
      status: rowData?.status,
    };
    dispatch(setCurrentFilter(updatedRowData));
  };

  const handleDeleteClick = (e: any, rowData: any) => {
    e.stopPropagation();
    setFilterToDelete(rowData);
    dispatch(openDialog('deleteFilterDialog'));
  };

  const confirmDeleteClick = async () => {
    if (filterToDelete?.id !== null) {
      try {
        const res = await deleteProfitLossFilterById(filterToDelete?.id);
        if (res?.status === 200) {
          dispatch(
            setSnackBar({
              severity: severity.success,
              message: res?.data?.data,
            }),
          );
          loadProfitAndLossFilters();
        }
      } catch (error) {
        console.log(`Error while deleting filter`, error);
        dispatch(
          setSnackBar({
            severity: severity.error,
            message: error,
          }),
        );
      }
    }
    dispatch(closeDialog('deleteFilterDialog'));
  };

  const loadProfitAndLossFilters = async () => {
    try {
      const res = await getProfitAndLossFilters();
      if (res) {
        const updatedResData = res?.data?.data?.map((item: any) => {
          return {
            ...item,
            actions: item,
          };
        });
        setFilterByProjectList(updatedResData);
      }
    } catch (error) {
      console.log(`Error while fetching filters`, error);
    }
  };

  const handleRowClick = (rowData: Project) => {
    const id = rowData?.id;
    const isFilter = true;
    navigate(
      `${RouteConstants.ROUTE_PROFIT_LOSS_DETAIL}?id=${id}&isFilter=${isFilter}&filterName=${rowData?.title}`,
    );
  };

  return (
    <>
      <Box className="mt-2 w-full pl-5">
        <TableComponent
          totalLength={filterByProjectList?.length}
          currentLength={filterByProjectList?.length}
          sx={{ height: 'calc(100vh - 200px)', ...scrollbarStyles }}
          columns={filterByColumns}
          infiniteScroll={true}
          data={filterByProjectList}
          onRowClick={handleRowClick}
        />
      </Box>
      <CustomDialog
        dialogId="deleteFilterDialog"
        dialogTitle="Delete Filter"
        showCloseButton={true}
        showCancelButton={true}
        showConfirmButton={true}
        onCancelButtonClick={() => dispatch(closeDialog('deleteFilterDialog'))}
        onConfirmButtonClick={confirmDeleteClick}
        onClose={() => dispatch(closeDialog('deleteFilterDialog'))}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        disableButton={false}
        loader={false}
        children={
          <Box>
            <Typography variant="mec_body1">
              {'Are you sure you want to delete the '}
              {filterToDelete?.title ? (
                <>
                  filter{' '}
                  <Typography component="span" variant="mec_bold">
                    {filterToDelete.title}
                  </Typography>{' '}
                  ?
                </>
              ) : (
                'filter?'
              )}
            </Typography>
          </Box>
        }
      />
    </>
  );
};
