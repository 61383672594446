import { environment } from '../environments/environment';

export const RESOURCE_SPRINGBOOT_URL = environment.springBootUrl + '/resource';
export const resourceSpringBootUrl = environment.springBootUrl + '/resource';
export const resourceListUrl = (params: { page: number; rowsPerPage: number }) =>
  `${environment.springBootUrl}/resource/paginated-list?page=${params.page}&size=${params.rowsPerPage}`;
export const resourceDataUrl = `${environment.springBootUrl}/resource/resource-data`;
export const technologyList = `${environment.springBootUrl}/resource/technology-list`;
export const projectList = `${environment.springBootUrl}/resource/project-list`;
export const emailList = `${environment.springBootUrl}/resource/emails`;
const projectSpringBootUrl = environment.springBootUrl + '/project';
export const kanbanUsersList = environment.springBootUrl + '/resource/devops-emails';
export const registerResourceUrl = environment.springBootUrl + '/resource/register';

export const userAccessTokenUrl: string = `${environment.springBootUrl}/user/access-token`;
export const connectJiraUrl: string = `${environment.springBootUrl}/external/jira-project`;
export const authUserUrl: string = `${environment.springBootUrl}/user/sign-in`;

export const getStatCountUrl: string = `${environment.springBootUrl}/dashboard`;
export const getNomineeListUrl: string = `${environment.springBootUrl}/nominee/list`;
export const saveNomineeUrl: string = `${environment.springBootUrl}/nominee/save`;
export const getEmailListUrl: string = `${RESOURCE_SPRINGBOOT_URL}/email-list`;

export const getLoggedInUserUrl: string = `${environment.springBootUrl}/user`;
export const getNewAccessToken: string = `${environment.springBootUrl}/user/access-token`;

export const resourceUtilizationReportUrl: string = `${environment.springBootUrl}/resource/utilization-report`;
export const resourceAvailabilityReportUrl: string = `${environment.springBootUrl}/resource/availability-report`;

export const projectListUrl: string = `${projectSpringBootUrl}/list`;
export const syncProjectUrl: string = `${environment.springBootUrl}/sync-project`;
export const projectByIDUrl: string = `${projectSpringBootUrl}/get-project-by-id/`;
export const sprintUrl: string = `${environment.springBootUrl}/sprint`;
export const invoiceUrl: string = `${sprintUrl}/invoice`;
export const sprintCompletionUrl: string = `${environment.springBootUrl}/sprint/status`;
export const happinessScoreBySprintUrl: string = `${environment.projectBaseUrl}/happiness-score-by-sprint`;
export const happinessScoreByProjectUrl: string = `${environment.projectBaseUrl}/happiness-score-by-project`;
export const sprintRetroUrl: string = `${environment.springBootUrl}/sprint-retro`;
export const projectResourcesUrl: string = `${projectSpringBootUrl}/resource/id`;
export const sprintIssueTypeUrl: string = `${environment.springBootUrl}/issue/type`;
export const sprintIssueUrl: string = `${environment.springBootUrl}/issue`;
export const sprintIssueProgressUrl: string = `${environment.springBootUrl}/issue/progress`;
export const feedbackFormUrl: string = `${environment.projectBaseUrl}/send-email`;
export const sprintStatusUrl: string = `${environment.springBootUrl}/sprint/status`;
export const mapResourceUrl: string = `${projectSpringBootUrl}/map-resource`;
export const forceUpdateProjectResourceUrl: string = `${projectSpringBootUrl}/resource/force-update`;
export const jiraUserUrl: string = `${projectSpringBootUrl}/external/jira-user`;

export const getProfitAndLossFiltersListUrl: string = `${environment.springBootUrl}/cost-setting/team`;
export const getProfitLossStatisticsListUrl = (id: number | string): string => {
  return `${environment.springBootUrl}/project/get-project-by-id/${id}`;
};

export const deleteProfitLossFilterUrl = (teamId: number) => {
  return `${environment.springBootUrl}/cost-setting/team/${teamId}`;
};

export const getProfitAndLossStatementUrl: string = `${environment.springBootUrl}/cost-setting/statement`;
export const getProfitAndLossFilterStatementUrl: string = `${environment.springBootUrl}/cost-setting/team/statement`;

export const goalsQuarterlyReportUrl: string = `${environment.springBootUrl}/goals/quarterly-report`;
export const goalsQuarterlyReportListUrl: string = `${environment.springBootUrl}/goals/quarterly-report-list`;
export const updateGoalConsiderationUrl: string = `${environment.springBootUrl}/resource/set-goals-consideration`;

//inbox
export const getInboxList: string = `${environment.springBootUrl}/forms-module/list?inboxType=`;
export const saveProjectFeedbackUrl: string = `${environment.springBootUrl}/forms-module/answers`;
export const ignoreFormUrl: string = `${environment.springBootUrl}/forms-module/form/ignore?resourceId=1`;
export const getFeedbackDataSourceUrl: string = `${environment.springBootUrl}/forms-module/source`;
export const getProjectFeedbackQuesUrl: string = `${environment.springBootUrl}/forms-module/project-feedback/form`;
export const getMonthlyFeedbackQuesUrl: string = `${environment.springBootUrl}/forms-module/one-to-one/form`;
export const getViewFeedbackFormUrl: string = `${environment.springBootUrl}/forms-module/form/view`;
export const sendProjectFeedbackReminderUrl: string = `${environment.springBootUrl}/forms-module/project-feedback/reminder/email`;

//wfh-module
export const applyWfhUrl: string = `${environment.springBootUrl}/wfh-request`;
export const getWfhRequestWithIdUrl: string = `${environment.springBootUrl}/wfh-request`;
export const getWfhRequestListUrl: string = `${environment.springBootUrl}/wfh-request/list`;
export const getAllGlobalWfhListUrl: string = `${environment.springBootUrl}/wfh-request/global-list`;
export const changeWfhRequestStatusUrl: string = `${environment.springBootUrl}/wfh-request/status`;
export const getWfhBalanceUrl: string = `${environment.springBootUrl}/wfh-request/balance`;

//KRA/KPI
export const getKraAndKpiByDesignationUrl = `${environment.springBootUrl}/section-question/subtype?subtype=`;
export const getAllDesignationUrl = `${environment.springBootUrl}/designation`;
export const getAllDesignationWithNoKraKpiUrl = `${environment.springBootUrl}/section-question/list`;
export const postCreateKraAndKpiUrl = `${environment.springBootUrl}/section-question`;

//Platform users
export const getAllPlatformUsersUrl = `${environment.springBootUrl}/user/list`;
export const addPlatformUserUrl = `${environment.springBootUrl}/user`;

//user details url
export const getUserDetailsUrl: string = `${environment.springBootUrl}/user`;

//workLog
export const AddWorkLogUrl = `${environment.springBootUrl}/worklog`;

//Resources
export const REGISTERED_RESOURCE_URL = `${resourceSpringBootUrl}/registerd`;
export const ONBOARD_RESOURCE_URL = `${resourceSpringBootUrl}/accept`;
export const REGISTER_RESOURCE_URL = `${resourceSpringBootUrl}/register`;
export const INVITED_RESOURCE_LIST_URL: string = `${environment.springBootUrl}/user/invited`;
export const INVITE_RESOURCE_URL: string = `${environment.springBootUrl}/user/invite`;

export const GET_DESIGNATIONS: string = `${environment.springBootUrl}/designation`;
export const UPDATE_DELETE_RESOURCE: string = `${resourceSpringBootUrl}/update-delete`;
export const GET_PROJECT_FEEDBACKFORMS = `${environment.springBootUrl}/forms/PROJECT_FEEDBACK`;
export const GET_RESOURCE_CAPACITY = (resourceId: number, startDate: string) =>
  `${environment.springBootUrl}/project/resource/capacity?resourceId=${resourceId}&startDate=${startDate}`;

//project
export const GET_PROJECT_KANBAN_KEY = `${environment.springBootUrl}/project/validate-key`;
export const CREATE_EXTERNAL_PROJECT = `${environment.springBootUrl}/project/external`;
export const FETCH_JIRA_PROJECTS = `${environment.springBootUrl}/project/external/jira-project`;
export const GET_PROJECT_BY_ID = `${environment.springBootUrl}/project/get-project-by-id`;
export const SAVE_PROJECT_SETTINGS = `${environment.springBootUrl}/project/setting/save`;

export const deleteProjectUrl = (projectId: string) => {
  return `${environment.springBootUrl}/project/${projectId}`;
};

//document-repository
export const getWorkspaceUrl: string = `${environment.springBootUrl}/workspace`;
export const getDocsAndCollectionsUrl: string = `${environment.springBootUrl}/workspace`;
export const collectionUrl: string = `${environment.springBootUrl}/collection`;
export const documentUrl: string = `${environment.springBootUrl}/document`;
export const documentRenameUrl: string = `${environment.springBootUrl}/document/rename`;
// export const sendProjectFeedbackReminderUrl: string = `${environment.springBootUrl}/forms-module/project-feedback/reminder/email`;

//Kra kpi rating
export const getKraKpiRatingUrl = `${environment.springBootUrl}/forms-module/ratings`;

export const EMPLOYEE_LOGIN_V2 = `${environment.springBootUrlV2}/user/sign-in`;
export const getCostSettingsUrl = `${environment.springBootUrl}/cost-setting`;
export const editCostSettingUrl = `${environment.springBootUrl}/cost-setting`;

export const getMenteeListUrl = `${environment.springBootUrl}/mentee/list`;

// goals
export const getGoalsUrl = `${environment.springBootUrl}/goals/resource-goals`;
export const getPreSignedEndPointUrl = (payload: {
  fileName: string;
  folderName: string;
}): string => {
  return `${environment.springBootUrl}/aws?fileName=${payload.fileName}&folderName=${payload.folderName}`;
};
export const submitGoalByMenteeUrl = `${environment.springBootUrl}/goals/submit`;
export const updateGoalStatusUrl = `${environment.springBootUrl}/goals/status`;
export const goalDateExtendUrl = (id: number): string => {
  return `${environment.springBootUrl}/goals/extend/${id}`;
};
export const getCommentByGoalIdUrl = (goalId: number): string => {
  return `${environment.springBootUrl}/goals/${goalId}/comments`;
};
export const saveCommentUrl = `${environment.springBootUrl}/goals/comments`;
export const getKraKpiUrl = (payload: { resourceId: string }) =>
  `${environment.springBootUrl}/goals/questions?resourceId=${payload.resourceId}`;
export const saveGoalsUrl = `${environment.springBootUrl}/goals`;
export const acceptGoalsUrl = `${environment.springBootUrl}/goals/accept`;

export const editCommentUrl = (commentId: number): string => {
  return `${environment.springBootUrl}/goals/comments/${commentId}`;
};

export const changeRequest = `${environment.springBootUrl}/project/change-request`;
export const getGoalsRatingUrl = `${environment.springBootUrl}/goals/ratings`;

// project
export const getResourceTimesheetUrl = `${environment.springBootUrl}/worklog/time-sheet`;
export const getProjectResourceListUrl = `${environment.springBootUrl}/project/resource/id`;
export const getProjectResourceListForViewUrl = `${environment.springBootUrl}/project/resource`;
export const getResourceWorklogListUrl = `${environment.springBootUrl}/worklog/list`;
export const getResourceWorklogListByKeyUrl = `${environment.springBootUrl}/worklog/list-by-key`;
export const changeWorklogSettingUrl = `${environment.springBootUrl}/worklog/setting`;
export const allowWorklogEditUrl = `${environment.springBootUrl}/project/resource/allow-edit`;
export const downloadWorklogWithKeyUrl = `${environment.springBootUrl}/worklog/download-sheet-by-key`;
export const downloadResourceWorklogUrl: string = `${environment.springBootUrl}/worklog/download-sheet`;

//kanban
export const taskAPI = `${environment.springBootUrl}/tasks`;
export const taskStatusAPI = `${environment.springBootUrl}/tasks/status`;
export const taskComment = `${environment.springBootUrl}/tasks/comments`;

//kanban module
export const kanbanTaskListUrl = `${environment.springBootUrl}/tasks/list`;
export const changeKanbanTaskStatusUrl = `${environment.springBootUrl}/tasks/status`;
export const getBoardSettingListUrl = `${environment.springBootUrl}/project/setting/board-setting`;
export const getTaskListWithFiltersUrl = `${environment.springBootUrl}/tasks/filter`;

// forms
export const getFormsUrl = `${environment.springBootUrl}/forms`;
export const formByIdUrl: string = `${environment.springBootUrl}/forms/id`;
export const createFormUrl = `${environment.springBootUrl}/forms`;
export const updateFormUrl = `${environment.springBootUrl}/forms/update-form`;
export const copyFormUrl = `${environment.springBootUrl}/forms/copy-form`;
export const deleteFormByIdUrl: string = `${environment.springBootUrl}/forms`;

export const getRepoListUrl = `${environment.springBootUrl}/repo/list`;
export const assignProjectUrl = `${environment.springBootUrl}/repo/assign-project`;
export const getKriKpiTableUrl = `${environment.springBootUrl}/forms-module/quarterly-report`;
export const getGoalsCardsUrl = `${environment.springBootUrl}/goals/resource-goals`;

export const AddWorkLogUrlSlack = `${environment.springBootUrl}/worklog/slack`;

export const getTaskWorklogsUrl = (id: number | string) => {
  return `${environment.springBootUrl}/tasks/${id}/worklogs`;
};
export const editDeleteWorklogUrl = `${environment.springBootUrl}/tasks/worklog`;

// create repository
export const repository = `${environment.springBootUrl}/repo`;

export const addEditDeleteWorklogUrl = `${environment.springBootUrl}/tasks/worklog`;
export const remindAdminCostUrl = `${environment.springBootUrl}/cost-setting/email`;
