// inbox.slice.ts
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/httpInterceptor";
import { HTTP_OK } from "../../constants/status-code-constant";
import { getInboxStatus } from "../../utils/getInboxStatus";
import { INBOX_TYPE_VALUE } from "../../constants/const";
import {
  getInboxList,
  getWfhRequestListUrl,
  getWfhRequestWithIdUrl,
} from "../../constants/api-urls";
import { defaultExceptionHandler } from "../../utils/defaultExcpetionHandler";

interface InboxState {
  isLoading: boolean;
  monthly: any[];
  project: any[];
  wfh: any[];
  singleWfhRequest: any;
}

const initialState: InboxState = {
  isLoading: false,
  monthly: [],
  project: [],
  wfh: [],
  singleWfhRequest: {},
};

export const getProjectInboxAsync = createAsyncThunk(
  "inbox/getProjectInboxAsync",
  async (payload: { status: string }) => {
    try {
      const response = await axiosInstance.get(
        `${getInboxList}${INBOX_TYPE_VALUE.PROJECT}${getInboxStatus(
          payload.status
        )}`
      );
      if (response.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getMonthlyInboxAsync = createAsyncThunk(
  "inbox/getMonthlyInboxAsync",
  async (payload: { status: string }) => {
    try {
      const response = await axiosInstance.get(
        `${getInboxList}${INBOX_TYPE_VALUE.MONTHLY}${getInboxStatus(
          payload.status
        )}&resource=90`
      );
      if (response.status !== HTTP_OK) {
        throw new Error(response.data.data.error);
      }
      return response.data.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getWfhRequestWithIdAsync = createAsyncThunk(
  "inbox/getWfhRequestWithIdAsync",
  async (payload: any) => {
    try {
      const response = await axiosInstance.get(
        `${getWfhRequestWithIdUrl}?requestId=${payload.id}`
      );
      if (response.status !== HTTP_OK) {
        throw new Error(response.data.data.error);
      }
      return response.data.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getWfhRequestListAsync = createAsyncThunk(
  "inbox/getWfhRequestListAsync",
  async (payload: any) => {
    try {
      const response = await axiosInstance.post(getWfhRequestListUrl, payload);
      if (response.status !== HTTP_OK) {
        throw new Error(response.data.data.error);
      }
      return response.data.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectInboxAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjectInboxAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.project = action.payload;
      })
      .addCase(getProjectInboxAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getMonthlyInboxAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMonthlyInboxAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.monthly = action.payload;
      })
      .addCase(getMonthlyInboxAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getWfhRequestWithIdAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWfhRequestWithIdAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleWfhRequest = action.payload;
      })
      .addCase(getWfhRequestWithIdAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getWfhRequestListAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWfhRequestListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.wfh = action.payload;
      })
      .addCase(getWfhRequestListAsync.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default inboxSlice.reducer;
