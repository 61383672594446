import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import { gray, mecPrimary } from "../../constants/theme/colors";

const StyledSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  marginRight: "6px",
  marginLeft: "8px",
  height: 23,
  padding: 0,
  background: "none",
  "& .MuiSwitch-switchBase": {
    padding: 2,
    margin: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "white",
      "& + .MuiSwitch-track": {
        backgroundColor: mecPrimary[500],
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        background: gray[100],
        width: 15,
        height: 15,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    background: gray[400],
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#FAFAFA",
    height: "auto",
    border: "2px solid #6A7575",
  },
}));

export default StyledSwitch;
