import React, { useState } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import svg from '../../assets';
import IconButton from '@mui/material';
import { AccountMenuItems } from '../AccountMenu';

interface NavbarProps {
  showAccountMenu?: boolean;
  isCenter?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ showAccountMenu = true, isCenter = false }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="h-10 w-full bg-gray-50 fixed top-0 px-7 z-[100] border-b border-b-gray-300">
      <div className="flex h-full w-full items-center">
        <Box
          sx={{
            marginX: isCenter ? 'auto' : '0',
            marginRight: isCenter ? '0' : '96px',
          }}
          className="flex text-center justify-center"
        >
          <img src={svg.epicoLogo} alt="metrics" style={{ height: '32px', width: 'auto' }} />
        </Box>
        <div className="ml-auto space-x-4 flex items-center">
          {showAccountMenu && (
            <>
              <Box
                className="rounded-full p-1 bg-mecPrimary-100 flex items-center gap-[2px] cursor-pointer"
                onClick={handlePopoverOpen}
              >
                <Box className="rounded-full p-1 bg-primary text-[10px] text-white font-medium flex justify-center items-center w-[22px] h-[22px]">
                  MB
                </Box>
                <KeyboardArrowDownOutlined sx={{ width: '22px' }} />
              </Box>
              <Popover
                disableScrollLock
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <AccountMenuItems />
              </Popover>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
