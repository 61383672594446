export const constants = {
  nomineeFailed: 'Failed to save nominee. Please try again.',
  getUserRoleFailed: 'Failed to get user role:',
  reasonRequired: 'Reason is required',
  awardRequired: 'Award is required',
  nomineeRequired: 'Nominee is required',
  nomineeSuccessfullySaved: 'Your nomination has been submitted successfully',
  resourceUtilization: 'Resource Utilization',
  downloadResourceUtilizationReport: 'Download resource utilization report',
  resourceAvailability: 'Resource Availability',
  downloadResourceAvailabilityReport: 'Download resource availability report',
  goalsSheet: 'Goals Sheet',
  developer: 'Developer',
  qa: 'QA',
  uiUx: 'UI/UX',
  pm: 'PM',

  devops: 'DevOps',
  marketing: 'Marketing',
  vendor: 'Vendor',
  statsSales: 'Sales',
  resources: 'Resources',
  projects: 'Projects',
  repositories: 'Repositories',
  statistics: 'Statistics',
  bpcNominations: 'Award - Nominations',
  sales: 'SALES',
  hr: 'HR',
  admin: 'ADMIN',
  livingByValues: 'Living by values',
  coachingChampion: 'Coaching champion',
  teamPlayer: 'Team player',
  alwaysGrowing: 'Always growing',
  goingAboveAndBeyond: 'Going above and beyond',
  nominationForBpc: 'Award - Nominations',
  nominatedByRequired: 'Nominated By is required',
  emailMustBeMindbowser: 'Email must be @mindbowser.com',
  forWhichAward: 'For which award do you want to nominate?',
  reasonRequiredNomination: 'Reason is required',
  reasonForNomination: 'Reason for nomination',
  enterReasonHere: 'Enter reason here',
  provideReasonNomination: 'Please provide a reason for the nomination.',
  nomineeName: 'Nominee Name',
  nominatedBy: 'Nominated By',
  award: 'Award',
  reason: 'Reason',
  noDataAvailable: 'No data available',
  viewReason: 'View Reason',
  chooseNominee: 'Choose Nominee:',
};
