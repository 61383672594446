import {
  getAllDesignationUrl,
  getAllDesignationWithNoKraKpiUrl,
  getKraAndKpiByDesignationUrl,
  postCreateKraAndKpiUrl,
} from "../constants/api-urls";
import { HTTP_OK } from "../constants/status-code-constant";
import { Kra } from "../pages/KraAndKpi/Interface";
import axiosInstance from "../utils/httpInterceptor";

export const getAllDesignation = async () => {
  try {
    const response = await axiosInstance.get(`${getAllDesignationUrl}`);

    if (response?.status !== HTTP_OK) {
      throw new Error(response?.data?.data?.error);
    }
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getAllDesignationWithNoKraKpi = async () => {
  try {
    const response = await axiosInstance.get(
      `${getAllDesignationWithNoKraKpiUrl}`
    );

    if (response?.status !== HTTP_OK) {
      throw new Error(response?.data?.data?.error);
    }
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getKraAndKpiByDesignation = async (params: {
  designation: string;
}) => {
  try {
    const response = await axiosInstance.get(
      `${getKraAndKpiByDesignationUrl}${params.designation}`
    );

    if (response?.status !== HTTP_OK) {
      throw new Error(response?.data?.data?.error);
    }
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const postCreateKraAndKpi = async (payload: Kra) => {
  try {
    const response = await axiosInstance.post(
      `${postCreateKraAndKpiUrl}`,
      payload
    );

    if (response?.status !== HTTP_OK) {
      throw new Error(response?.data?.data?.error);
    }
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const putEditKraAndKpi = async (payload: Kra) => {
  try {
    const response = await axiosInstance.put(
      `${postCreateKraAndKpiUrl}`,
      payload
    );

    if (response?.status !== HTTP_OK) {
      throw new Error(response?.data?.data?.error);
    }
    console.log(response);
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};
