// src/features/example/exampleSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { projectByIDUrl, projectListUrl, sprintUrl } from '../../constants/api-urls';
import axiosInstance from '../../utils/httpInterceptor';
import { authUpdateAndReload } from '../../services/authServices';
import { FAIL_TO_FETCH_DATA } from '../../constants/messages';

export interface Sprint {
  id: string;
  name: string;
  startDate?: string;
  endDate?: string;
  invoicePercentage?: number;
  status?: string;
  state?: string;
  sprintRetrospect?: boolean;
}

interface SprintState {
  sprintList: Sprint[];
  selectedSprint: Sprint | null;
  totalRecord: number;
  loading: boolean;
  error: string | null;
}

const initialState: SprintState = {
  sprintList: [],
  selectedSprint: null,
  totalRecord: 0,
  loading: false,
  error: null,
};

// Define async thunk for API call
export const fetchSprintList = createAsyncThunk('sprint/fetchList', async (projectId: number) => {
  const response = await axiosInstance.get<any>(`${sprintUrl}?id=${projectId}`);

  if (response.data?.tokenExpire) {
    authUpdateAndReload();
    return;
  }

  return response.data.data;
});

export const fetchSprint = createAsyncThunk('sprint/fetch', async (sprintId: number) => {
  const response = await axiosInstance.get<any>(`${sprintUrl}/${sprintId}`);

  if (response.data?.tokenExpire) {
    authUpdateAndReload();
    return;
  }

  return response.data.data;
});

const sprintReducer = createSlice({
  name: 'sprint',
  initialState,
  reducers: {
    resetSprint: state => initialState,
    selectSprint: (state, action) => ({ ...state, selectedSprint: action.payload }),
    showLoading: (state, action) => ({ ...state, loading: action.payload }),
  },
  extraReducers: builder => {
    builder.addCase(fetchSprintList.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSprintList.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.sprintList = action.payload;
      state.selectedSprint = null;
      state.totalRecord = action.payload.length;
    });
    builder.addCase(fetchSprintList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });

    builder.addCase(fetchSprint.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSprint.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.selectedSprint = action.payload;
    });
    builder.addCase(fetchSprint.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });
  },
});

export const { resetSprint, selectSprint, showLoading } = sprintReducer.actions;
export default sprintReducer.reducer;
