import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../utils/httpInterceptor";
import {
  getFeedbackDataSourceUrl,
  getMonthlyFeedbackQuesUrl,
  getProjectFeedbackQuesUrl,
} from "../../constants/api-urls";
import { HTTP_OK } from "../../constants/status-code-constant";
import { defaultExceptionHandler } from "../../utils/defaultExcpetionHandler";

const initialState = {
  dataSource: [],
  projectFeedbackQuestion: {},
  monthlyFeedbackQuestion: {},
  isQuestionLoading: false,
  isLoading: false,
};

export const getFeedbackDataSource = createAsyncThunk(
  "feedback/getFeedbackDataSource",
  async (params: any) => {
    try {
      const response = await axiosInstance.get(
        `${getFeedbackDataSourceUrl}?filledForId=${params.filledForId}&sectionId=${params.sectionId}&questionId=${params.questionId}&month=${params.month}&year=${params.year}`
      );
      if (response?.status !== HTTP_OK) {
        console.log("response", response);
        throw new Error("Something went wrong!");
      }
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProjectFeedbackQues = createAsyncThunk(
  "feedback/getProjectFeedbackQues",
  async (params: any) => {
    try {
      const response = await axiosInstance
        .get(`${getProjectFeedbackQuesUrl}?inboxUuid=${params.id}`)
        .catch((error) => {
          throw defaultExceptionHandler(error);
        });
      if (response?.status !== HTTP_OK) {
        console.log("response", response);
        throw new Error("Something went wrong!");
      }
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getMonthlyFeedbackQues = createAsyncThunk(
  "feedback/getMonthlyFeedbackQues",
  async (params: any) => {
    try {
      const response = await axiosInstance
        .get(`${getMonthlyFeedbackQuesUrl}?inboxUuid=${params.id}`)
        .catch((error) => {
          throw defaultExceptionHandler(error);
        });
      if (response?.status !== HTTP_OK) {
        console.log("response", response);
        throw new Error("Something went wrong!");
      }
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    clearState: (state, action) => {
      state.projectFeedbackQuestion = {};
      state.monthlyFeedbackQuestion = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbackDataSource.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFeedbackDataSource.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dataSource = action?.payload;
      })
      .addCase(getFeedbackDataSource.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(getProjectFeedbackQues.pending, (state, action) => {
        state.isQuestionLoading = true;
      })
      .addCase(getProjectFeedbackQues.fulfilled, (state, action) => {
        state.isQuestionLoading = false;
        state.projectFeedbackQuestion = action?.payload;
      })
      .addCase(getProjectFeedbackQues.rejected, (state, action) => {
        state.isQuestionLoading = false;
      })
      .addCase(getMonthlyFeedbackQues.pending, (state, action) => {
        state.isQuestionLoading = true;
      })
      .addCase(getMonthlyFeedbackQues.fulfilled, (state, action) => {
        state.isQuestionLoading = false;
        state.monthlyFeedbackQuestion = action?.payload;
      })
      .addCase(getMonthlyFeedbackQues.rejected, (state, action) => {
        state.isQuestionLoading = false;
      });
  },
});

export const { clearState } = feedbackSlice.actions;
export default feedbackSlice.reducer;
