import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  Popper,
  styled,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { mecPrimary } from "../../constants/theme/colors";
import { MuiAutocompleteChipProps } from "../../constants/app-constant";
import { StyledPopper, StyledTextField } from "./style";

const AutocompleteMultiSelectWithCheckBox = ({
  options,
  value,
  label,
  error,
  helperText,
  isRequired,
  onChange = () => {},
  onBlur,
  disabled = false,
  placeholder,
}: MuiAutocompleteChipProps) => {
  return (
    <Box sx={{ minWidth: 120 }} className="flex flex-col">
      <Typography variant="mec_body1" className="!mb-1">
        {label} {isRequired ? "*" : null}
      </Typography>
      <Autocomplete
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        PopperComponent={StyledPopper}
        multiple
        blurOnSelect={false}
        id="tags-standard"
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.label}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        renderOption={(props, option, { selected }) => {
          return (
            <li
              {...props}
              style={{ backgroundColor: "transparent" }}
              key={option.value}
            >
              <Box width={"100%"}>
                <Checkbox checked={selected} />
                <Typography variant=" mec_body1"> {option.label}</Typography>
                <Divider />
              </Box>
            </li>
          );
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: null,
            }}
          />
        )}
        slotProps={{
          paper: {
            className: "shadow-lg",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            padding: "0px",
          },
        }}
        ListboxProps={{
          sx: {
            "& .MuiAutocomplete-option": {
              fontSize: "16px",
              backgroundColor: "transparent",
              "&[aria-selected=true]": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiAutocomplete-option[aria-selected=true]:hover": {
              backgroundColor: "transparent",
            },
          },
        }}
        disabled={disabled}
        renderTags={() => null}
      />
    </Box>
  );
};

export default AutocompleteMultiSelectWithCheckBox;
