import { Breadcrumbs } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface Breadcrumb {
  label: string;
  path: string;
}

interface BreadcrumbsProps {
  customCrumb: Breadcrumb[];
}

const CustomBreadCrumb: React.FC<BreadcrumbsProps> = ({ customCrumb }) => {
  return (
    <Breadcrumbs>
      {customCrumb?.map((breadcrumb: Breadcrumb, index: number) => {
        const isLast = index === customCrumb.length - 1;
        return isLast ? (
          <p key={breadcrumb.path} className="text-gray-extradark capitalize text-sm font-medium">
            {breadcrumb.label}
          </p>
        ) : (
          <Link
            to={breadcrumb.path}
            key={breadcrumb.path}
            className="text-gray capitalize text-sm font-medium"
          >
            {breadcrumb.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadCrumb;
