import moment from 'moment';

export const capitalizeFirstLetter = (str: string) => {
  return str
    .split(' ')
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export const convertToTitleCase = (str?: string) => {
  return str
    ?.toLowerCase()
    ?.split('_')
    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
};

export const base64UrlDecode = (base64Url: string): string => {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return jsonPayload;
};

export const decodeJwt = (token: string): any => {
  const base64Url = token.split('.')[1];
  const decodedPayload = base64UrlDecode(base64Url);
  return JSON.parse(decodedPayload);
};

export const getDaysDifference = (date1: string, date2?: string): number => {
  const firstDate = moment(date1);
  const secondDate = moment(date2);

  return secondDate.diff(firstDate, 'days');
};
