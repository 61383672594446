import {
  HomeOutlined,
  EmailOutlined,
  ArticleOutlined,
  BarChartOutlined,
  FolderOutlined,
  AccessTimeOutlined,
  GroupAddOutlined,
  PeopleOutline,
  TrendingUpOutlined,
  PieChartOutline,
  MessageOutlined,
} from '@mui/icons-material';
import { RouteConstants } from './route-constant';
import { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

import { Theme } from '@emotion/react';
import svg from '../assets';

export const DialogConstants = {
  dialogIds: {
    CREATE_DOCUMENT_DIALOG: 'createDocumentDialog',
    CREATE_REMINDER_DIALOG: 'createReminderDialog',
    CREATE_NEW_TASK_DIALOG: 'createNewTaskDialog',
    UPLOAD_RESOURCE_DETAILS_DIALOG: 'uploadResourceDetailsDialog',
    CREATE_NEW_KRA_KPI_DIALOG: 'createNewKraKpiDialog',
    EDIT_KRA_KPI_DIALOG: 'editKraKpiDialog',
    CREATE_NEW_KRA_DIALOG: 'createNewKra',
    RENAME_KRA_DIALOG: 'renameKraDialog',
    CONFIRM_DELETE_KRA: 'deleteKraDialog',
    CONFIRM_SUBMIT_KRA_KPI_DIALOG: 'confirmSubmitDialog',
    CONFIRM_CLEAR_KRA_KPI_DIALOG: 'confirmClearDialog',
    CONFIRM_BACK_SPRINT_RETRO: 'confirmBackSprintRetro',
    OPEN_CR_DETAIL_DIALOG: 'openCrDetailDialog',
    NOMINATION_FORM_DIALOG: 'nominationFormDialog',
    ADD_PLATFORM_USER: 'addplatfromUserDialog',
    GOAL_SHEET_DIALOG: 'goalSheetDialog',
    BPC_AWARD_REASON_DIALOG: 'bpcAwardReasonDialog',
    ONBOARD_RESOURCE_EDIT_CONFIRM_DIALOG: 'onboardResourceEditConfirmDialog',
    RESOURCE_EDIT_CONFIRM_DIALOG: 'resourceEditConfirmDialog',
    RESOURCE_REGISTER_CONFIRM_DIALOG: 'resourceEditConfirmDialog',
    ONBOARD_RESOURCE_EDIT_CONFIRM_STEP_TWO_DIALOG: 'onboardResourceEditConfirmStepTwoDialog',
    SEND_FEEDBACK_FORM_DIALOG: 'sendFeedbackFormDialog',
    SPRINT_MARK_AS_COMPLETED_DIALOG: 'sprintMarkAsCompletedDialog',
    ADD_RESOURCES_FORM_DIALOG: 'addResourcesFormDialog',
    FORCE_UPDATE_RESOURCES_FORM_DIALOG: 'forceUpdateResourcesFormDialog',
    DELETE_RESOURCES_DIALOG: 'deleteResourcesDialog',
    EDIT_PLATFORM_USER: 'editPlatformUser',
    DELETE_PLATFORM_USER: 'deletePlatformUser',
    INVITE_RESOURCE_DIALOG: 'inviteResourceDialog',
    CONFIRM_ALLOW_WORKLOG_EDIT: 'confirmAllowWorklogEdit',
    SHARE_BUTTON_DIALOG: 'shareButtonDialog',
    DOWNLOAD_WORKLOG_DIALOG: 'downloagWorklogDialog',
    SELECT_EXISTING_JIRA_PROJECTS: 'selectExistingJiraProjects',
    ADD_KANBAN_TASK_DIALOG: 'addKanbanTaskDialog',
    TASK_DETAILS_VIEW: 'taskDetailsDialog',
    PROJECT_SETTING_DIALOG: 'projectSettingDialog',
    DELETE_BOARD_COLUMN: 'deleteColumnDialog',
    DELETE_FORM: 'deleteForm',
    COPY_FORM: 'copyForm',
    ADD_BITBUCKET_DIALOG: 'assignBitbucketProject',
    DELETE_WORKLOG_DAILOG: 'deleteWorklogDailog',
    KANBAN_WORKLOG_DAILOG: 'kanbanWorkLogDialog',
    KANBAN_TASK_DELETE_DAILOG: 'kanbanTaskDeleteDialog',
    ADD_NEW_PROJECT_DIALOG: 'addNewProjectDialog',
    PROJECT_HISTORY_DETAIL_DIALOG: 'projectHistoryDetailDialog',
    PROJECT_EDIT_REASON_DIALOG: 'projectEditReasonDialog',
    APPROVE_GOAL_DIALOG: 'approveGoalDialog',
    REJECT_GOAL_DIALOG: 'rejectGoalDialog',
    MARK_AS_INCOMPLETE_GOAL_DIALOG: 'marAsIncompleteGoalDialog',
    EXTEND_GOAL_END_DATE_DIALOG: 'extendGoalEndDateDialog',
    MARK_AS_INCOMPLETE_GOAL_CONFIRM_DIALOG: 'marAsIncompleteGoalConfirmDialog',
    SUBMIT_GOAL_DIALOG: 'submitGoalDialog',
    EDIT_GOAL_COMMENT_DIALOG: 'editGoalCommentDialog',
    DOWNLOAD_WORKLOGS_DIALOG: 'downloadWorklogsDialog',
    WORKLOG_WARNING_DIALOG: 'workLogWarningDialog',
    PROJECT_SETTING_UNSAVED_CHANGES: 'projectSettingsUnsavedChangesDialog',
    ADD_EDIT_INVOICE_PERCENTAGE_DIALOG: 'addOrEditInvoicePercentageDialog',
    MARK_SPRINT_COMPLETED_DIALOG: 'markSprintAsCompletedDialog',
    DELETE_PROJECT: 'delete-project',
    ADD_YOUR_TECHNOLOGY_DIALOG: 'addYourTechnologyDialog',
    ADD_YOUR_SKILL_DIALOG: 'addSkillDialog',
    ONBOARD_RESOURCE_AS_CONSULTANT_CONFIRM_DIALOG: 'onboardResourceAsConsultantConfirmDialog',
  },
};

export enum USER_ROLE {
  ADMIN = 'ADMIN',
  PM = 'PM',
  SALES = 'SALES',
  USER = 'USER',
  VENDOR = 'VENDOR',
  HR = 'HR',
}

export enum PROJECT_TYPE {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  KANBAN = 'KANBAN',
}

export interface SideBarTab {
  label: string;
  icon: JSX.Element;
  to: string;
  roles?: USER_ROLE[];
}

export const SideBarTabs: SideBarTab[] = [
  {
    label: 'dashboard',
    icon: <BarChartOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_DASHBOARD,
    roles: [USER_ROLE.ADMIN, USER_ROLE.SALES, USER_ROLE.PM, USER_ROLE.HR],
  },
  {
    label: 'mentees',
    icon: <PeopleOutline fontSize="small" />,
    to: '/mentees',
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.PM],
  },
  {
    label: 'projects',
    icon: <FolderOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_PROJECT,
    roles: [USER_ROLE.ADMIN, USER_ROLE.SALES, USER_ROLE.PM, USER_ROLE.USER, USER_ROLE.VENDOR],
  },
  {
    label: 'resources',
    icon: <GroupAddOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_RESOURCES,
    roles: [USER_ROLE.ADMIN, USER_ROLE.SALES, USER_ROLE.HR],
  },
  {
    label: 'profit & loss',
    icon: <TrendingUpOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_PROFIT_LOSS,
    roles: [USER_ROLE.ADMIN],
  },
  {
    label: 'forms',
    icon: <ArticleOutlined fontSize="small" />,
    to: '/forms',
    roles: [USER_ROLE.ADMIN],
  },
  {
    label: 'platform users',
    icon: <PeopleOutline fontSize="small" />,
    to: RouteConstants.ROUTE_PLATFORM_USERS,
    roles: [USER_ROLE.ADMIN],
  },
  {
    label: 'inbox',
    icon: <EmailOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_INBOX,
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.PM],
  },
  {
    label: 'goals',
    icon: (
      <Box className="relative w-5 h-5 inline-block">
        <Box
          component="img"
          src={svg.pieChartIcon}
          alt="goal-icon"
          className="w-5 h-5 top-0 left-0 absolute"
        />
        <Box
          className="absolute top-0 left-0 w-full h-full bg-primary pointer-events-none mix-blend-screen hidden"
          sx={{
            '.text-primary &': {
              display: 'inline-block',
            },
          }}
        />
      </Box>
    ),
    to: RouteConstants.ROUTE_GOALS,
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.PM],
  },
  // will use in future
  // {
  //   label: 'feedback',
  //   icon: <MessageOutlined fontSize="small" />,
  //   to: '/feedback',
  //   roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.PM],
  // },
  {
    label: 'work from home',
    icon: <AccessTimeOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_WFH_REQUEST,
    roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.PM],
  },
];

interface Month {
  label: string;
}

export const listOfMonths: Month[] = [
  { label: 'January' },
  { label: 'February' },
  { label: 'March' },
  { label: 'April' },
  { label: 'May' },
  { label: 'June' },
  { label: 'July' },
  { label: 'August' },
  { label: 'September' },
  { label: 'October' },
  { label: 'November' },
  { label: 'December' },
];

interface NatureOfWorkOption {
  label: string;
  key: string;
}

export const natureOfWorkOptions: NatureOfWorkOption[] = [
  { label: 'Work from office', key: 'WFO' },
  { label: 'Work from home', key: 'WFH' },
  { label: 'Hybrid (2+3)', key: 'HYBRID_2_3' },
  { label: 'Hybrid (3+2)', key: 'HYBRID_3_2' },
];
export const listOfYears: number[] = [2020, 2021, 2022, 2023, 2024];

export const severity = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

export const MAT_TAB_MONTHS = [
  { value: 0, label: 'Jan' },
  { value: 1, label: 'Feb' },
  { value: 2, label: 'Mar' },
  { value: 3, label: 'Apr' },
  { value: 4, label: 'May' },
  { value: 5, label: 'Jun' },
  { value: 6, label: 'Jul' },
  { value: 7, label: 'Aug' },
  { value: 8, label: 'Sep' },
  { value: 9, label: 'Oct' },
  { value: 10, label: 'Nov' },
  { value: 11, label: 'Dec' },
];

export const MAT_SELECT_YEARS = [
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
];

export const monthMap: { [key: string]: number } = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const SKILLS_AND_INTEGRATIONS = {
  integrations: [
    { name: '1UpHealth', checked: false },
    { name: 'Terra', checked: false },
    { name: 'Twilio (SMS)', checked: false },
    { name: 'Stripe', checked: false },
    { name: 'AWS S3', checked: false },
    { name: 'Firebase', checked: false },
    { name: 'Mixpanel', checked: false },
    { name: 'AWS Cognito', checked: false },
    { name: 'Auth0', checked: false },
    { name: 'Twilio (Chat)', checked: false },
    { name: 'TokBox', checked: false },
    { name: 'Dosespot', checked: false },
    { name: 'Sendgrid', checked: false },
    { name: 'Aghora', checked: false },
    { name: 'Braintree', checked: false },
    { name: 'Paypal', checked: false },
    { name: 'Google Analytics', checked: false },
    { name: 'In-app purchase', checked: false },
    { name: 'Apple Pay', checked: false },
    { name: 'ClimateIQ', checked: false },
    { name: 'Mapbox', checked: false },
    { name: 'Google Places API', checked: false },
    { name: 'Facebook Graph API', checked: false },
    { name: 'PayU', checked: false },
    { name: 'Firebase Cloud Messaging', checked: false },
    { name: 'LLM', checked: false },
    { name: 'ChatGPT', checked: false },
    { name: 'Generative AI', checked: false },
    { name: 'AWS - SNS', checked: false },
    { name: 'AWS - SQS', checked: false },
    { name: 'Vanta', checked: false },
    { name: 'OCR', checked: false },
    { name: 'Amity Social SDK', checked: false },
    { name: 'GetSocial', checked: false },
    { name: 'SharpSports', checked: false },
    { name: 'Amplify', checked: false },
    { name: 'Zendesk', checked: false },
    { name: 'Intercom', checked: false },
    { name: 'Phillips Hue', checked: false },
    { name: 'Slack', checked: false },
    { name: 'Kissmetrics', checked: false },
    { name: 'BLE', checked: false },
    { name: 'AWS IoT', checked: false },
    { name: 'Google Core IoT', checked: false },
    { name: 'Cloud Functions', checked: false },
    { name: 'Twilio (Video/Audio)', checked: false },
    { name: 'Plotly', checked: false },
    { name: 'ChartJS', checked: false },
    { name: 'VictoryCharts', checked: false },
    { name: 'Swagger', checked: false },
  ],
};

export const ROLE_OPTIONS = [
  { label: 'FRONTEND', value: 'FRONTEND' },
  { label: 'BACKEND', value: 'BACKEND' },
  { label: 'FULLSTACK', value: 'FULLSTACK' },
  { label: 'PM', value: 'PM' },
  { label: 'DEVOPS', value: 'DEVOPS' },
  { label: 'QA', value: 'QA' },
  { label: 'DESIGNER', value: 'DESIGNER' },
  { label: 'DATASCIENCE', value: 'DATASCIENCE' },
  { label: 'MOBILE', value: 'MOBILE' },
];

export const NATURE_OF_WORK = [
  { label: 'Work from office', value: 'WFO' },
  { label: 'Work from home', value: 'WFH' },
  { label: 'Hybrid (2+3)', value: 'HYBRID_2_3' },
  { label: 'Hybrid (3+2)', value: 'HYBRID_3_2' },
];

export const QUARTERS: { label: string; value: string }[] = [
  { label: 'April - June', value: 'QUARTER_ONE' },
  { label: 'July - September', value: 'QUARTER_TWO' },
  { label: 'October - December', value: 'QUARTER_THREE' },
  { label: 'January - March', value: 'QUARTER_FOUR' },
];

export const monthNames: { [key: string]: string } = {
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

export const types = [
  { value: 'FIXED_COST', label: 'Fixed Cost' },
  { value: 'TANDM', label: 'T&M' },
];

export type optionsType = {
  label: string;
  value: string | number;
};

export interface MuiAutocompleteChipProps {
  options: optionsType[];
  value: optionsType[];
  label?: string;
  error?: boolean;
  helperText?: string;
  isRequired?: boolean;
  onChange?: (event: any, newValue: optionsType[]) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  disabled?: boolean;
  placeholder?: string;
}

export interface FilterProfitLossProjectDialogProps {
  open: boolean;
  onClose: () => void;
}

export interface ProfitLossCustomTableData {
  id: number | string;
  [key: string]: any;
}

export interface ProfitLossCustomTableColumnsProps {
  id: string;
  label: any;
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  icon?: ReactNode;
  cellRenderer?: (data: any, rowData: ProfitLossCustomTableData) => ReactNode;
  colWidth?: string | number;
  visible?: boolean;
  parentLabel?: string;
  parentLabelColor?: any;
  bgColor?: string;
  cellTextColor?: string;
  bold?: boolean;
  textColor?: string;
}

export interface ProfitLossCustomTableProps {
  columns: ProfitLossCustomTableColumnsProps[];
  data: ProfitLossCustomTableData[];
  onRowClick?: (rowData: any) => void | null;
  sx?: SxProps<Theme> | undefined;
  infiniteScroll?: boolean;
  handleScroll?: any;
  currentLength?: number;
  totalLength?: number;
  loading?: Boolean;
  noDataMessage?: string;
  height?: string;
  renderHeader?: boolean;
  renderParentHeader?: boolean;
  renderSubHeader?: boolean;
  tableHeading?: string;
}

export interface DateRangePickerProps {
  selectRange: any;
  handleRangeChange: (ranges: any) => void;
}
export const NON_JIRA_PROJECT_STEPS = [
  {
    label: 'Project Details',
  },
  {
    label: 'Client Details',
  },
];

export const JIRA_PROJECT_STEPS = [
  {
    label: 'Project Details',
  },
  {
    label: 'Client Details',
  },
  {
    label: 'Jira Settings',
  },
];

export interface Project {
  id: number;
  name: string;
  projectType: string;
  startDate: string;
  endDate: string;
  status: string;
  title?: string;
}
export interface TableHeaderProps {
  columns: Array<any>;
}

export const sectionHeaders = [
  { label: 'Project Details', colSpan: 5, backgroundColor: '#e7e7e7' },
  { label: 'Client Estimate', colSpan: 3 },
  { label: 'Team Estimate', colSpan: 3, backgroundColor: '#e7e7e7' },
  { label: 'Actual Estimate', colSpan: 3 },
  { label: 'Estimated', colSpan: 3, backgroundColor: '#e7e7e7' },
];

export interface Statement {
  projectDetailsModel?: {
    projectName?: string;
    resourceRole?: string;
    projectType?: string;
    projectCostType?: string | null;
    tAndMType?: string | null;
    utilization?: number;
    message?: string | null;
  };
  clientEstimate?: {
    rate?: number;
    hours?: number;
    cost?: number;
  } | null;
  teamEstimate?: {
    rate?: number;
    hours?: number;
    cost?: number;
  } | null;
  actualEstimate?: {
    rate?: number;
    hours?: number;
    cost?: number;
  } | null;
  estimated?: {
    rate?: number;
    hours?: number;
    cost?: number;
  } | null;
  costVarience?: number;
}

export interface Resource {
  resourceName?: string;
  resourceId?: number;
  resourceStatementModel?: Statement[] | null;
  actualEstimateCost: number | null;
  teamEstimateCost: number | null;
  clientEstimateCost: number | null;
  totalUtilization: number | null;
  totalCostVariance: number | null;
  estimateCost?: number | null;
}

export interface TableRowsProps {
  data: Resource[];
  getValueById: any;
  generateColumns: any;
  overAllCostObj: any;
}

export interface ProjectDetails {
  name?: string;
  resourceRole?: string | null;
  projectType?: string | null;
  projectCostType?: string | null;
  tAndMType?: string | null;
  utilization?: number;
}

export interface Estimate {
  rate?: number;
  hours?: number;
  cost?: number;
}

export interface FilterTableStatementProps {
  projectDetailsModel?: ProjectDetails | null;
  clientEstimate?: Estimate | null;
  teamEstimate?: Estimate | null;
  actualEstimate?: Estimate | null;
  estimated?: Estimate | null;
  costVarience?: number;
}

export interface FilterTableColumnProps {
  label?: string;
  id: any;
  defaultValue?: string;
  colWidth?: string;
}

export interface ProfitLossFilterTableComponentProps {
  resData?: any;
}

export const generateColumns = ([]): FilterTableColumnProps[] => {
  const columns: FilterTableColumnProps[] = [
    {
      label: 'Project Name',
      id: 'projectDetailsModel.projectName',
      colWidth: '200px',
    },
    { label: 'Role', id: 'projectDetailsModel.role' },
    {
      label: 'Project Type',
      id: 'projectDetailsModel.projectType',
      colWidth: '140px',
    },

    {
      label: 'Cost Type',
      id: 'projectDetailsModel.projectCostType',
      colWidth: '140px',
    },

    { label: 'Utilization', id: 'projectDetailsModel.utilization' },
    {
      label: 'Rate',
      id: 'clientEstimate.rate',
      defaultValue: '-',
    },
    {
      label: 'Hours',
      id: 'clientEstimate.hours',
      defaultValue: '-',
    },
    { label: 'Cost', id: 'clientEstimate.cost', defaultValue: '-' },
    { label: 'Rate', id: 'teamEstimate.rate', defaultValue: '-' },
    { label: 'Hours', id: 'teamEstimate.hours', defaultValue: '-' },
    { label: 'Cost', id: 'teamEstimate.cost', defaultValue: '-' },
    {
      label: 'Rate',
      id: 'actualEstimate.rate',
      defaultValue: '-',
    },
    {
      label: 'Hours',
      id: 'actualEstimate.hours',
      defaultValue: '-',
    },
    { label: 'Cost', id: 'actualEstimate.cost', defaultValue: '-' },
    { label: 'Rate', id: 'estimated.rate', defaultValue: '-' },
    {
      label: 'Hours',
      id: 'estimated.hours',
      defaultValue: '-',
    },
    { label: 'Cost', id: 'estimated.cost', defaultValue: '-' },
    {
      label: 'Cost Variance',
      id: 'costVariance',
      defaultValue: '-',
      colWidth: '140px',
    },
  ];
  return columns;
};

export function formatBackendValue(value: string): string {
  if (typeof value !== 'string') return value;

  if (value === 'PM') {
    return 'PM';
  }
  const formattedValue = value
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase());

  return formattedValue;
}

export const projectTypes = {
  jira: 'jira',
  nonJira: 'non-jira',
  kanban: 'kanban',
};

export const ALLOWED_FILE_TYPES_RESUME = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const MenuList1 = [
  { title: 'Dashboard', index: 0 },
  { title: 'Project Detail', index: 1 },
  { title: 'Team Members', index: 2 },
  { title: 'Resource Timesheet', index: 3 },
  { title: 'Cost Settings', index: 4 },
];
export const MenuList2 = [
  { title: 'Dashboard', index: 0 },
  { title: 'Project Detail', index: 1 },
  { title: 'Team Members', index: 2 },
];
