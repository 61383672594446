import { saveAs } from "file-saver";

export const downloadFile = (
  b64encodedString: string,
  projectName: string
): void => {
  if (b64encodedString) {
    const today = new Date();
    const dateobj = formatDate(today);
    const blob = base64ToBlob(b64encodedString, "text/plain");
    saveAs(
      blob,
      `${projectName.toLowerCase().replace(/\s+/g, "-")}-worklog-${dateobj}.xls`
    );
  }
};

const formatDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const base64ToBlob = (base64: string, contentType: string): Blob => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
