import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SearchState {
  searchString: string;
}
const initialState: SearchState = {
  searchString: "",
};
const searchReducer = createSlice({
  name: "setData",
  reducers: {
    addSearchString(state, action: PayloadAction<any>) {
      state.searchString = action.payload;
    },
  },
  initialState: initialState,
});

export const { addSearchString } = searchReducer.actions;
export default searchReducer.reducer;
