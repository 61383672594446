import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useAppSelector } from "../../store/hooks";
import { Outlet, useNavigate } from "react-router-dom";

export interface Tab {
  label: string;
  tabIcon?: React.ReactNode;
  activeTabIcon?: React.ReactNode;
  inActiveTabIcon?: React.ReactNode;
  actionButtons?: React.ReactNode;
  to?: string;
  content?: any;
  isTabActive?: boolean;
}

interface CustomTabsProps {
  tabs: Tab[];
  tabContainerClassName?: string;
  tabContentClassName?: string;
  buttonClassName?: string;
  activeButtonClassName?: string;
  hasOutlet?: boolean;
  handleTabChange?: (label: string, to: string | undefined) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabs,
  tabContainerClassName = "",
  tabContentClassName = "",
  buttonClassName = "",
  activeButtonClassName = "border-primary text-primary",
  hasOutlet = false,
  handleTabChange = () => {},
}) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.label ?? "");
  const collapsed = useAppSelector((state) => state.sidebarReducer.collapsed);

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      const newSelectedTab = tabs.reduce(
        (a, t) => (t.isTabActive ? t.label : a),
        tabs[0]?.label
      );
      setSelectedTab(newSelectedTab);
    }
  }, [tabs]);

  const handleTabClick = (label: string, to?: string) => {
    setSelectedTab(label);
    if (to) {
      navigate(to);
    }
    handleTabChange(label, to);
  };

  return (
    <div className="w-full">
      <div
        className={classNames(
          "flex flex-row justify-between items-center space-x-5 px-5 border-b z-50",
          {
            "w-[calc(100%_-_250px)]": !collapsed,
            "w-[calc(100%_-_80px)]": collapsed,
          },
          tabContainerClassName
        )}
      >
        <div className="flex flex-row gap-4">
          {tabs.map((tab) => (
            <button
              id={tab.label}
              key={tab.label}
              className={classNames(
                "p-2 text-sm capitalize border-b-2",
                buttonClassName,
                {
                  [activeButtonClassName]: selectedTab === tab.label,
                  "border-white": selectedTab !== tab.label,
                }
              )}
              onClick={() => handleTabClick(tab.label, tab?.to)}
            >
              {tab.activeTabIcon && tab.inActiveTabIcon ? (
                <span className="mr-1">
                  {selectedTab === tab.label
                    ? tab.activeTabIcon
                    : tab.inActiveTabIcon}
                </span>
              ) : (
                tab.tabIcon && <span className="mr-1">{tab.tabIcon}</span>
              )}
              {tab.label}
            </button>
          ))}
        </div>
        <div className="flex flex-row gap-4">
          {tabs?.find((tab) => selectedTab === tab.label)?.actionButtons ??
            null}
        </div>
      </div>
      <div className={`${tabContentClassName}`}>
        {hasOutlet ? (
          <Outlet />
        ) : (
          tabs.map((tab) =>
            selectedTab === tab.label ? (
              <div
                key={tab.label}
                className={selectedTab === tab.label ? "block" : "hidden"}
              >
                {tab.content}
              </div>
            ) : (
              <></>
            )
          )
        )}
      </div>
    </div>
  );
};

export default CustomTabs;
