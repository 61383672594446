import { HTTP_OK } from '../../constants/status-code-constant';
import axiosInstance from '../../utils/httpInterceptor';
import {
    documentUrl,
    getDocsAndCollectionsUrl,
    getWorkspaceUrl,
} from '../../constants/api-urls';
import { defaultExceptionHandler } from '../../utils/defaultExcpetionHandler';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the types for the state
interface SelectedDoc {
    id: number | null;
    name: string;
    data: string;
    collectionId: number | null;
    shared: boolean;
    publicUrl: string;
}

interface Document {
    id: number;
    name: string;
    data: string;
    collectionId: number | null;
    shared: boolean;
    publicUrl: string;
}

interface Collection {
    id: number;
    name: string;
    documents: Document[];
}

interface DocumentRepositoryState {
    isLoading: boolean;
    workspace: any; 
    documents: Document[];
    collections: Collection[];
    selected: SelectedDoc;
}

interface Params {
    workspaceId: number;
    name?: string | null;
}

const initialState: DocumentRepositoryState = {
    isLoading: false,
    workspace: null,
    documents: [],
    collections: [],
    selected: {
        id: null,
        name: '',
        data: '',
        collectionId: null,
        shared: false,
        publicUrl: '',
    },
};

export const getDocsAndCollections = createAsyncThunk(
    'document-repository/getDocsAndCollections',
    async (params: Params) => {
        try {
            const response = await axiosInstance.get(
                `${getDocsAndCollectionsUrl}/${params.workspaceId}/docs?title=${
                    params.name ?? ''
                }`
            );
            if (response?.status !== HTTP_OK) {
                throw new Error(response?.data?.data?.error);
            }
            return response?.data?.data;
        } catch (error) {
            throw defaultExceptionHandler(error);
        }
    }
);

export const documentRepositoryReducer = createSlice({
    name: 'document-repository',
    initialState,
    reducers: {
        resetDocs: (state) => initialState,
        resetCollection: (state) => initialState,

        setWorkspace: (state, action: PayloadAction<any>) => {
            state.workspace = action?.payload;
        },

        addDoc: (state, action: PayloadAction<Document>) => {
            return {
                ...state,
                documents: [action?.payload, ...state?.documents],
            };
        },
        addCollection: (state, action: PayloadAction<Collection>) => {
            return {
                ...state,
                collections: [action?.payload, ...state?.collections],
            };
        },

        addDocInCollection: (state, action: PayloadAction<Document>) => {
            state.collections = state?.collections.map((col) => {
                if (col.id === action?.payload?.collectionId) {
                    const updatedDocs = [action?.payload, ...col?.documents];
                    return { ...col, documents: updatedDocs };
                }
                return col;
            });
        },
        renameDoc: (state, action: PayloadAction<Document>) => {
            state.documents = state?.documents?.map((doc) => {
                if (doc.id === action?.payload?.id) return action?.payload;
                return doc;
            });
        },
        renameDocInCollection: (state, action: PayloadAction<Document>) => {
            state.collections = state?.collections?.map((col) => {
                if (col.id === action?.payload?.collectionId) {
                    const updateDocs = col.documents.map((doc) => {
                        if (doc.id === action?.payload?.id)
                            return action?.payload;
                        return doc;
                    });
                    return { ...col, documents: updateDocs };
                }
                return col;
            });
        },
        renameCollection: (state, action: PayloadAction<Collection>) => {
            return {
                ...state,
                collections: state.collections.map((col) => {
                    if (col.id === action?.payload?.id) return action?.payload;
                    return col;
                }),
            };
        },
        deleteCollection: (state, action: PayloadAction<{ id: number }>) => {
            state.collections = state.collections.filter(
                (col) => col.id !== action?.payload?.id
            );
        },
        deleteDocument: (state, action: PayloadAction<{ id: number }>) => {
            state.documents = state?.documents?.filter(
                (doc) => doc.id !== action?.payload?.id
            );
        },

        deleteDocInCollection: (state, action: PayloadAction<{ id: number; collectionId: number }>) => {
            state.collections = state.collections.map((col) => {
                if (col.id === action?.payload?.collectionId) {
                    const updatedDocs = col.documents.filter((doc) => {
                        if (doc.id !== action?.payload?.id) return doc;
                    });
                    return { ...col, documents: updatedDocs };
                }
                return col;
            });
        },

        updateDocs: (state, action: PayloadAction<Document[]>) => {
            return {
                ...state,
                documents: action.payload,
            };
        },
        updateContentOfDoc: (state, action: PayloadAction<{ selected: SelectedDoc; data: string }>) => {
            const idToUpdate = action?.payload?.selected?.id;
            const collectionId =
                action?.payload?.selected?.collectionId || null;
            if (collectionId) {
                state.collections = state.collections.map((col) => {
                    if (col.id === collectionId) {
                        const updatedDocs = col.documents.map((doc) => {
                            if (doc.id === idToUpdate)
                                return { ...doc, data: action?.payload?.data };
                            return doc;
                        });
                        return { ...col, documents: updatedDocs };
                    }
                    return col;
                });
            } else {
                state.documents = state.documents.map((doc) => {
                    if (doc.id === idToUpdate)
                        return { ...doc, data: action?.payload?.data };
                    return doc;
                });
            }
        },

        setDocSelected: (state, action: PayloadAction<SelectedDoc>) => {
            return {
                ...state,
                selected: action.payload,
            };
        },

        updateSelectedShareStatus: (state, action: PayloadAction<{ shared: boolean; publicUrl: string }>) => {
            state.selected = {
                ...state.selected,
                shared: action.payload.shared,
                publicUrl: action.payload.publicUrl,
            };
        },

        updateShareStatusForDocumentAndCollection: (state, action) => {
            const { id, shared, publicUrl, collectionId } = action.payload;
            if (collectionId) {
                state.collections = state.collections?.map((col) => {
                    if (col.id === collectionId) {
                        const updatedDocs = col.documents?.map((doc) => {
                            if (doc.id === id)
                                return { ...doc, shared, publicUrl };
                            return doc;
                        });
                        return { ...col, documents: updatedDocs };
                    }
                    return col;
                });
            } else {
                state.documents = state.documents?.map((doc) => {
                    if (doc.id === id) return { ...doc, shared, publicUrl };
                    return doc;
                });
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocsAndCollections.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDocsAndCollections.fulfilled, (state, action: PayloadAction<{ collections: Collection[]; documents: Document[] }>) => {
                state.isLoading = false;
                state.collections = action?.payload?.collections;
                state.documents = action?.payload?.documents;
            })
            .addCase(getDocsAndCollections.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const {
    resetDocs,
    resetCollection,
    setWorkspace,
    addDoc,
    addDocInCollection,
    addCollection,
    renameDoc,
    renameDocInCollection,
    renameCollection,
    deleteDocument,
    deleteCollection,
    deleteDocInCollection,
    updateDocs,
    updateContentOfDoc,
    setDocSelected,
    updateSelectedShareStatus,
    updateShareStatusForDocumentAndCollection,
} = documentRepositoryReducer.actions;
export default documentRepositoryReducer.reducer;
