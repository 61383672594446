import { themePalette } from "./colors";

import { themeTypography } from "./typography";
import { themeButtons } from "./button";
import { themeInputs } from "./inputs";
import { createTheme } from "@mui/material/styles";

const theme = createTheme(
  themeTypography,
  themeButtons,
  themeInputs,
  themePalette,
);

export default theme;
