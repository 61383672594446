import React from "react";
import classNames from "classnames";

interface IconTitleProps {
  icon: React.ReactNode;
  title: string;
  onClick?: any;
  className?: string;
}

const IconTitle: React.FC<IconTitleProps> = ({
  icon,
  title,
  onClick = () => null,
  className = "",
}) => {
  const baseClass = "flex items-center space-x-2";

  return (
    <div className={classNames(baseClass, className)} onClick={onClick}>
      {icon}
      <h2 className="w-max capitalize">{title}</h2>
    </div>
  );
};

export default IconTitle;
