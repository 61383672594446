import React from "react";
import IconTitle from "../IconTitle";
import { Link } from "react-router-dom";

export interface MenuItem {
  icon: React.ReactNode;
  label: string;
  to?: string;
  onClick?: (e?: any) => void;
}

interface MenuItemsListProps {
  items: MenuItem[];
  className?: string;
}

const MenuItemsList: React.FC<MenuItemsListProps> = ({ items, className }) => (
  <div className={className}>
    {items.map((item, index) =>
      item.to ? (
        <Link to={item.to} key={index}>
          <div
            className="p-2 hover:bg-gray-extralight cursor-pointer text-xs rounded-lg"
            onClick={item?.onClick}
          >
            <IconTitle icon={item.icon} title={item.label} />
          </div>
        </Link>
      ) : (
        <div
          key={index}
          className="p-2 hover:bg-gray-extralight cursor-pointer text-xs rounded-lg"
          onClick={item?.onClick}
        >
          <IconTitle icon={item.icon} title={item.label} />
        </div>
      )
    )}
  </div>
);

export default MenuItemsList;
