import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableRow from "@mui/material/TableRow";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import {
  StyledTableCellBody,
  StyledTableCellHead,
  StyledTableContainer,
  StyledTableHead,
  tableCellOverridesTop,
  tableCellOverridesBottom,
} from "./style";
import { useAppSelector } from "../../../store/hooks";
import { mecGrey } from "../../../constants/theme/colors";

const KraKpiTable = ({ tableData }: any) => {
  const isLoading = useAppSelector((state) => state.kraAndKpiState.isLoading);
  return (
    <>
      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"80vh"}
        >
          <CircularProgress color={"primary"} />
        </Box>
      ) : (
        <>
          {tableData.length ? (
            <StyledTableContainer>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCellHead style={{ width: "15%" }}>
                      <Typography variant="mec_bold_14" color={mecGrey[800]}>
                        Weightage
                      </Typography>
                    </StyledTableCellHead>
                    <StyledTableCellHead style={{ textAlign: "left" }}>
                      <Typography variant="mec_bold_14" color={mecGrey[800]}>
                        Description
                      </Typography>
                    </StyledTableCellHead>
                  </TableRow>
                </StyledTableHead>

                <TableBody>
                  {tableData.map((kra: any, kraIndex: number) => (
                    <React.Fragment key={kra.id}>
                      <TableRow
                        sx={{
                          borderTop:
                            kraIndex !== 0 ? `1px solid ${mecGrey[100]}` : "",
                          mt: 1,
                        }}
                      >
                        <StyledTableCellBody sx={{ textAlign: "center" }}>
                          <Typography lineHeight={"22px"} variant="mec_h3">
                            {kra?.weightage}
                          </Typography>
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          <Typography lineHeight={"22px"} variant="mec_h3">
                            {`KRA - ${kra?.name}`}
                          </Typography>
                        </StyledTableCellBody>
                      </TableRow>
                      {kra.questions.map((question: any) => (
                        <React.Fragment key={question.id}>
                          <TableRow>
                            <StyledTableCellBody
                              sx={{
                                textAlign: "center",
                                ...tableCellOverridesBottom,
                              }}
                            >
                              <Typography
                                variant="mec_body1"
                                lineHeight={"24px"}
                                color={mecGrey[800]}
                              >
                                {question.weightage}
                              </Typography>
                            </StyledTableCellBody>
                            <StyledTableCellBody
                              sx={{
                                alignItems: "center",
                                ...tableCellOverridesBottom,
                              }}
                            >
                              <Typography
                                variant="mec_body1"
                                lineHeight={"24px"}
                                color={mecGrey[700]}
                                sx={{
                                  textDecoration: "underline",
                                }}
                              >
                                {`KPI - ${question.question}`}
                              </Typography>
                            </StyledTableCellBody>
                          </TableRow>
                          <TableRow>
                            <StyledTableCellBody
                              sx={{
                                textAlign: "center",
                                ...tableCellOverridesTop,
                              }}
                            ></StyledTableCellBody>
                            <StyledTableCellBody
                              sx={{ ...tableCellOverridesTop }}
                            >
                              <Stack>
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    fontFamily: "Inter",
                                    color: mecGrey[700],
                                    whiteSpace: question.description.startsWith(
                                      "<ul>",
                                      0
                                    )
                                      ? "unset"
                                      : "pre-wrap",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: question.description,
                                  }}
                                />
                              </Stack>
                            </StyledTableCellBody>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"} mt={5}>
              <Typography variant="mec_semiBold_16">
                No KRAs & KPIs for selected Designation
              </Typography>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default KraKpiTable;
