import data from "./../../package.json";

export function displayVersion() {
  console.log(
    `%cProject-Name%c : ${data.name}`,
    `
    background: #039BE5;
    color: #FFF;
    font-weight: bold;
    padding: 5px 0px 5px 20px;
    font-size: 14px;
  `,
    `
  text-transform: uppercase;
  background: #039BE5;
  color: #FFF;
  font-weight: light;
  padding: 5px 20px 5px 0px;
  font-size: 14px;
  `
  );

  console.log(
    `%cVersion : %c${process.env.REACT_APP_VERSION}`,
    `background: #039BE5;
    color: #FFF;
    padding: 2px 0px 2px 20px;
    font-size: 14px;
    `,
    `background: #039BE5;
    color: #FFF;
    padding: 2px 20px 2px 0px;
    text-decoration: underline;
    font-size: 14px;
    `
  );

  console.log(
    `%cEnvironment : %c${process.env.REACT_APP_ENV}`,
    `background: #039BE5;
    color: #FFF;
    padding: 2px 0px 2px 20px;
    font-size: 14px;
    `,
    `background: #039BE5;
    color: #FFF;
    padding: 2px 20px 2px 0px;
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
    `
  );
}
