import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FilterInterface {
  payload?: any;
}

export interface ResourceFilterCountModel {
  shadow: boolean;
  bench: boolean;
  assigned: boolean;
}

interface ResourceItem {
  value: string;
  label: string;
}

export interface FilterStatus {
  count: number;
  status: boolean;
}

export interface ResourceFilterModel {
  project: number[] | null;
  technology: number[] | null;
  vendors: boolean;
  minExp: number;
  maxExp: number;
  mentorId: number[] | null;
  month: number | null;
  operatorType: string | null;
  name: string | null;
}

interface dataType {
  project: string | null;
  technology: string[] | null;
  vendors: boolean;
  minExp: number;
  maxExp: number;
  mentorId: string | null;
  month: number;
  operatorType: string;
  name:string;
}

export interface FilterState {
  resourceFilterCountModel: ResourceFilterCountModel;
  resourceFilterModel: ResourceFilterModel;
  filterStatus: FilterStatus;
}

const initialState: FilterState = {
  resourceFilterCountModel: {
    shadow: false,
    bench: false,
    assigned: false,
  },
  resourceFilterModel: {
    project: null,
    technology: null,
    vendors: false,
    minExp: 0,
    maxExp: 0,
    mentorId: null,
    month: null,
    operatorType: "OR",
    name:null
  },
  filterStatus: {
    count: 0,
    status: false,
  },
};

const filterReducer = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setResourceFilterCountModel: (
      state,
      action: PayloadAction<ResourceFilterCountModel>
    ) => {
      state.resourceFilterCountModel = action.payload;
    },
    setResourceFilterModel: (
      state,
      action: PayloadAction<ResourceFilterModel>
    ) => {
      state.resourceFilterModel = action.payload;
    },
    clearFilters: (state) => {
      state.resourceFilterCountModel = initialState.resourceFilterCountModel;
      state.resourceFilterModel = initialState.resourceFilterModel;
    },
    clearFilterModelData: (state) => {
      state.resourceFilterModel = initialState.resourceFilterModel;
    },
    setFilterStatus: (state, action: PayloadAction<FilterStatus>) => {
      state.filterStatus = action.payload;
    },
  },
});

export const {
  setResourceFilterCountModel,
  setResourceFilterModel,
  clearFilters,
  clearFilterModelData,
  setFilterStatus,
} = filterReducer.actions;
export default filterReducer.reducer;
