export const RouteConstants = {
  ROUTE_HOME: '/home' as const,
  ROUTE_INBOX: '/inbox' as const,
  ROUTE_INBOX_PROJECT: '/inbox/project' as const,
  ROUTE_INBOX_MONTHLY: '/inbox/monthly' as const,
  ROUTE_INBOX_PROJECT_ID: '/inbox/project/:id' as const,
  ROUTE_INBOX_MONTHLY_ID: '/inbox/monthly/:id' as const,
  ROUTE_INBOX_WFH: '/inbox/wfh' as const,
  ROUTE_INBOX_WFH_ID: '/inbox/wfh/:id' as const,
  ROUTE_MONTHLY_FEEDBACK: '/feedback/monthly/:id' as const,
  ROUTE_PROJECT_FEEDBACK: '/feedback/project/:id' as const,
  ROUTE_MONTHLY_VIEW_FEEDBACK: '/feedback/monthly/view/:id' as const,
  ROUTE_PROJECT_VIEW_FEEDBACK: '/feedback/project/view/:id' as const,
  ROUTE_WFH_REQUEST: '/wfh-request' as const,
  ROUTE_WFH_MY_REQUEST: '/wfh-request/my-request' as const,
  ROUTE_WFH_MY_REQUEST_ID: '/wfh-request/my-request/:id' as const,
  ROUTE_WFH_NEW_REQUEST: '/wfh-request/new-request' as const,
  ROUTE_WFH_SUMMARY: '/wfh-request/summary' as const,
  ROUTE_DOCUMENTS_REPO: '/docs' as const,
  ROUTE_DOCUMENTS_REPO_WITH_PROJECT_ID: '/docs/:projectId' as const,
  ROUTE_DASHBOARD: '/dashboard' as const,
  ROUTE_GOALS_SHEET: '/dashboard/goal-sheet' as const,
  ROUTE_PROJECT: '/project' as const,
  ROUTE_PROJECT_CARD: '/project/card' as const,
  ROUTE_PROJECT_LIST: '/project/list' as const,
  ROUTE_PROJECT_DETAILS: '/project/:id' as const,
  ROUTE_PROJECT_WORKLOGS: '/project/:id/details/worklogs/:projectType' as const,
  ROUTE_PROJECT_DETAILS_OVERVIEW: '/project/:id/details' as const,
  ROUTE_KANBAN_PROJECT_DETAILS: '/project/:id/kanban' as const,
  // ROUTE_ADD_KANBAN_TASK: "/project/:id/details/create-task" as const,
  ROUTE_PROJECT_DETAILS_DASHBOARD: '/project/:id/dashboard' as const,
  ROUTE_PROJECT_DETAILS_TEAM_MEMBERS: '/project/:id/team-members' as const,
  ROUTE_PROJECT_DETAILS_RESOURCE_TIME_SHEET: '/project/:id/resource-time-sheet' as const,
  ROUTE_PROJECT_DETAILS_COST_SETTINGS: '/project/:id/cost-settings' as const,
  ROUTE_PROJECT_SPRINT_RETROSPECT: '/project/:id/sprint/:sprintId/retrospect' as const,
  ROUTE_PROJECT_SPRINT_DETAILS: '/project/:id/sprint/:sprintId' as const,
  ROUTE_PLATFORM_USERS: '/platform-users' as const,
  ROUTE_TIMESHEET: '/timesheet' as const,
  ROUTE_LOGIN: '/login' as const,
  ROUTE_SIGNUP: '/signup' as const,
  ROUTE_ERROR_404: '/error404' as const,
  ROUTE_RESOURCES: '/resources' as const,
  ROUTE_RESOURCE_ONBOARD: '/resources/onboard',
  ROUTE_RESOURCE_REGISTRATION: '/resource/register' as const,
  ROUTE_RESOURCE_REGISTRATION_SUCCESS: '/resource/register/success' as const,
  ROUTE_KRA_KPI_RATINGS: '/kra-kpi/ratings' as const,
  ROUTE_PROFIT_LOSS: '/profit-loss' as const,
  ROUTE_PROFIT_LOSS_DETAIL: '/profit-loss/:id' as const,
  ROUTE_KRA_KPI: '/kra-kpi' as const,
  ROUTE_KRA_KPI_CREATE_EDIT: '/kra-kpi/:designation' as const,
  ROUTE_RESOURCES_BPC_NOMINATIONS: '/resource/bpc-nomination' as const,
  ROUTE_FORMS: '/forms' as const,
  ROUTE_ADD_FORM: '/forms/add' as const,
  ROUTE_VIEW_FORM: '/forms/view/:id' as const,
  ROUTE_EDIT_FORM: '/forms/edit/:id' as const,
  ROUTE_PROFILE_DETAILS: '/profile/details/:resourceId' as const,
  ROUTE_RESOURCE_DETAILS: '/resources/details/:resourceId' as const,
  ROUTE_PROJECT_COST_SETTING: '/project/cost-setting',
  // ROUTE_RESOURCE_EDIT: "/resources/edit/:objectId",
  ROUTE_ONBOARD_RESOURCE_EDIT: '/resources/edit/:objectId',
  ROUTE_PROFILE_EDIT: '/profile/details/:resourceId/edit-profile' as const,
  ROUTE_RESOURCE_EDIT: '/resources/details/:resourceId/edit-resource' as const,
  ROUTE_RESOURCE_INVITE: '/resources/invite',
  ROUTE_VIEW_WORKLOG_ON_SHARE: '/worklog/project/:key' as const,
  ROUTE_ADD_JIRA_PROJECT: '/project/jira',
  ROUTE_ADD_NON_JIRA_PROJECT: '/project/non-jira',
  ROUTE_KRA_KPI_RATINGS_DETAILS: '/kra-kpi/ratings/details/:id/:q' as const,
  ROUTE_ADD_KANBAN_PROJECT: '/project/kanban',
  ROUTE_EDIT_PROJECT: '/project/edit/:projectId',
  ROUTE_PROJECT_SETTING: '/project/:id/setting',
  ROUTE_SETTING_PROJECT_REMINDER: 'project-reminder',
  ROUTE_SETTING_LEAVE_HOLIDAY: 'leave-holiday',
  ROUTE_SETTING_BOARD_SETTING: 'board-setting',
  ROUTE_GOALS_DETAILS: '/profile/details/goals/:id/:q' as const,
  ROUTE_RESOURCE_GOALS_DETAILS: '/resources/details/goals/:id/:q' as const,
  ROUTE_PROJECT_ADD_CR: '/project/:id/add-cr' as const,

  ROUTE_PROFIT_LOSS_ALL_PROJECTS: '/profit-loss/all' as const,
  ROUTE_PROFIT_LOSS_FILTERS: '/profit-loss/filters' as const,

  // ROUTE_RESOURCE_EDIT: "/resources/edit/:objectId",
  ROUTE_WORKLOG_PUBLIC_PAGE:
    '/work-logs/eyJhbGciOiJIUzI1NiJ9.eyJwcm9qZWN0TmFtZSI6IlRyaXB0ICIsInJlc291cmNlTmFtZSI6IlNocmluYXRoIEhpbWFuZSIsInByb2plY3RJZCI6MTk5NDUxLCJyZXNvdXJjZUlkIjoxNjAzMTAsIndvcmtMb2dEYXRlIjoxNzE0NTcwMjQ5OTY5LCJzdWIiOiJTaHJpbmF0aC5IaW1hbmVAbWluZGJvd3Nlci5jb20tVEgtMTcxNDU3MDI0OTk2OSIsImlhdCI6MTcxNDU3MDI0OX0.1HGtg4HV_TX0b-xiTuHeoclwS6-Lv1kmbjPQlqzDzHU' as const,
  ROUTE_GOALS: '/goals' as const,
  ROUTE_MY_GOALS: '/goals/:id' as const,
  ROUTE_ADD_GOALS: '/goals/:id/:year/:q' as const,
  ROUTE_EDIT_GOALS: '/goals/:id/:year/:q/edit' as const,
  ROUTE_WORKLOG_PUBLIC_PAGE2: '/work-logs/:ac' as const,
  ROUTE_ADD_REPOSITORY: '/project/repository/add' as const,
  ROUTE_VIEW_REPOSITORIES: '/project/repository/list/:id' as const,
  ROUTE_PROJECT_VIEW_WORKLOGS: '/project/:id/details/worklogs/:projectType' as const,
  ROUTE_MENTEE: '/mentees' as const,
  ROUTE_MENTEE_WITH_ID: '/mentees/:resourceId' as const,
  ROUTE_REGISTER_RESOURCE_AS_CONSULTANT: 'resource/consultant/register',
} as const;
