import { RouteConstants } from '../../constants/route-constant';
import {
  HomeOutlined,
  EmailOutlined,
  ArticleOutlined,
  BarChartOutlined,
  FolderOutlined,
} from '@mui/icons-material';

interface ProjectListItem {
  label: string;
  icon: JSX.Element;
  to: string;
}

export const menuItems: { label: string; icon: JSX.Element; to: string }[] = [
  { label: 'home', icon: <HomeOutlined fontSize="small" />, to: RouteConstants.ROUTE_HOME },
  {
    label: 'my project',
    icon: <FolderOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_PROJECT,
  },
  { label: 'inbox', icon: <EmailOutlined fontSize="small" />, to: RouteConstants.ROUTE_INBOX },
  {
    label: 'docs',
    icon: <ArticleOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_DOCUMENTS_REPO,
  },
  {
    label: 'dashboard',
    icon: <BarChartOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_DASHBOARD,
  },
];

export const projectListItems: ProjectListItem[] = [
  {
    label: 'project 1',
    icon: <FolderOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_HOME,
  },
  {
    label: 'project 2',
    icon: <FolderOutlined fontSize="small" />,
    to: RouteConstants.ROUTE_HOME,
  },
];
