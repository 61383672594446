import React, { useState, useEffect, useCallback } from 'react';
import { TextField, CircularProgress, IconButton, Typography, Box, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import { debounce } from 'lodash';
import Layout from '../../components/Layout';
import { authUpdateAndReload } from '../../services/authService';
import { getProfitAndLossFilters, getProjectList } from '../../services/profitLossService';
import TableComponent, { Column, Data } from '../../components/TableComponent';
import ActionBar from '../../components/ActionBar';
import InputField from '../../components/InputField';
import TableSkeleton from '../../components/TableSkeleton';
import Badge from '../../components/Badge';
import { TextBoxSuccess, TextBoxWarning } from '../../common/styles/TextBox';
import moment from 'moment';
import CustomTabs from '../../components/CustomTabs';
import { Add, CheckBox } from '@mui/icons-material';
import CustomDialog from '../../components/Dialog';
import FilterProfitLossProjectDialog from '../../components/Dialog/FilterProfitLossProjectDialog';
import { closeDialog, openDialog } from '../../store/reducers/dialogReducer';
import { useDispatch } from 'react-redux';
import svg from '../../assets';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { optionsType } from '../../constants/app-constant';
import { FilterComponent } from './ProfitLossByFilter/FilterProjectsComponent';
import { RouteConstants } from '../../constants/route-constant';
import { useAppDispatch } from '../../store/hooks';
import {
  clearSearchValueState,
  setSearchValues,
} from '../../store/reducers/profitLossFilterReducer';
import Breadcrumbs from '../../components/Breadcrumbs';
import CustomBreadCrumb from '../../components/CustomBreadCrumbs';
import { STATUS } from '../../types/project/enums';

interface Project {
  id: number;
  name: string;
  projectType: string;
  startDate: string;
  endDate: string;
  status: string;
}

const ProfitLossProjectsList: React.FC = () => {
  const searchControl = useForm({ defaultValues: { search: '' } });
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const debouncedDispatch = useCallback(
    debounce((searchKey: any) => {
      dispatch(setSearchValues(searchKey));
    }, 500),
    [dispatch],
  );
  useEffect(() => {
    const subscription = searchControl.watch((value: any) => {
      const searchKey = value.search?.trim()?.toLowerCase();
      debouncedDispatch(searchKey);
    });
    return () => {
      dispatch(clearSearchValueState());
    };
  }, [searchControl, debouncedDispatch]);

  const clearSearch = () => {
    searchControl.setValue('search', '', { shouldValidate: false });
    dispatch(clearSearchValueState());
  };

  const handleCloseFilterDialog = () => {
    setOpenFilterDialog(false);
    dispatch(closeDialog('filterProjectsDialog'));
  };

  const handleFilterDialog = () => {
    setOpenFilterDialog(true);
    dispatch(openDialog('filterProjectsDialog'));
  };

  const tabs = [
    {
      label: 'All Projects',
      tabIcon: <CheckCircleOutlineIcon sx={{ height: '20px', width: '20px' }} />,
      to: RouteConstants.ROUTE_PROFIT_LOSS_ALL_PROJECTS,
      isTabActive: location.pathname === RouteConstants.ROUTE_PROFIT_LOSS_ALL_PROJECTS,
    },
    {
      label: 'By Filter',
      tabIcon: <FilterAltOutlinedIcon sx={{ height: '20px', width: '20px' }} />,
      to: RouteConstants.ROUTE_PROFIT_LOSS_FILTERS,
      isTabActive: location.pathname === RouteConstants.ROUTE_PROFIT_LOSS_FILTERS,
    },
  ];

  const customCrumb = location.pathname.includes('filter')
    ? [
        { label: 'Profit-Loss', path: RouteConstants.ROUTE_PROFIT_LOSS },
        { label: 'By-Filter', path: RouteConstants.ROUTE_PROFIT_LOSS_FILTERS },
      ]
    : [
        { label: 'Profit-Loss', path: RouteConstants.ROUTE_PROFIT_LOSS },
        { label: 'All-Projects', path: RouteConstants.ROUTE_PROFIT_LOSS_ALL_PROJECTS },
      ];
  return (
    <Layout>
      <ActionBar className="flex justify-between items-center">
        <CustomBreadCrumb customCrumb={customCrumb} />
        <Box sx={{ overflow: 'hidden' }}>
          <Box
            className={`flex flex-row gap-2 ${
              location.pathname === RouteConstants.ROUTE_PROFIT_LOSS_ALL_PROJECTS ? 'mb-[5px]' : ''
            }`}
          >
            {location.pathname === RouteConstants.ROUTE_PROFIT_LOSS_ALL_PROJECTS && (
              <Controller
                name="search"
                control={searchControl.control}
                render={({ field }) => (
                  <InputField
                    {...field}
                    variant="outlined"
                    fullWidth
                    placeholder="Search by project name"
                    onChange={(e: any) => {
                      field.onChange(e);
                    }}
                    startAdornment={<SearchIcon />}
                    endAdornment={
                      field.value && (
                        <IconButton onClick={clearSearch}>
                          <CloseIcon />
                        </IconButton>
                      )
                    }
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: '32px',
                      },
                    }}
                  />
                )}
              />
            )}

            {location.pathname === RouteConstants.ROUTE_PROFIT_LOSS_FILTERS && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={handleFilterDialog}
                >
                  Create Filter
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </ActionBar>
      <Box className="flex justify-center m-2">
        <CustomTabs tabs={tabs} tabContainerClassName="w-full" hasOutlet />
      </Box>
      {location.pathname === RouteConstants.ROUTE_PROFIT_LOSS_FILTERS && (
        <FilterProfitLossProjectDialog open={openFilterDialog} onClose={handleCloseFilterDialog} />
      )}
    </Layout>
  );
};

export default ProfitLossProjectsList;

export const formatStatus = (data: any) => {
  if (data === STATUS.UPCOMING || data === STATUS.PUBLIC) {
    return (
      <Badge
        className="bg-warning-100 text-warning-500 w-20 h-9 py-6- font-semibold text-xs rounded-md"
        title={data === STATUS.UPCOMING ? 'Upcoming' : 'Public'}
      />
    );
  } else if (data === STATUS.ACTIVE || data === STATUS.PRIVATE) {
    return (
      <Badge
        className="bg-green-100 text-green-500 w-20 h-9 py-6- font-semibold text-xs rounded-md"
        title={data === STATUS.ACTIVE ? 'Active' : 'Private'}
      />
    );
  } else if (data === STATUS.COMPLETED) {
    return (
      <Badge
        className="bg-blue-light text-blue-main w-20 h-9 py-6- font-semibold text-xs rounded-md"
        title={'Completed'}
      />
    );
  } else {
    return <Typography>-</Typography>;
  }
};
