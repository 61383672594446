import styled from "@emotion/styled";
import { mecPrimary } from "../../constants/theme/colors";
import { Avatar } from "@mui/material";

export const StyledAvatar = styled(Avatar)({
  '&.MuiAvatar-root': {
    height: '34px',
    width: '34px',
    borderRadius: '36px',
    border: `2px solid ${mecPrimary[200]}`,
    backgroundColor: mecPrimary[100],
    color: mecPrimary[500],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
});
