export const defaultMessage: string = 'Something went wrong!';
export const noMessage: string = 'No results found!';
export const noWorklogs: string = 'Work logs not found';
export const loading: string = 'Loading....';
export const formSubmitted: string = 'Hurry! you have submitted the form';
export const formIgnored: string = 'You have ignored the form';
export const isRequired = (label = 'Value') => `${label} is required`;
export const savedSuccess = (label = 'Data') => `${label} saved successfully`;
export const minMaxCharacterMessage = (label = 'Data', min = '10', max = '1000') =>
  `${label} should be between ${min} to ${max} characters`;
export const nomineeFailed: string = 'Failed to save nominee. Please try again.';
export const getUserRoleFailed: string = 'Failed to get user role:';
export const reasonRequired: string = 'Reason is required';
export const awardRequired: string = 'Award is required';
export const nomineeRequired: string = 'Nominee is required';
export const nomineeSuccessfullySaved: string = 'Your nomination has been submitted successfully';
export const userEditAllow: string = 'Status changed successfully';
export const urlKeyMessage: string = 'Minimum 4 characters required';

export const urlCopied: string = 'Text copied to clipboard!';
export const requiredMessage = (field: string) => `${field} is required`;
export const mandatoryMessage = (field: string) => `${field} is mandatory`;
export const rangeMessage = (label = 'Data', min = 0, max = 1000) =>
  `${label} should be between ${min} and ${max}`;
export const mindbowserEmailMessage = `Only mindbowser email is allowed`;
export const invalidEmailMessage = `Please enter valid email`;
export const pleaseContactAdmin: string = 'Something went wrong! Please contact your admin.';
export const mindbowserIdAllowed: string =
  'Only Gmail IDs from Mindbowser.com are allowed for login.';
export const worklogDeleted: string = 'Worklog deleted successfully';

export const worklogUpdated: string = 'Worklog Updated successfully';
export const costSettingSaved: string = 'Cost settings fetched successfully';
export const technologyExistsMessage: string = 'Technology already exists in the estimate';
export const technologyMustMatch: string = 'Technologies in both estimates must match';
export const costSuccess: string = 'Cost settings posted successfully';
export const costIsEmpty: string = 'Cost settings is empty';
export const errorTable: string = 'Error fetching table data.';

export const createDoc: string = 'New Document Created!';
export const createDocError: string = 'Error occured while creating Document';
export const collectionRenamed: string = 'Successfully Renamed Collection!';
export const collectionRenameRError: string = 'Error Occured While Renaming Collection!';
export const collectionDeleted: string = 'Collected Deleted Successfully!';
export const collectionDeletedError: string = 'Error Occured while Deleting Collection';

export const documentRenamed: string = 'Document Renamed Successfully!';
export const documentRenamedError: string = 'Error Occured while Renaming Document';
export const documentDeleted: string = 'Document Deleted Successfully';
export const documentDeletedError: string = 'Error Occured while Deleting Document';
export const createCollection: string = 'New Collection Successfully Created!';
export const createCollectionError: string = 'Error Occured while creating Collection';
export const unauthorisedMessage: string =
  'You do not have permission to access the requested page';
export const accessDeniedMessage: string = '403 Access Denied';

export const addMust: string = 'Please enter all required fileds before adding';

export const deleteWarningMessage: string =
  'Warning : Deleting this project will permanently erase all associated data. Proceed with caution.';
export const worklogError: string = 'An error occurred while adding worklog';

export const descriptionError: string = 'Please add task description';
export const estiamtionError: string = 'Please enter valid estimation';
export const taskNameError: string = 'Please add task title';
export const noLogsAdded: string =
  'You have already logged work for this date; changing the date is not allowed.';
export const failedSheetDownload: string = 'Failed to download the sheet. Please try again';

export const wfhRequestFromMessage: string = 'You just received a WFH request from';
export const wfhActionRequestMessage: string =
  'Please complete the necessary steps using the action buttons below to either accept or decline the request:';
export const wfhActionThankMessage: string =
  'You have already performed required action. Thank you, we appreciate your time and effort.';
export const adminRemindFailedMessage: string = 'Failed to send admin reminder.';
export const costToComapnyCannotMessage: string = 'Cost To Company cannot be greater than the Rate';

export const goalAccepted: string =
  ' Your goals have been approved by your mentor, you can start working on your goals';
export const goalWeightage: string = 'Total Weightage should be equal to 100';
export const menteeEnable: string =
  'Your are enabling your mentee to submit their goal, despite the original completion date having passed. The deadline will be extended by 10 days.';

export const submissionPenalty: string =
  'Please note, a penalty of 10% will be applied for each day the submission is late. For example, If the original submission date was March 25th and the mentee submits on March 29th, which is 4 days late, a penalty of 40% (4*10%) will be applied.';
// constants.ts
export const penaltyText1 =
  'This penalty will be applicable ONLY when the mentee has fully completed the goal assigned to them.';
export const penaltyText2 =
  'If the mentee does not submit the goal within these extended 10 days, it will be automatically marked as incomplete, and this action cannot be reversed.';
export const communicationText = 'Please communicate this clearly to your mentee.';

export const fileToolarge = 'File is too large. Please upload a file smaller than 50MB';
export const invalidFileType = 'Invalid file type. Only DOCX and PDF files are allowed.';
export const MAX_FILE_SIZE = 50 * 1024 * 1024;

export const PROJECT_NOT_FOUND = 'Projects not found';

export const FIRST_NAME_REQUIRED = 'First name is required';
export const LAST_NAME_REQUIRED = 'Last name is required';
export const EMAIL_REQUIRED = 'Email is required';
export const DATE_JOINING__REQUIRED = 'Date of joining required';
export const ADD_TECHNOLOGY_TITLE = 'Add technology';
export const INPUT_LABEL_TECH = 'Enter technology';
export const ERROR_LABEL_TECH = 'Technology Name is Mandatory';
export const ADD_SKILL_TITLE = 'Add skill/integrations';
export const INPUT_LABEL_SKILL = 'Enter skill/integrations';
export const ERROR_LABEL_SKILL = 'Skill/integration Name is Mandatory';
export const FAIL_TO_FETCH_DATA = 'Failed to fetch data';

