import axios from "axios";
import {
  saveProjectFeedbackUrl,
  getViewFeedbackFormUrl,
  applyWfhUrl,
  getAllGlobalWfhListUrl,
  changeWfhRequestStatusUrl,
  getWfhBalanceUrl,
  sendProjectFeedbackReminderUrl,
  getUserDetailsUrl,
  EMPLOYEE_LOGIN_V2,
} from "../constants/api-urls";
import { defaultExceptionHandler } from "../utils/defaultExcpetionHandler";
import axiosInstance from "../utils/httpInterceptor";
import store from "../store/store";
import { setTokens, setUser } from "../store/reducers/authReducer";

export const saveProjectFeedback = async (params: any) => {
  const response = await axiosInstance
    .post(
      `${saveProjectFeedbackUrl}?&month=${params.month}&year=${params.year}&inboxUuid=${params.inboxUuid}`,
      params.payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });
  return response;
};

export const sendProjectFeedbackReminder = async (params: any) => {
  const response = await axiosInstance
    .post(`${sendProjectFeedbackReminderUrl}`, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });
  return response;
};

export const getViewFeedbackForm = async (params: any) => {
  const response = await axiosInstance
    .get(`${getViewFeedbackFormUrl}?&inboxUuid=${params.inboxUuid}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });
  return response;
};

export const handleApplyWfhRequest = async (payload: any) => {
  const response = await axiosInstance
    .post(`${applyWfhUrl}`, payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });
  return response;
};

export const getAllGlobalWfhList = async (params: any) => {
  const response = await axiosInstance
    .post(getAllGlobalWfhListUrl, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const changeWfhRequestStatus = async (params: any) => {
  const response = await axiosInstance
    .post(changeWfhRequestStatusUrl, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getWfhBalance = async (params: any) => {
  const response = await axiosInstance.get(getWfhBalanceUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const getUserDetails = async () => {
  const response = await axiosInstance.get(getUserDetailsUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const employeeLoginV2 = async (payload: { code: string }) => {
  const response = await axios
    .post(EMPLOYEE_LOGIN_V2, payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });
  const { token, user } = response.data.data;
  const { accessToken, refreshToken } = token;
  store.dispatch(setTokens({ accessToken, refreshToken }));
  store.dispatch(setUser(user));
  return response;
};
