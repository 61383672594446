import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";

interface Breadcrumb {
  label: string;
  path: string;
}

interface BreadcrumbsProps {
  customCrumb?: Breadcrumb;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ customCrumb }) => {
  const location = useLocation();

  const pathnames = location.pathname.split("/").filter((x) => x);

  const paramsToExclude = Object.values(useParams());

  const breadcrumbs = pathnames
    .filter((value) => !paramsToExclude.includes(value))
    .map((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join("/")}`;
      return { label: value, path: to };
    });

  if (customCrumb) {
    breadcrumbs.push(customCrumb);
  }

  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => {
        const isLast = index === breadcrumbs.length - 1;
        return isLast ? (
          <p
            key={breadcrumb.path}
            className="text-gray-extradark capitalize text-sm font-medium"
          >
            {breadcrumb.label}
          </p>
        ) : (
          <Link
            to={breadcrumb.path}
            key={breadcrumb.path}
            className="text-gray capitalize text-sm font-medium"
          >
            {breadcrumb.label}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
