import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material';
import { CalendarMonthOutlined } from '@mui/icons-material';
import { Technology } from '../components/CreateRepository/Common';
import svg from '../assets';
import { constants } from '../components/Dashboard/constants';
import { QuickActionMenuItemProps } from '../components/QuickActionMenu';
type InboxStatus = {
  value: string;
  label: string;
};
export const INBOX_STATUS_ENUM = {
  ALL: 'ALL',
  TODO: 'TODO',
  SUBMITTED: 'SUBMITTED',
  IGNORED: 'IGNORED',
  CANCELLED: 'CANCEL',
};
export const INBOX_STATUS: InboxStatus[] = [
  {
    value: INBOX_STATUS_ENUM.ALL,
    label: 'All',
  },
  {
    value: INBOX_STATUS_ENUM.TODO,
    label: 'To-do',
  },
  {
    value: INBOX_STATUS_ENUM.SUBMITTED,
    label: 'Submitted',
  },
  {
    value: INBOX_STATUS_ENUM.IGNORED,
    label: 'Ignored',
  },
];

export const WFH_INBOX_STATUS: InboxStatus[] = [
  {
    value: INBOX_STATUS_ENUM.ALL,
    label: 'All',
  },
  {
    value: INBOX_STATUS_ENUM.TODO,
    label: 'To-do',
  },
  {
    value: INBOX_STATUS_ENUM.SUBMITTED,
    label: 'Submitted',
  },
  {
    value: INBOX_STATUS_ENUM.CANCELLED,
    label: 'Cancelled',
  },
];

export const INBOX_TYPE = {
  MONTHLY: 'ONE_TO_ONE',
  PROJECT: 'PROJECT',
};
export const INBOX_TYPE_VALUE = {
  MONTHLY: 'ONE_TO_ONE',
  PROJECT: 'PROJECT_FEEDBACK',
};
export const severity = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};
export const USER_ROLE_LIST = [
  { value: 'ADMIN', label: 'ADMIN' },
  { value: 'PM', label: 'PM' },
  { value: 'USER', label: 'USER' },
  { value: 'SALES', label: 'SALES' },
  { value: 'HR', label: 'HR' },
  { value: 'VENDOR', label: 'VENDOR' },
];

export const TASK_STATUS = [
  { value: 'TODO', label: 'TODO' },
  { value: 'BACKLOG', label: 'BACKLOG' },
  { value: 'INREVIEW', label: 'IN REVIEW' },
  { value: 'DONE', label: 'DONE' },
  { value: 'BLOCKED', label: 'BLOCKED' },
];

export const TaskPriority = [
  {
    value: 'LOWEST',
    label: 'LOWEST',
    icon: ArrowDownwardOutlined,
    color: '#28C76F',
  },
  { value: 'LOW', label: 'LOW', icon: ArrowDownwardOutlined, color: '#28C76F' },
  {
    value: 'MEDIUM',
    label: 'MEDIUM',
    icon: ArrowUpwardOutlined,
    color: '#FF9F43',
  },
  { value: 'HIGH', label: 'HIGH', icon: ArrowUpwardOutlined, color: '#EA5455' },
  {
    value: 'HIGHEST',
    label: 'HIGHEST',
    icon: ArrowUpwardOutlined,
    color: '#EA5455',
  },
];
export const years = [
  { label: '2023-2024', value: 2023 },
  { label: '2024-2025', value: 2024 },
  { label: '2025-2026', value: 2025 },
  { label: '2026-2027', value: 2026 },
  { label: '2027-2028', value: 2027 },
  { label: '2028-2029', value: 2028 },
  { label: '2029-2030', value: 2029 },
  { label: '2030-2031', value: 2030 },
  { label: '2031-2032', value: 2031 },
  { label: '2032-2033', value: 2032 },
  { label: '2033-2034', value: 2033 },
  { label: '2034-2035', value: 2034 },
];

export const months = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const CREATE_REPO_STEPS = [
  {
    label: 'Choose Technology',
  },
  {
    label: 'Create Repository',
  },
  {
    label: 'Review Repository',
  },
];

export const BRANCH_OPTIONS = [
  { label: 'development', value: 'development' },
  { label: 'uat', value: 'uat' },
  { label: 'master', value: 'master' },
  { label: 'staging', value: 'staging' },
];
export const REVIEW_REPOSITORY_TEMPLATE = [
  { label: 'Bitbucket Project Name', type: 'text' },
  { label: 'Project Name', type: 'text' },
  { label: 'Technology', type: 'text' },
  { label: 'Portal Name', type: 'chip' },
  { label: 'Repository Name', type: 'chip' },
  { label: 'Branch Or Pattern', type: 'chip' },
  { label: 'Developer', type: 'chip' },
  { label: 'Code Reviewer & PM', type: 'chip' },
];

export const TECHNOLOGY: Record<string, Technology> = {
  react: {
    main: {
      tooltip: 'React',
      icon: svg.reactIcon,
      label: 'REACT',
      bgcolor: 'mecPrimary-100',
    },
    subTechnologies: [
      {
        tooltip: 'Java Script',
        icon: svg.javascriptIcon,
        label: 'JAVA SCRIPT',
        key: 'react-js',
      },
      {
        tooltip: 'Type Script',
        icon: svg.typescriptIcon,
        label: 'TYPE SCRIPT',
        key: 'react-ts',
      },
    ],
  },
  java: {
    main: {
      tooltip: 'Java',
      icon: svg.javaIcon,
      label: 'JAVA',
      bgcolor: 'mecPrimary-100',
    },
    subTechnologies: [
      {
        tooltip: 'Spring REST',
        icon: svg.springRestIcon,
        label: 'SPRING REST',
        key: 'java-rest',
      },
      {
        tooltip: 'Spring JWT',
        icon: svg.springJwtIcon,
        label: 'SPRING JWT',
        key: 'java-jwt',
      },
    ],
  },
  node: {
    main: {
      tooltip: 'Node',
      icon: svg.nodeJsIcon,
      label: 'NODE',
      bgcolor: 'mecPrimary-100',
    },
    subTechnologies: [
      {
        tooltip: 'GraphQL',
        icon: svg.graphQLIcon,
        label: 'GRAPHQL',
        key: 'node-graph-ql',
      },
      {
        tooltip: 'Express',
        icon: svg.nodeExpressIcon,
        label: 'EXPRESS',
        key: 'node-express',
      },
    ],
  },
  reactNative: {
    main: {
      tooltip: 'REACT NATIVE',
      icon: svg.reactNativeIcon,
      label: 'REACT NATIVE',
      bgcolor: 'mecPrimary-100',
    },
    subTechnologies: [
      {
        tooltip: 'Java Script',
        icon: svg.javascriptIcon,
        label: 'JAVA SCRIPT',
        key: 'react-native-js',
      },
      {
        tooltip: 'Type Script',
        icon: svg.typescriptIcon,
        label: 'TYPE SCRIPT',
        key: 'react-native-ts',
      },
    ],
  },
  android: {
    main: {
      tooltip: 'Android',
      icon: svg.androidKotlinIcon,
      label: 'ANDROID',
      bgcolor: 'mecPrimary-100',
    },
    subTechnologies: [
      {
        tooltip: 'Java',
        icon: svg.androidJavaOption,
        label: 'JAVA',
        key: 'android-java',
      },
      {
        tooltip: 'Kotlin',
        icon: svg.androidKotlinIcon,
        label: 'KOTLIN',
        key: 'android-kotlin',
      },
    ],
  },
};

export const awardOptions = [
  { value: 'LIVING_BY_VALUES', label: 'Living by values' },
  { value: 'COACHING_CHAMPION', label: 'Coaching champion' },
  { value: 'TEAM_PLAYER', label: 'Team player' },
  { value: 'ALWAYS_GROWING', label: 'Always growing' },
  { value: 'GOING_ABOVE_AND_BEYOND', label: 'Going above and beyond' },
];

export const quarters = [
  { label: 'APR-MAY-JUN', value: 'QUARTER_ONE' },
  { label: 'JUL-AUG-SEPT', value: 'QUARTER_TWO' },
  { label: 'OCT-NOV-DEC', value: 'QUARTER_THREE' },
  { label: 'JAN-FEB-MAR', value: 'QUARTER_FOUR' },
];

export const quarterMapping: any = {
  'JAN-FEB-MAR': 'QUARTER_FOUR',
  'APR-MAY-JUN': 'QUARTER_ONE',
  'JUL-AUG-SEPT': 'QUARTER_TWO',
  'OCT-NOV-DEC': 'QUARTER_THREE',
};

export const emailRule = /^[a-zA-Z0-9._%+-]+@mindbowser\.com$/;

export const awards = [
  { value: 'LIVING_BY_VALUES', label: constants.livingByValues },
  { value: 'COACHING_CHAMPION', label: constants.coachingChampion },
  { value: 'TEAM_PLAYER', label: constants.teamPlayer },
  { value: 'ALWAYS_GROWING', label: constants.alwaysGrowing },
  { value: 'GOING_ABOVE_AND_BEYOND', label: constants.goingAboveAndBeyond },
];

export const ratingData = [
  {
    quarter: 'JAN-FEB-MAR',
    id: '4',
    ratingAverage: '-',
    isClickable: true,
  },
  {
    quarter: 'APR-MAY-JUN',
    id: '1',
    ratingAverage: '-',
    isClickable: true,
  },
  {
    quarter: 'JUL-AUG-SEPT',
    id: '2',
    ratingAverage: '-',
    isClickable: true,
  },
  {
    quarter: 'OCT-NOV-DEC',
    id: '3',
    ratingAverage: '-',
    isClickable: true,
  },
];

export const SINGLE_WFH_STATUS_ENUM = {
  PENDING: 'PENDING',
  PARTIAL_APPROVED: 'PARTIAL_APPROVED',
  APPROVED: 'APPROVED',
  CANCEL: 'CANCEL',
  REJECTED: 'REJECTED',
};

export const QUICK_ACTION_MENU_ITEM_LIST = [
  { icon: <CalendarMonthOutlined />, title: 'my profile' },
  { icon: <CalendarMonthOutlined />, title: 'my profile' },
  { icon: <CalendarMonthOutlined />, title: 'my profile' },
  { icon: <CalendarMonthOutlined />, title: 'my profile' },
  { icon: <CalendarMonthOutlined />, title: 'my profile' },
  { icon: <CalendarMonthOutlined />, title: 'my profile' },
];

export const DATE_FORMAT_DoMMMYYYY = 'Do MMM YYYY';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
