import { createTheme, ThemeOptions } from '@mui/material';
import { gray, mecGrey, mecPrimary, primary, themePalette } from './colors';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    [key: string]: true;
  }
}
interface ExtendedTypographyOptions extends TypographyOptions {
  [key: string]: React.CSSProperties | any;
}

export const themeTypography = createTheme(themePalette, {
  typography: {
    mec_h1: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    mec_h2: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    mec_h3: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    mec_h4: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    mec_h5: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
    },
    mec_subtitle1: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
    },
    mec_subtitle2: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
    },
    mec_body1: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    mec_body2: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
    },
    mec_body4: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      color: themePalette.palette.gray.dark,
    },
    mec_body: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      color: themePalette.palette.gray.main,
    },
    mec_caption1: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: themePalette.palette.gray.dark,
    },
    mec_caption2: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: themePalette.palette.gray.main,
    },
    mec_caption3: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: themePalette.palette.gray.light,
    },
    mec_caption4: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      color: themePalette.palette.gray.main,
    },
    mec_inputLabel: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      color: themePalette.palette.gray.inputLabelGray,
    },
    mec_helperText: {
      fontFamily: 'Inter',
      fontSize: '8px',
      fontWeight: 400,
    },
    mec_errorHelperText: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: themePalette.palette.error.main,
    },
    mec_menuItem: {
      fontFamily: 'Inter',
      fontSize: '17.5px',
      fontWeight: 400,
    },
    mec_semiBold: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
      textTransform: 'capitalize',
    },
    mec_semiBold_16: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
      textTransform: 'capitalize',
    },
    mec_bold: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '21.78px',
    },
    mec_bold_26: {
      fontFamily: 'Inter',
      fontSize: '26px',
      fontWeight: 700,
      lineHeight: '36px',
    },
    mec_bold_16: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
    },

    mec_medium: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    mec_medium2: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22px',
    },

    mec_bold_22: {
      fontFamily: 'Inter',
      fontSize: '22px',
      lineHeight: '30px',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    mec_semiBold_12: {
      fontFamily: 'Inter',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    mec_semiBold_14: {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    mec_bold_32: {
      fontFamily: 'Inter',
      fontSize: '32px',
      lineHeight: '32px',
      fontWeight: 700,
    },
    mec_caption12: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 100,
    },
    mec_medium24: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    mec_medium12: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    mec_medium22: {
      fontFamily: 'Inter',
      lineHeight: '22px',
      fontSize: '14px',
    },
    mec_bold_14: {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 700,
    },
    mec_tertiary_button: {
      fontFamily: 'Inter',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    mec_purple_chips: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 500,
      color: themePalette.palette.primary.dark,
      border: `1px solid ` + mecPrimary[200],
      backgroundColor: mecPrimary[100],
      marginTop: '4px',
    },
    mec_medium500: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 500,
      color: mecGrey[600],
    },
    mec_kanbanCount: {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '12px',
      color: mecGrey[600],
      marginTop: '4px',
    },
    mec_caption5: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      color: mecGrey[600],
    },
    mec_title1: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      color: mecGrey[800],
      lineHeight: '22px',
    },
    mec_primary_12: {
      fontFamily: 'Inter',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600,
      textTransform: 'capitalize',
      color: primary[500],
    },
    mec_option_22: {
      fontFamily: 'Inter',
      fontSize: '22px',
      lineHeight: '28px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    mec_primary_14: {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      textTransform: 'capitalize',
      color: primary[500],
    },
    mec_primary_14_500: {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
      textTransform: 'capitalize',
      color: primary[500],
    },

    mec_regular_16: {
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      color: mecGrey[700],
    },
    mec_regular_18_bold: {
      fontFamily: 'Inter',
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 500,
      color: mecGrey[800],
    },
    mec_regular_16_bold_grey: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
      color: mecGrey[600],
    },
    mec_kanbanTask: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      color: mecGrey[600],
    },
    mec_description: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      color: mecGrey[800],
    },

    mec_primary_button: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 600,
      color: mecPrimary[500],
    },
    mec_regular_16_bold_grey600: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22px',
      color: mecGrey[600],
    },
    mec_black_boldest: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 700,
      color: themePalette.palette.common.black,
      lineHeight: '30px',
    },
    checkbox_font500: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      color: mecGrey[800],
      lineHeight: '24px',
    },
    dark_font600: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 600,
      color: mecGrey[800],
      lineHeight: '22px',
    },
    light_font400: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      color: mecGrey[700],
      lineHeight: '22px',
    },
    mec_darkest_18_bold: {
      fontFamily: 'Inter',
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 700,
      color: mecGrey[900],
    },
  } as ExtendedTypographyOptions,
} as ThemeOptions);
