import { alpha, createTheme, Theme } from '@mui/material';
import { mecPrimary, themePalette } from './colors';

import '@mui/material/Button';

export const themeButtons: Theme = createTheme(themePalette, {
  palette: themePalette.palette,
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: 'contained',
        color: 'white',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Inter',
      },
      styleOverrides: {
        root: {
          borderRadius: '6px',
          textTransform: 'capitalize',
          fontFamily: 'Inter',
          minWidth: 'fit-content',
          whiteSpace: 'nowrap',
        },
        sizeSmall: {
          height: '24px',
          padding: '6px 14px',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '20px',
          fontFamily: 'Inter',
        },
        sizeMedium: {
          height: '32px',
          padding: '9px 20px',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '20px',
          fontFamily: 'Inter',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: themePalette.palette.primary.main,
            '&:hover': {
              backgroundColor: themePalette.palette.primary.dark,
            },
            '&:disabled': {
              backgroundColor: alpha(themePalette.palette.primary.light, 0.5),
              color: themePalette.palette.primary.light,
            },
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            backgroundColor: themePalette.palette.primary.main,
            '&:hover': {
              backgroundColor: themePalette.palette.primary.dark,
            },
            '&:disabled': {
              backgroundColor: alpha(themePalette.palette.gray.main, 0.5),
              color: themePalette.palette.gray.main,
            },
          },
        },
        {
          props: { variant: 'rounded', color: 'primary' },
          style: {
            backgroundColor: themePalette.palette.primary.main,
            borderRadius: '9999px !important',
            color: 'white',
            '&:hover': {
              backgroundColor: themePalette.palette.primary.extraDark,
            },
            '&:disabled': {
              backgroundColor: alpha(themePalette.palette.gray.main, 0.5),
              color: themePalette.palette.gray.main,
            },
          },
        },
        {
          props: { variant: 'rounded_outlined', color: 'gray' },
          style: {
            backgroundColor: 'transparent',
            borderColor: themePalette.palette.gray.extraLight,
            border: '1px solid',
            color: themePalette.palette.gray.main,
            borderRadius: '9999px !important',
            '&:hover': {
              borderColor: themePalette.palette.gray.extraLight,
              backgroundColor: themePalette.palette.gray.extraLight,
            },
            '&:disabled': {
              backgroundColor: alpha(themePalette.palette.gray.extraLight, 0.5),
              color: themePalette.palette.gray.main,
            },
          },
        },
        {
          props: { variant: 'rounded_outlined', color: 'primary' },
          style: {
            backgroundColor: 'transparent',
            borderColor: themePalette.palette.primary.extraLight,
            color: themePalette.palette.primary.main,
            borderRadius: '9999px !important',
            border: '1px solid',
            '&:hover': {
              borderColor: themePalette.palette.primary.extraLight,
              backgroundColor: themePalette.palette.primary.extraLight,
            },
            '&:disabled': {
              backgroundColor: alpha(themePalette.palette.primary.extraLight, 0.5),
              color: themePalette.palette.primary.main,
            },
          },
        },

        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: 'transparent',
            border: `1px solid ${themePalette.palette.primary.main}`,
            color: themePalette.palette.gray.extraDark,

            '&:hover': {
              color: themePalette.palette.primary.main,
              borderColor: themePalette.palette.primary.extraLight,
              backgroundColor: themePalette.palette.primary.extraLight,
            },
            '&:disabled': {
              backgroundColor: alpha(themePalette.palette.primary.extraLight, 0.5),
              color: themePalette.palette.primary.main,
            },
          },
        },
        {
          props: { variant: 'tertiary', color: 'primary' },
          style: {
            backgroundColor: 'transparent',
            border: `1px solid #ffffff`,
            color: themePalette.palette.primary.main,

            '&:hover': {
              color: themePalette.palette.gray.extraDark,
              border: `1px solid ${themePalette.palette.gray.extraDark}`,
              borderColor: themePalette.palette.gray.extraDark,
              backgroundColor: 'transparent',
            },
            '&:disabled': {
              backgroundColor: alpha(themePalette.palette.primary.extraLight, 0.5),
              color: themePalette.palette.primary.main,
            },
          },
        },
        {
          props: { variant: 'inactive', color: 'primary' },
          style: {
            backgroundColor: alpha(themePalette.palette.primary.light, 0.1),
            color: themePalette.palette.primary.light,
            '&:hover': {
              backgroundColor: alpha(themePalette.palette.primary.light, 0.1),
            },
            '&:disabled': {
              backgroundColor: alpha(themePalette.palette.primary.light, 0),
              color: themePalette.palette.primary.light,
            },
          },
        },
      ],
    },
  },
});
