import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  downloadWorklogWithKeyUrl,
  getProjectResourceListForViewUrl,
  getProjectResourceListUrl,
  getResourceTimesheetUrl,
  getResourceWorklogListByKeyUrl,
  getResourceWorklogListUrl,
} from '../../constants/api-urls';
import axiosInstance from '../../utils/httpInterceptor';
import { HTTP_OK } from '../../constants/status-code-constant';
import { defaultExceptionHandler } from '../../utils/defaultExcpetionHandler';
import { downloadFile } from '../../utils/downloadWorklog';

interface SprintState {
  projectTimesheet: any;
  projectResources: any;
  worklogList: any;
  projectName: string;
  totalListHours: number | string;
  loading: boolean;
  downloadLoading: boolean;
  error: string | undefined | null;
}

const initialState: SprintState = {
  projectTimesheet: {},
  projectResources: {},
  worklogList: [],
  projectName: '',
  totalListHours: 0,
  loading: false,
  downloadLoading: false,
  error: null,
};

// Define async thunk for API call
export const getProjectResouceTimesheet = createAsyncThunk(
  'resourceReducer/getProjectResouceTimesheet',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${getResourceTimesheetUrl}`, payload);

      if (response?.status !== HTTP_OK) {
        return rejectWithValue(response?.data?.message);
      }
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(defaultExceptionHandler(error));
    }
  },
);

export const getProjectResourceList = createAsyncThunk(
  'resourceReducer/getProjectResourceList',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${
          params?.mode === 'PROJECT' ? getProjectResourceListUrl : getProjectResourceListForViewUrl
        }/${params?.projectId}?timeZone=Asia/Kolkata`,
      );
      if (response?.status !== HTTP_OK) {
        return rejectWithValue(response?.data?.message);
      }
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(defaultExceptionHandler(error));
    }
  },
);

export const getResourceWorklogList = createAsyncThunk(
  'resourceReducer/getResourceWorklogList',
  async (params: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${
          params?.mode === 'PROJECT' ? getResourceWorklogListUrl : getResourceWorklogListByKeyUrl
        }`,
        params.payload,
      );
      if (response?.status !== HTTP_OK) {
        return rejectWithValue(response?.data?.message);
      }
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(defaultExceptionHandler(error));
    }
  },
);

export const downloadWorklogSheet = createAsyncThunk(
  'resourceReducer/downloadWorklogSheet',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${downloadWorklogWithKeyUrl}`, params.payload);
      if (response?.status !== HTTP_OK) {
        return rejectWithValue(response?.data?.message);
      }
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(defaultExceptionHandler(error));
    }
  },
);

const resourceReducer = createSlice({
  name: 'resourceReducer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProjectResouceTimesheet.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProjectResouceTimesheet.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.projectTimesheet = action.payload;
      })
      .addCase(getProjectResouceTimesheet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getProjectResourceList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProjectResourceList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.projectResources = action.payload;
      })
      .addCase(getProjectResourceList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getResourceWorklogList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getResourceWorklogList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.worklogList = action.payload?.list;
        state.projectName = action.payload?.projectName;
        state.totalListHours = action.payload?.totalhours;
      })
      .addCase(getResourceWorklogList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(downloadWorklogSheet.pending, state => {
        state.downloadLoading = true;
        state.error = null;
      })
      .addCase(downloadWorklogSheet.fulfilled, (state, action: PayloadAction<any>) => {
        state.downloadLoading = false;
        downloadFile(action.payload, state?.projectName);
      })
      .addCase(downloadWorklogSheet.rejected, (state, action) => {
        state.downloadLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const {} = resourceReducer.actions;
export default resourceReducer.reducer;
