import { createSlice } from "@reduxjs/toolkit";

interface DrawerState {
  open: boolean;
}

const initialState: DrawerState = {
  open: false,
};

const profileDrawerReducer = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openProfileDrawer: (state) => {
      state.open = true;
    },
    closeProfileDrawer: (state) => {
      state.open = false;
    },
    toggleProfileDrawer: (state) => {
      state.open = !state.open;
    },
  },
});

export const { openProfileDrawer, closeProfileDrawer, toggleProfileDrawer } =
  profileDrawerReducer.actions;
export default profileDrawerReducer.reducer;
