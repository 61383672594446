// src/features/example/exampleSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { projectByIDUrl, projectListUrl } from "../../constants/api-urls";
import axiosInstance from "../../utils/httpInterceptor";
import { authUpdateAndReload } from "../../services/authServices";
import {
  Project,
  ProjectAuthUser,
  ProjectSettings,
  TeamMember,RepoProject,
} from "../../types/project";
import { FAIL_TO_FETCH_DATA } from '../../constants/messages';

export const PROJECT_PER_PAGE = 10;

interface ProjectState {
  projectList: Project[];
  projectData: {
    project: Project;
    teamMember: TeamMember[];
    authUser: ProjectAuthUser | null;
    projectSettings: ProjectSettings | null;
    changeRequestProject: any;
    settings: any;
    repoCount: number;
    timeZones: string[];
    clientModels: [];
    history: any;
    repoProject: any;
  } | null;
  totalProjects: number;
  page: number;
  loading: boolean;
  hasMore: boolean;
  searching: boolean;
  searchString: string | null;
  initialFetched: boolean;
  error: string | null;
  isBitbucketProjectAssigned: boolean | false;
}

const initialState: ProjectState = {
  projectList: [],
  projectData: null,
  hasMore: false,
  page: 0,
  totalProjects: 0,
  loading: false,
  error: null,
  searching: false,
  searchString: null,
  initialFetched: false,
  isBitbucketProjectAssigned: false,
};

// Define async thunk for API call
export const fetchProjectList = createAsyncThunk(
  'project/fetchList',
  async (params: { page: number; size: number; name: string } | any) => {
    const response = await axiosInstance.get<any>(projectListUrl, { params });

    if (response.data?.tokenExpire) {
      authUpdateAndReload();
      return;
    }

    return response.data.data;
  },
);

export const fetchProject = createAsyncThunk('project/fetch', async (id: number) => {
  const response = await axiosInstance.get<any>(`${projectByIDUrl}${id}`);

  if (response.data?.tokenExpire) {
    authUpdateAndReload();
    return;
  }

  return response.data.data;
});

const projectReducer = createSlice({
  name: 'project',
  initialState,
  reducers: {
    resetProjects: state => initialState,
    setProjectPage: (state, action) => {
      return { ...state, page: action.payload };
    },
    setProjectSearching: (state, action) => {
      return {
        ...state,
        searching: action.payload.searching,
        searchString: action.payload.searchString,
        projectList: [],
        projectData: null,
        hasMore: true,
        page: 0,
        totalProjects: 0,
      };
    },
    setisBitbucketProjectAssigned: (state, action) => {
      state.isBitbucketProjectAssigned = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProjectList.pending, state => {
      state.error = null;
    });
    builder.addCase(fetchProjectList.fulfilled, (state, action: PayloadAction<any>) => {
      state.projectList = [...state.projectList, ...action.payload.models];
      state.searching = false;
      state.initialFetched = true;
      state.hasMore = !action.payload.isLast;
      state.totalProjects = action.payload.totalElements;
    });
    builder.addCase(fetchProjectList.rejected, (state, action) => {
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });

    builder.addCase(fetchProject.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.isBitbucketProjectAssigned = false;
      state.projectData = {
        project: action.payload.project,
        teamMember: action.payload.teamModel,
        authUser: action.payload.authUser,
        projectSettings: action.payload.projectSettings,
        changeRequestProject: action.payload.changeRequestProject,
        settings: action.payload.projectSettings,
        repoCount: action.payload.repoCount,
        timeZones: action.payload.timeZones,
        clientModels: action.payload.clientModels,
        history: action.payload.project.history,
        repoProject: action.payload.project.repoProject,
      };
    });
    builder.addCase(fetchProject.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || FAIL_TO_FETCH_DATA;
    });
  },
});

export const { resetProjects, setProjectPage, setProjectSearching, setisBitbucketProjectAssigned } = projectReducer.actions;

export default projectReducer.reducer;
