import { INBOX_STATUS_ENUM } from "../constants/const";

export const getInboxStatus = (status: string) => {
  switch (status) {
    case INBOX_STATUS_ENUM.IGNORED:
      return `&status=${INBOX_STATUS_ENUM.IGNORED}`;
    case INBOX_STATUS_ENUM.SUBMITTED:
      return `&status=${INBOX_STATUS_ENUM.SUBMITTED}`;
    case INBOX_STATUS_ENUM.TODO:
      return `&status=${INBOX_STATUS_ENUM.TODO}`;

    default:
      return "";
  }
};
