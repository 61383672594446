import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DialogState {
  openDialogIds: string[];
}

const initialState: DialogState = {
  openDialogIds: [],
};

const dialogReducer = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<string>) => {
      state.openDialogIds.push(action.payload);
    },
    closeDialog: (state, action: PayloadAction<string>) => {
      state.openDialogIds = state.openDialogIds.filter(
        (id) => id !== action.payload
      );
    },
  },
});

export const { openDialog, closeDialog } = dialogReducer.actions;
export default dialogReducer.reducer;
