import axios from "axios";
import axiosInstance from "../../utils/httpInterceptor";
import {
  authUserUrl,
  connectJiraUrl,
  getLoggedInUserUrl,
  userAccessTokenUrl,
} from "../../constants/api-urls";
import store from "../../store/store";
import {
  setTokens,
  setUser,
  clearAuth,
  setUserPhoto,
  setProject,
  setLastLoggedInAt,
} from "../../store/reducers/authReducer";
import { persistor } from "../../store/store";
import { USER_ROLE } from "../../constants/app-constant";
export interface UserInterface {
  deleted?: boolean;
  Integer?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  role?: USER_ROLE;
  resourceId?: number;
}

export async function login(loginObject: any) {
  const headers = {
    Authorization: "Bearer " + loginObject?.idToken,
  };
  try {
    const response = await axios.get(authUserUrl, { headers });
    const { token, user } = response.data.data;
    const { accessToken, refreshToken } = token;
    store.dispatch(setTokens({ accessToken, refreshToken }));
    store.dispatch(setUser(user));
    return response.data.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
}

export function connectJira(obj: any) {
  return axiosInstance.post(connectJiraUrl, obj);
}

export async function logout() {
  console.log("called");
  store.dispatch(clearAuth());
  await persistor.purge();
  window.location.href = "/login";
}

export const getAccessToken = () => {
  const state = store.getState();
  return state.auth.accessToken;
};

export async function findUser() {
  return axiosInstance.get(getLoggedInUserUrl);
}

export async function authUpdateAndReload(pageUrl?: any) {
  try {
    const res = await updateToken();
    if (res?.data?.accessToken && res?.data?.refreshToken) {
      store.dispatch(
        setTokens({
          accessToken: res.data.accessToken,
          refreshToken: res.data.refreshToken,
        })
      );
      window.location.reload();
    } else {
      window.location.href = "/sign-in";
    }
  } catch (error) {
    window.location.href = "/sign-in";
  }
}

export async function updateToken() {
  return axiosInstance.get(userAccessTokenUrl);
}

export function setUserPhotoFunc(photoUrl: string) {
  store.dispatch(setUserPhoto(photoUrl));
}

export function setProjectDetails(project: any) {
  store.dispatch(setProject(project));
}

export function setLastLoggedInAtFunc(date: string) {
  store.dispatch(setLastLoggedInAt(date));
}

export function setUserDetails(user: UserInterface) {
  store.dispatch(setUser(user));
}

export function getUser() {
  const state = store.getState();
  return state.auth.user;
}

export function getUserPhoto() {
  const state = store.getState();
  return state.auth.userPhoto;
}

export function getProjectDetails() {
  const state = store.getState();
  return state.auth.project;
}

export function getLastLoggedInAt() {
  const state = store.getState();
  return state.auth.lastLoggedInAt;
}

export function getRefreshToken() {
  const state = store.getState();
  return state.auth.refreshToken;
}
