import React, { ReactNode } from 'react';
import { useAppSelector } from '../../store/hooks';
import classNames from 'classnames';

interface ActionBarProps {
  children: ReactNode;
  className?: string;
}

const ActionBar: React.FC<ActionBarProps> = ({ children, className = '' }) => {
  const collapsed = useAppSelector(state => state.sidebarReducer.collapsed);

  const baseClass = `h-12 z-10 ${
    collapsed ? 'pl-10' : 'pl-3'
  } border-b bg-white w-full border-b-gray-300 sticky top-10 items-center flex p-2 px-4 ml-[2px] `;

  return <div className={classNames(baseClass, className)}>{children}</div>;
};

export default ActionBar;
