import { createSlice } from "@reduxjs/toolkit";

interface LoadingBarState {
  show: boolean;
  mode: "determinate" | "indeterminate" | "buffer" | "query";
  progress: number;
}

const initialState: LoadingBarState = {
  show: false,
  mode: "indeterminate",
  progress: 0,
};

const loadingBarSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    showLoading: (state, action) => ({ ...state, show: action.payload }),
    setMode: (state, action) => ({ ...state, mode: action.payload }),
    setProgress: (state, action) => ({ ...state, progress: action.payload }),
  },
});

export const { showLoading, setMode, setProgress } = loadingBarSlice.actions;
export default loadingBarSlice.reducer;
