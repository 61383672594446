import { styled } from "@mui/system";
import { TableCell } from "@mui/material";
import { mecGrey } from "../../constants/theme/colors";

export const StyledTableHeadCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    padding: "12px 16px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "16.94px",
    color: mecGrey[600],
    height: "44px",
  },
});

export const StyledTableContentCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    padding: "8px 16px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: mecGrey[800],
    borderBottom: `1px solid ${mecGrey[300]}`,
    height: "44px",
  },
});
