import { createSlice } from '@reduxjs/toolkit';
import { severity } from '../../constants/app-constant';
import { AlertColor } from "@mui/material/Alert";

interface snackBarState {
    open: boolean;
    message: string;
    severity: AlertColor
  }
  

const initialState = {
    open: false,
    severity: severity.info,
    message: null,
};

const snackBarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        resetSnackBar: (state) => initialState,
        setSnackBar: (state, action) => {
            return {
                ...state,
                severity: action.payload.severity,
                message: action.payload.message,
                open: true,
            };
        },
    },
});

export const { resetSnackBar, setSnackBar } = snackBarSlice.actions;
export default snackBarSlice.reducer;
