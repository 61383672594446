import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface TaskState {
    taskData: any;
  }
const initialState: TaskState = {
    taskData: {}
};
const taskReducer = createSlice({
    name: "setData",
    reducers: {
        setTaskDetails(state, action:PayloadAction<any>) {
            return void(state.taskData = action.payload);
        },
    },
    initialState:initialState,
})

export const { setTaskDetails } = taskReducer.actions;
export default  taskReducer.reducer;