import React, { useEffect } from "react";
import { displayVersion } from "./utils/console-reporting";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import LoadingBar from "./components/LoadingBar";
import CustomSnackBar from "./components/SnackBar";
import { ThemeProvider } from "@mui/material";
import theme from "./constants/theme";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store/store";

const App: React.FC = () => {
  useEffect(() => {
    displayVersion();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LoadingBar />
          <CustomSnackBar />
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
