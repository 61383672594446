import { combineReducers } from 'redux';

import addChangeRequestResources from './reducers/addResourcesForCR';
import goalReducer from './reducers/goalsReducer';
import inboxReducer from './reducers/inboxReducer';
import sidebarReducer from './reducers/sidebarReducer';
import selectedProjectReducer from './reducers/selectedProjectReducer';
import dialogReducer from './reducers/dialogReducer';
import profileDrawerReducer from './reducers/profileDrawerReducer';
import dashboardReducer from './reducers/dashboardReducer';
import loadingBarReducer from './reducers/loadingBarReducer';
import authReducer from './reducers/authReducer';
import snackBarReducer from './reducers/snackBarReducer';
import projectReducer from './reducers/projectReducer';
import sprintReducer from './reducers/sprintReducer';
import kraKpiSlice from './slices/Kra-Kpi/kra-kpi.slice';
import feedbackReducer from './reducers/feedback.slice';
import filterReducer from './reducers/filterReducer';
import userReducer from './reducers/userRdecuder';
import timesheetReducer from './reducers/timesheetReducer';
import workLogReducer from './reducers/workLogReducer';
import documentRepositoryReducer from './reducers/documentRepositoryReducer';
import kanbanReducer from './reducers/kanbanReducer';
import taskDetails from './reducers/taskReducer';
import formReducer from './reducers/formReducer';
import searchReducer from './reducers/searchReducer';

import profitLossFilterReducer from './reducers/profitLossFilterReducer';
const rootReducer = combineReducers({
  sidebarReducer,
  selectedProjectReducer,
  dialogReducer: dialogReducer,
  profileDrawerReducer,
  dashboard: dashboardReducer,
  loadingBar: loadingBarReducer,
  auth: authReducer,
  snackBar: snackBarReducer,
  projects: projectReducer,
  goals: goalReducer,
  forms: formReducer,
  sprint: sprintReducer,
  inbox: inboxReducer,
  kraAndKpiState: kraKpiSlice,
  feedback: feedbackReducer,
  filter: filterReducer,
  user: userReducer,
  addChangeRequestResources: addChangeRequestResources,
  timesheet: timesheetReducer,
  documentRepository: documentRepositoryReducer,
  kanban: kanbanReducer,
  workLog: workLogReducer,
  taskData: taskDetails,
  profitLossFilterReducer: profitLossFilterReducer,
  search: searchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
