import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

// Update the interface to type date as Date
export interface workLogDataType {
  description: string;
  date: string;
  hours: number;
  id: number | null;
  editStatus: boolean;
}

export interface workLogInterface {
  workLogData: workLogDataType;
}

const initialState: workLogInterface = {
  workLogData: {
    description: '',
    date: '',
    hours: 0,
    id: null,
    editStatus: false,
  },
};

const workLogReducer = createSlice({
  name: 'workLog',
  initialState,
  reducers: {
    setWorkLogEdit: (state, action: PayloadAction<workLogDataType>) => {
      state.workLogData = action.payload;
    },
    clearWorkLog: state => {
      state.workLogData = initialState.workLogData;
    },
  },
});

export const { setWorkLogEdit, clearWorkLog } = workLogReducer.actions;
export default workLogReducer.reducer;
