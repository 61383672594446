import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataType {
    id: string | number,
    projectType: string,
    email: string,
    jiraUserName: string,
    startDate: string,
    endDate: string,
    utilization: string | number
    role: string
    technology: [],
}


const initialState: DataType[] = [];

const addEditChangeRequestResources = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setChangeRequestResource: (state, action: PayloadAction<any>) => {
        state.unshift(action.payload);
    },
    setEmptyChangeRequestResource: (state) => {
        state.splice(0, state.length)
    },
    deleteChangeRequestResource: (state, action: PayloadAction<any>) => {
        const elem_index = state.findIndex(elem => elem.email == action.payload);
        state.splice(elem_index, 1);
    },
    editChangeRequestResource: (state, action: PayloadAction<any>) => {
        const elem_index = state.findIndex(elem => elem.email == action.payload.email);
        state[elem_index] = action.payload;
    }
  },
});

export const {
    setChangeRequestResource,
    editChangeRequestResource,
    deleteChangeRequestResource,
    setEmptyChangeRequestResource
} = addEditChangeRequestResources.actions;

export default addEditChangeRequestResources.reducer;
