import { kanbanPaginationSize } from '../components/KanbanModule/utils';
import { getTaskListWithFiltersUrl } from '../constants/api-urls';
import { defaultExceptionHandler } from '../utils/defaultExcpetionHandler';
import axiosInstance from '../utils/httpInterceptor';

export const getTaskListWithFilters = async (params: any) => {
  try {
    const response = await axiosInstance
      .post(
        `${getTaskListWithFiltersUrl}?page=${params.page}&size=${kanbanPaginationSize}`,
        params.payload,
      )
      .catch(error => {
        throw defaultExceptionHandler(error);
      });
    return response.data.data;
  } catch (error) {
    throw defaultExceptionHandler(error);
  }
};
