import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toggleSidebar, setSidebarCollapsed } from '../../store/reducers/sidebarReducer';
import { setSelectedProject } from '../../store/reducers/selectedProjectReducer';

import { Sidebar, Menu, MenuItem, SubMenu, SidebarProps } from 'react-pro-sidebar';

import { default as DropdownMenu } from '../Menu';
import Navbar from '../Navbar';

import {
  HomeOutlined,
  EmailOutlined,
  ArticleOutlined,
  BarChartOutlined,
  MenuOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
  MoreHoriz,
  FolderOutlined,
} from '@mui/icons-material';

import { SideBarTab, SideBarTabs } from '../../constants/app-constant';
import { Typography } from '@mui/material';
import { RouteConstants } from '../../constants/route-constant';
import svg from '../../assets';
import { projectListItems } from './constant';

interface ProjectListItem {
  label: string;
  icon: JSX.Element;
  to: string;
}

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const { user: loggedInUser } = useAppSelector(state => state.auth);
  const collapsed = useAppSelector(state => state.sidebarReducer.collapsed);
  const selectedProject = useAppSelector(state => state.selectedProjectReducer.selectedProject);

  const handleProjectSelection = (projectLabel: string) => {
    dispatch(setSelectedProject(projectLabel));
  };

  useEffect(() => {
    dispatch(setSelectedProject(projectListItems[0]?.label));
  }, [dispatch]);

  return (
    <div className="w-full">
      <Navbar />
      <div className="relative flex w-full">
        <Sidebar
          collapsed={collapsed}
          className="h-[calc(100vh-2.5rem)] !fixed top-10 border-r border-r-gray-300"
        >
          {/* TODO */}
          {/* <div className="w-full h-12 border border-b-slate-200 flex justify-between p-3 pl-7">
            <DropdownMenu
              buttonElement={
                <div hidden={collapsed} className="capitalize font-semibold">
                  {selectedProject}
                  <span className="ml-2">
                    <KeyboardArrowDownOutlined fontSize="small" />
                  </span>
                </div>
              }
              position="left"
              menuClassName="h-max mt-4 z-30"
              children={
                <div className="w-full p-2 space-y-1">
                  {projectListItems.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => handleProjectSelection(item.label)}
                        className="w-full p-2 hover:bg-gray-extralight rounded-lg text-sm capitalize cursor-pointer"
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              }
            />
            <div className="" hidden={collapsed}>
              <button onClick={() => dispatch(toggleSidebar())}>
                <MenuOutlined />
              </button>
            </div>
          </div> */}
          <Menu
            renderExpandIcon={({ open }) => (
              <span>
                {open ? (
                  <KeyboardArrowUpOutlined fontSize="small" />
                ) : (
                  <KeyboardArrowDownOutlined fontSize="small" />
                )}
              </span>
            )}
          >
            {SideBarTabs.reduce((a: SideBarTab[], tab) => {
              if (loggedInUser?.role && tab.roles?.includes(loggedInUser?.role)) {
                return [...a, tab];
              } else return a;
            }, []).map((item, index) => (
              <MenuItem
                key={index}
                icon={item.icon}
                className={`capitalize text-sm w-full ${
                  location.pathname?.indexOf(item.to) !== -1
                    ? 'bg-gray-extralight text-primary'
                    : ''
                }`}
                component={item.to ? <Link to={item.to} /> : undefined}
                onClick={
                  item.label === 'docs'
                    ? () => {
                        dispatch(setSidebarCollapsed(true));
                      }
                    : undefined
                }
              >
                {item.label}
              </MenuItem>
            ))}
            {/* TODO */}
            {/* <SubMenu label="More" icon={<MoreHoriz />} className="text-sm">
              <MenuItem
                icon={<img src={svg["kraKpiIcon"]} alt="kra-kpi-icon" />}
                className={`capitalize text-sm w-full ${
                  location.pathname.startsWith(RouteConstants.ROUTE_KRA_KPI)
                    ? "bg-gray-extralight text-primary"
                    : ""
                }`}
                component={<Link to={RouteConstants.ROUTE_KRA_KPI} />}
              >
                <Typography variant="mec_subtitle1">KRA/KPI</Typography>
              </MenuItem>
              <MenuItem
                className={`capitalize text-sm w-full ${
                  location.pathname.startsWith(RouteConstants.ROUTE_KRA_KPI)
                    ? "bg-gray-extralight text-primary"
                    : ""
                }`}
                component={<Link to={RouteConstants.ROUTE_WFH_REQUEST} />}
              >
                <Typography variant="mec_subtitle1">WFH Request</Typography>
              </MenuItem>
              <MenuItem> more two</MenuItem>
              <MenuItem> more three</MenuItem>
            </SubMenu> */}
          </Menu>
        </Sidebar>
        <main
          className={`pt-10 w-full flex transition-all duration-300 ${
            collapsed ? 'pl-[79px]' : 'pl-[249px]'
          }`}
        >
          <div className="w-full relative">
            <div className="w-full">{children}</div>
            <div className="fixed top-[3.25rem] left-[5.5rem] z-10" hidden={!collapsed}>
              <button onClick={() => dispatch(toggleSidebar())}>
                <MenuOutlined />
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
