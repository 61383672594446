import { alpha, createTheme } from '@mui/material';
import { gray } from './colors';
import { themePalette } from './colors';

export const themeInputs = createTheme(themePalette, {
  components: {
    MuiFormControl: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #000 inset',
          '-webkit-text-fill-color': themePalette.palette.secondary.extraLight,
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '& .MuiOutlinedInput-root, & .MuiAutocomplete-root': {
              border: 'none',
              height: '38px',
              '& fieldset': {
                borderRadius: '7px',
                border: `1px solid ${themePalette.palette.gray.light}`,
              },
              input: {
                height: '38px',
                '&:-webkit-autofill': {
                  transitionDelay: '9999s',
                  transitionProperty: 'background-color, color',
                },
                '&::placeholder': {
                  fontWeight: 400,
                  color: themePalette.palette.gray.dark,
                },
              },
              '& .MuiSvgIcon-root': {
                fill: '#202020',
              },
              '&:hover fieldset': {
                borderColor: themePalette.palette.primary.main,
              },
              '&.Mui-focused fieldset': {},
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '23px',
              '&::placeholder': {
                opacity: 0.5,
              },
            },
            '& .placeholder': {
              '&:hover + .MuiOutlinedInput-root fieldset, &:hover + .MuiAutocomplete-root + fieldset':
                {
                  border: `1px solid ${themePalette.palette.primary.main}`,
                },
            },
            '& .MuiFormHelperText-root': {
              margin: 0,
              fontFamily: 'Inter',
            },
            '& .MuiOutlinedInput-root.Mui-error': {
              '&:hover fieldset': {
                borderColor: themePalette.palette.error.main,
              },
              '&.Mui-focused fieldset': {
                border: `2px solid ${themePalette.palette.error.main}`,
                borderRadius: '8px !important',
              },
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              backgroundColor: gray[50],
              '& fieldset': {
                borderColor: gray[300],
              },
              '&:hover fieldset': {
                borderColor: gray[300],
              },
              '&.Mui-focused fieldset': {
                border: `2px solid ${gray[300]}`,
                borderRadius: '8px !important',
              },
            },
            '&.inputHeightAuto': {
              '& .MuiOutlinedInput-root': {
                height: 'auto',
              },
            },
            // SEARCH INPUT FIELD
            '&.searchInput': {
              '& .MuiOutlinedInput-root': {
                height: '38px',
                '& fieldset': {
                  borderColor: 'transparent',
                  borderRadius: '7px',
                },

                input: {
                  '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                  },
                  '&::placeholder': {
                    fontWeight: 400,
                    color: themePalette.palette.gray.dark,
                  },
                },
                '&:hover fieldset': {
                  // backgroundColor: alpha(
                  //   themePalette.palette.primary.main,
                  //   0.3
                  // ),
                  borderColor: themePalette.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                  border: `2px solid ${themePalette.palette.primary.main}`,
                  borderRadius: '8px !important',
                },
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '23px',
                '&::placeholder': {
                  opacity: 0.5,
                },
              },
              '& .MuiFormHelperText-root': {
                margin: 0,
                fontFamily: 'Inter',
              },
              '& .MuiOutlinedInput-root.Mui-error': {
                '& fieldset': {
                  backgroundColor: alpha(themePalette.palette.error.main, 0.2),
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  backgroundColor: alpha(themePalette.palette.error.main, 0.2),
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  backgroundColor: alpha(themePalette.palette.error.main, 0.2),
                  borderColor: 'transparent',
                  borderRadius: '8px !important',
                },
              },
              '& .MuiOutlinedInput-root.Mui-disabled': {
                borderColor: 'transparent',
                backgroundColor: gray[100],
                '& fieldset': {
                  backgroundColor: gray[100],
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                  backgroundColor: gray[100],
                },
                '&.Mui-focused fieldset': {
                  backgroundColor: gray[100],
                  borderColor: 'transparent',
                  borderRadius: '8px !important',
                },
              },
            },
          },
        },
      ],
    },
  },
});
