export const environment = {
  production: false,
  bitbucketSource: process.env.REACT_APP_BITBUCKET_SOURCE,
  baseUrl: process.env.REACT_APP_BASE_URL,
  jiraBaseUrl: process.env.REACT_APP_JIRA_BASE_URL,
  projectBaseUrl: process.env.REACT_APP_PROJECT_BASE_URL,
  springBootUrl: process.env.REACT_APP_SPRING_BOOT_URL,
  springBootUrlV2: process.env.REACT_APP_SPRING_BOOT_URL_V2,
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  jirasyncBaseUrl: process.env.REACT_APP_JIRA_ASYNC_BASE_URL,
  GoogleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
  },
  csvTemplateUrl: process.env.REACT_APP_CSV_TEMPLATE_URL,
  appUrl: process.env.REACT_APP_APP_URL,
};
