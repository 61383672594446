import axiosInstance from "../utils/httpInterceptor";
import {
  deleteProfitLossFilterUrl,
  getEmailListUrl,
  getProfitAndLossFilterStatementUrl,
  getProfitAndLossFiltersListUrl,
  getProfitAndLossStatementUrl,
  getProfitLossStatisticsListUrl,
  projectListUrl,
} from "../constants/api-urls";

// GET API'S
export function getProjectList(obj: any = {}) {
  return axiosInstance.get(projectListUrl, { params: obj });
}

export function getProjectStatisticsById(id: string | number) {
  return axiosInstance.get(getProfitLossStatisticsListUrl(id));
}

export function getProfitAndLossFilters() {
  return axiosInstance.get(getProfitAndLossFiltersListUrl);
}

export function getResourcesForFilter() {
  return axiosInstance.get(getEmailListUrl);
}

// POST API'S
export function createProfitLossFilters(obj: any = {}) {
  return axiosInstance.post(getProfitAndLossFiltersListUrl, obj);
}

export function getProfitAndLossStatement(obj: any = {}) {
  return axiosInstance.post(getProfitAndLossStatementUrl, obj);
}

export function getProfitAndLossFilterStatement(obj: any = {}) {
  return axiosInstance.post(getProfitAndLossFilterStatementUrl, obj);
}

// DELETE API's
export function deleteProfitLossFilterById(teamId: number) {
  return axiosInstance.delete(deleteProfitLossFilterUrl(teamId));
}

//PUT API's
export function updateProfitLossFilter(obj: any = {}) {
  return axiosInstance.put(getProfitAndLossFiltersListUrl, obj);
}
