import React from "react";
import classNames from "classnames";

interface BadgeProps {
  title: string;
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({ title, className = "" }) => {
  const baseClass =
    "px-2 py-1 text-xs rounded-lg flex justify-center items-center capitalize";

  return <div className={classNames(baseClass, className)}>{title}</div>;
};

export default Badge;
