import { defaultMessage } from '../constants/messages';

export const defaultExceptionHandler = (error: any) => {
  let message = defaultMessage;

  if (error?.response?.data?.message) {
    message = error?.response?.data?.message;
  } else if (error.message) {
    message = error.message;
  } else {
    message = error;
  }
  return message;
};
