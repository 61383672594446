import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetGoalsPayload } from '../../services/goalsService';
import axiosInstance from '../../utils/httpInterceptor';
import { authUpdateAndReload } from '../../services/authServices';
import {
  getCommentByGoalIdUrl,
  getGoalsUrl,
  getPreSignedEndPointUrl,
  submitGoalByMenteeUrl,
  updateGoalStatusUrl,
  saveCommentUrl,
  editCommentUrl,
  getKraKpiUrl,
  saveGoalsUrl,
  acceptGoalsUrl,
} from '../../constants/api-urls';
import {
  AcceptGoals,
  GoalModel,
  SaveGoalPayload,
  UpdateGoalPayload,
  UpdateGoalStatus,
} from '../../types/goals';
import { HTTP_OK } from '../../constants/status-code-constant';
import { defaultExceptionHandler } from '../../utils/defaultExcpetionHandler';
import axios from 'axios';

interface GoalState {
  isLoading: boolean;
  isGoalAvailable: boolean;
  goalModels: GoalModel[];
  timeStamp: string;
}

const initialState: GoalState = {
  isLoading: false,
  isGoalAvailable: false,
  goalModels: [],
  timeStamp: '',
};

export const getGoals = createAsyncThunk(
  'goal/getGoals',
  async (payload: GetGoalsPayload | null) => {
    const response = await axiosInstance.post<any>(getGoalsUrl, payload);

    if (response.data?.tokenExpire) {
      authUpdateAndReload();
      return;
    }

    return response.data;
  },
);

export const getPreSignedUrl: any = createAsyncThunk(
  'goal/getPreSignedUrl',
  async (payload: { fileName: string; folderName: string }) => {
    const response: any = await axiosInstance.post(getPreSignedEndPointUrl(payload), {});

    return response?.data;
  },
);

export const uploadFileToS3: any = createAsyncThunk(
  'goal/uploadFileToS3',
  async (payload: { url: string; file: File }) => {
    const response: any = await axios.put(payload.url, payload.file);
    if (response?.status !== HTTP_OK) {
      throw new Error('Something went wrong!');
    }
    return response?.data;
  },
);

export const submitGoalByMentee: any = createAsyncThunk(
  'goal/submitGoalByMentee',
  async (payload: any) => {
    try {
      const response: any = await axiosInstance.post(submitGoalByMenteeUrl, payload);
      if (response?.status !== HTTP_OK) {
        throw new Error('Something went wrong!');
      }
      return response?.data;
    } catch (error) {
      if (typeof error === 'string') {
        throw defaultExceptionHandler({ message: error });
      } else {
        throw defaultExceptionHandler(error);
      }
    }
  },
);

export const updateGoalStatus: any = createAsyncThunk(
  'goal/updateGoalStatus',
  async (payload: UpdateGoalStatus) => {
    try {
      const response: any = await axiosInstance.post(updateGoalStatusUrl, payload);
      if (response?.status !== HTTP_OK) {
        throw new Error(response?.data?.data?.error);
      }
      return response?.data;
    } catch (error) {
      if (typeof error === 'string') {
        throw defaultExceptionHandler({ message: error });
      } else {
        throw defaultExceptionHandler(error);
      }
    }
  },
);

export const getCommentByGoalId = createAsyncThunk(
  'goal/getCommentByGoalId',
  async (goalId: number) => {
    const response: any = await axiosInstance.get(getCommentByGoalIdUrl(goalId));
    if (response?.status !== HTTP_OK) {
      throw new Error('Something went wrong!');
    }
    return response?.data;
  },
);

export const saveComment = createAsyncThunk(
  'goal/saveComment',
  async (payload: { description: string; goalId: number }) => {
    try {
      const response: any = await axiosInstance.post(saveCommentUrl, payload);
      if (response?.status !== HTTP_OK) {
        throw new Error('Something went wrong!');
      }
      return response?.data;
    } catch (error) {
      if (typeof error === 'string') {
        throw defaultExceptionHandler({ message: error });
      } else {
        throw defaultExceptionHandler(error);
      }
    }
  },
);

export const editComment = createAsyncThunk(
  'goal/editComment',
  async (payload: { description: string; commentId: number }) => {
    try {
      const response: any = await axiosInstance.put(editCommentUrl(payload.commentId), {
        description: payload.description,
      });
      if (response?.status !== HTTP_OK) {
        throw new Error(response?.data?.data?.error);
      }
      return response?.data;
    } catch (error) {
      if (typeof error === 'string') {
        throw defaultExceptionHandler({ message: error });
      } else {
        throw defaultExceptionHandler(error);
      }
    }
  },
);

export const getKraKpis: any = createAsyncThunk(
  'goal/getKraKpis',
  async (payload: { resourceId: string }) => {
    const response = await axiosInstance.get<any>(getKraKpiUrl(payload));

    if (response.data?.tokenExpire) {
      authUpdateAndReload();
      return;
    }

    return response.data;
  },
);

export const updateGoals = createAsyncThunk(
  'goal/updateGoals',
  async (payload: UpdateGoalPayload) => {
    try {
      const response: any = await axiosInstance.put(saveGoalsUrl, payload);
      if (response?.status !== HTTP_OK) {
        throw new Error('Something went wrong!');
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  },
);

export const saveGoals = createAsyncThunk('goal/saveGoals', async (payload: SaveGoalPayload) => {
  try {
    const response: any = await axiosInstance.post(saveGoalsUrl, payload);
    if (response?.status !== HTTP_OK) {
      throw new Error('Something went wrong!');
    }
    return response?.data;
  } catch (error) {
    throw defaultExceptionHandler(error);
  }
});

export const acceptMenteeGoals = createAsyncThunk(
  'goal/acceptMenteeGoals',
  async (payload: AcceptGoals) => {
    try {
      const response: any = await axiosInstance.post(acceptGoalsUrl, payload);
      if (response?.status !== HTTP_OK) {
        throw new Error(response?.data?.data?.error as string);
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  },
);

export const goalReducer = createSlice({
  name: 'goal',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getGoals.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGoals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGoalAvailable = action?.payload?.data?.isGoalAvailable;
        state.goalModels = action?.payload?.data?.goalModels;
        state.timeStamp = action?.payload?.timestamp;
      })
      .addCase(getGoals.rejected, (state, action) => {
        state.isLoading = false;
        state.goalModels = [];
        state.isGoalAvailable = false;
      });
  },
});

export default goalReducer.reducer;
