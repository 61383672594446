import progressSvg from '../assets/Svg/progress.svg';
import teamSvg from '../assets/Svg/team.svg';
import calenderSvg from '../assets/Svg/calendar.svg';
import descriptionSvg from '../assets/Svg/description.svg';
import lastSyncSvg from '../assets/Svg/LastSync.svg';
import downloadArrowFileSvg from '../assets/Svg/DownloadArrow.svg';
import award from '../assets/Svg/Award.svg';
import nomineeName from '../assets/Svg/NomineeName.svg';
import nominatedBy from '../assets/Svg/NominatedBy.svg';
import reasonSvg from '../assets/Svg/ReasonSvg.svg';
import arrowDown from '../assets/Svg/ArrowDown.svg';
import activeDashboard from '../assets/Svg/ActiveDashboard.svg';
import inActiveDashboard from '../assets/Svg/InActiveDashboard.svg';
import activeClock from '../assets/Svg/ActiveClock.svg';
import inActiveClock from '../assets/Svg/InActiveClock.svg';
import activeMoney from '../assets/Svg/ActiveMoney.svg';
import inActiveMoney from '../assets/Svg/InActiveMoney.svg';
import activeProjectDetails from '../assets/Svg/ActiveProjectDetails.svg';
import inActiveProjectDetails from '../assets/Svg/InActiveProjectDetails.svg';
import activeTeam from '../assets/Svg/ActiveTeam.svg';
import inActiveTeam from '../assets/Svg/InActiveTeam.svg';
import eye from '../assets/Svg/eye.svg';
import addIcon from '../assets/Svg/add.svg';
import kraKpiIcon from '../assets/Svg/kra-kpi-sidebarIcon.svg';
import metricsLogo from '../assets/Svg/metrics-logo.svg';
import loginLayout from '../assets/Svg/login-layout.svg';
import googleLogo from '../assets/Svg/google-logo.svg';
import ComingSoon from '../assets/Svg/ComingSoon.svg';
import ShareIcon from '../assets/Svg/ShareIcon.svg';
import DeleteIcon from '../assets/Svg/DeleteIcon.svg';
import EditIcon from '../assets/Svg/EditIcon.svg';
import teamsIcon from '../assets/Svg/teams-icon.svg';
import dragIcon from '../assets/Svg/Drag.svg';
import plusIcon from '../assets/Svg/PlusIcon.svg';

import jiraIcon from '../assets/Svg/JiraIcon.svg';
import nonJiraIcon from '../assets/Svg/NonJiraIcon.svg';
import kanbanIcon from '../assets/Svg/KanbanIcon.svg';
import jiraIconPrimary from '../assets/Svg/JiraIconPrimary.svg';
import nonJiraIconPrimary from '../assets/Svg/NonJiraIconPrimary.svg';
import kanbanIconPrimary from '../assets/Svg/KanbanIconPrimary.svg';
import SpringRestIcon from '../assets/Svg/techIcons/spring-rest.svg';
import SpringJwtIcon from '../assets/Svg/techIcons/spring-jwt.svg';
import GraphQLIcon from '../assets/Svg/techIcons/graph-ql.svg';
import NodeExpressIcon from '../assets/Svg/techIcons/node-express.svg';
import AndroidJavaOption from '../assets/Svg/techIcons/android-java.svg';
import KotlinIcon from '../assets/Svg/techIcons/android-kotlin.svg';
import ReactNativeIcon from '../assets/Svg/techIcons/react-native.svg';
import AngularIcon from '../assets/Svg/techIcons/angular.svg';
import JavaIcon from '../assets/Svg/techIcons/java.svg';
import DjangoIcon from '../assets/Svg/techIcons/django.svg';
import ReactIcon from '../assets/Svg/techIcons/react.svg';
import AndroidKotlinIcon from '../assets/Svg/techIcons/android.svg';
import IosIcon from '../assets/Svg/techIcons/ios.svg';
import NodeJsIcon from '../assets/Svg/techIcons/node.svg';
import FlutterIcon from '../assets/Svg/techIcons/flutter.svg';
import JavascriptIcon from '../assets/Svg/techIcons/javascript.svg';
import TypeScriptIcon from '../assets/Svg/techIcons/typescript.svg';
import doubleTick from '../assets/Svg/doubleTick.svg';
import warning from '../assets/Svg/warning.svg';
import editPenIcon from '../assets/Svg/EditPenIcon.svg';
import ProjectCheck from '../assets/Svg/ProjectCheck.svg';
import deleteIcon from '../assets/Svg/delete.svg';
import renameIcon from '../assets/Svg/rename.svg';
import textIcon from '../assets/Svg/text.svg';
import metricsLogoAlt from '../assets/Svg/metrics-logo-alt.svg';
import epicoLogo from '../assets/Svg/epico-logo.svg';
import informationIcon from '../assets/Svg/information.svg';
import commentsIcon from '../assets/Svg/comment.svg';
import BluePluseIcon from '../assets/Svg/bluePluseIcon.svg';
import WarningIcon from '../assets/Svg/WarningIcon.svg';
import TwoPageIcon from '../assets/Svg/TwoPageIcon.svg';
import CancelRedOutlined from '../assets/Svg/CancelRedOutlined.svg';
import epico404 from '../assets/Svg/404Epico.svg';
import menuIconButton from '../assets/Svg/menuIconButton.svg';
import eyeIcon from '../assets/Svg/eyeIcon.svg';
import linkExpired from '../assets/Svg/LinkExpired.svg';
import worklogBgSvg from '../assets/Svg/WorklogBgSvg.svg';
import worklogSubmitted from '../assets/Svg/WorklogSubmitted.svg';
import downloadIcon from '../assets/Svg/downloadIcon.svg';
import eyeClose from '../assets/Svg/eye-close.svg';
import folderSvg from '../assets/Svg/FolderSvg.svg';
import calendarSvg from '../assets/Svg/CalendarSvg.svg';
import uploadIcon from '../assets/Svg/UploadIcon.svg';
import pieChartIcon from '../assets/Svg/PieChart.svg';
import datePickerIcon from '../assets/Svg/DatePickerIcon.svg';
import eyeBoxIcon from '../assets/Svg/eyeBoxIcon.svg';
import copyIcon from '../assets/Svg/copyIcon.svg';

export const svg = {
  award: award,
  nomineeName: nomineeName,
  nominatedBy: nominatedBy,
  reasonSvg: reasonSvg,
  progressSvg: progressSvg,
  teamSvg: teamSvg,
  calenderSvg: calenderSvg,
  descriptionSvg: descriptionSvg,
  lastSyncSvg: lastSyncSvg,
  downloadArrowFileSvg: downloadArrowFileSvg,
  arrowDown: arrowDown,
  activeDashboard: activeDashboard,
  inActiveDashboard: inActiveDashboard,
  activeClock: activeClock,
  inActiveClock: inActiveClock,
  activeMoney: activeMoney,
  inActiveMoney: inActiveMoney,
  activeProjectDetails: activeProjectDetails,
  inActiveProjectDetails: inActiveProjectDetails,
  activeTeam: activeTeam,
  inActiveTeam: inActiveTeam,
  eye: eye,
  addIcon: addIcon,
  kraKpiIcon: kraKpiIcon,
  loginLayout,
  metricsLogo,
  googleLogo,
  comingSoonIcon: ComingSoon,
  shareIcon: ShareIcon,
  DeleteIcon: DeleteIcon,
  EditIcon: EditIcon,
  projectCheckSvg: ProjectCheck,
  deleteIcon: deleteIcon,
  renameIcon: renameIcon,
  textIcon: textIcon,
  teamsIcon: teamsIcon,
  metricsLogoAlt: metricsLogoAlt,
  dragIcon: dragIcon,
  plusIcon: plusIcon,
  epicoLogo: epicoLogo,
  informationIcon: informationIcon,
  commentsIcon: commentsIcon,
  jiraIcon: jiraIcon,
  nonJiraIcon: nonJiraIcon,
  kanbanIcon: kanbanIcon,
  jiraIconPrimary: jiraIconPrimary,
  nonJiraIconPrimary: nonJiraIconPrimary,
  kanbanIconPrimary: kanbanIconPrimary,
  doubleTick: doubleTick,
  warning: warning,
  editPenIcon: editPenIcon,

  reactNativeIcon: ReactNativeIcon,
  angularIcon: AngularIcon,
  javaIcon: JavaIcon,
  djangoIcon: DjangoIcon,
  reactIcon: ReactIcon,
  androidKotlinIcon: AndroidKotlinIcon,
  iosIcon: IosIcon,
  nodeJsIcon: NodeJsIcon,
  flutterIcon: FlutterIcon,
  javascriptIcon: JavascriptIcon,
  typescriptIcon: TypeScriptIcon,
  springRestIcon: SpringRestIcon,
  springJwtIcon: SpringJwtIcon,
  graphQLIcon: GraphQLIcon,
  nodeExpressIcon: NodeExpressIcon,
  androidJavaOption: AndroidJavaOption,
  kotlinIcon: KotlinIcon,
  bluePluseIcon: BluePluseIcon,
  warningIcon: WarningIcon,
  twoPageIcon: TwoPageIcon,
  cancelRedOutlined: CancelRedOutlined,
  epico404: epico404,
  menuIconButton: menuIconButton,
  eyeIcon: eyeIcon,
  linkExpired: linkExpired,
  worklogBgSvg: worklogBgSvg,
  worklogSubmitted: worklogSubmitted,
  downloadIcon: downloadIcon,
  eyeClose: eyeClose,
  folderSvg: folderSvg,
  calendarSvg: calendarSvg,
  uploadIcon: uploadIcon,
  pieChartIcon: pieChartIcon,
  datePickerIcon: datePickerIcon,
  eyeBoxIcon: eyeBoxIcon,
  copyIcon: copyIcon,
};

export default svg;
