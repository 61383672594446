import React, { ReactElement, forwardRef, useRef, ForwardedRef } from "react";
import {
  Snackbar,
  Slide,
  useMediaQuery,
  useTheme,
  SlideProps,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { resetSnackBar } from "../../store/reducers/snackBarReducer";
import { severity } from "../../constants/app-constant";

// Define the props type for Alert
interface AlertForwardRefProps extends AlertProps {}

const Alert = forwardRef<HTMLDivElement, AlertForwardRefProps>(function Alert(
  props,
  ref: ForwardedRef<HTMLDivElement>
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackBar = (): ReactElement => {
  const dispatch = useDispatch();
  const alertRef = useRef<HTMLDivElement>(null);
  const {
    open,
    severity: selectedSeverity,
    message,
  } = useSelector((state: RootState) => state.snackBar);
  const theme = useTheme();
  const isMdup = useMediaQuery(theme.breakpoints.up("md"));
  const vertical = isMdup ? "bottom" : "top";
  const horizontal = "right";

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetSnackBar());
  };

  const TransitionLeft = (props: SlideProps) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <div>
      {open && (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
            TransitionComponent={TransitionLeft}
          >
            <Alert
              onClose={handleClose}
              severity={
                selectedSeverity === severity.success
                  ? "success"
                  : selectedSeverity === severity.error
                  ? "error"
                  : selectedSeverity === severity.warning
                  ? "warning"
                  : "info"
              }
              sx={{ width: "100%" }}
              ref={alertRef}
            >
              {message}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </div>
  );
};

export default CustomSnackBar;
