import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Kpi, Kra } from "../../../pages/KraAndKpi/Interface";
import axiosInstance from "../../../utils/httpInterceptor";
import { HTTP_OK } from "../../../constants/status-code-constant";
import { getKraAndKpiByDesignationUrl } from "../../../constants/api-urls";

interface Iquestion {
  id: number;
  question: string;
  description: string;
  weightage: number;
  isValid: boolean;
  isEdited?: boolean;
  isDeleted?: boolean;
}
interface IKraData {
  id: number;
  name: string;
  weightage: number;
  isValid: boolean;
  isEdited?: boolean;
  isDeleted?: boolean;
  questions: Iquestion[];
}
interface KraKpiState {
  isLoading: boolean;
  isSubmit: boolean;
  operation: string;
  accordionOpen: number | boolean;
  krasTotal: number;
  kras: Kra[];
  kraKpiByDesignation: IKraData[];
  editPayload: Kra[];
  selectedKra: Kra | null;
}

const initialState: KraKpiState = {
  isSubmit: false,
  isLoading: false,
  operation: "",
  accordionOpen: false,
  krasTotal: 0,
  kras: [],
  kraKpiByDesignation: [],
  editPayload: [],
  selectedKra: null,
};

export const getKraAndKpiByDesignationAsync = createAsyncThunk(
  `kra-kpi/getKraAndKpiByDesignation`,
  async (params: { designation: string }) => {
    try {
      const response = await axiosInstance.get(
        `${getKraAndKpiByDesignationUrl}${params.designation}`
      );

      if (response?.status !== HTTP_OK) {
        throw new Error(response?.data?.data?.error);
      }
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const kraKpiSlice = createSlice({
  name: "kra-kpi",
  initialState,
  reducers: {
    resetKraKpi: (state) => {
      state.kras = [];
    },
    resetEditPayload: (state) => {
      state.editPayload = [];
    },

    resetSelectedKra: (state) => {
      state.selectedKra = null;
    },

    setKraKpi: (state, action) => {
      state.kras = action.payload;
    },
    setEditPayload: (state, action) => {
      state.editPayload = action.payload.map((kra: Kra) => ({
        ...kra,
        isDeleted: false,
        isEdited: false,
        questions: kra.questions.map((kpi: Kpi) => ({
          ...kpi,
          isDeleted: false,
          isEdited: false,
          isCreated: false,
        })),
      }));
    },

    setKraSelected: (state, action) => {
      state.selectedKra = action.payload;
    },

    addNewKra: (state, action: PayloadAction<Kra>) => {
      const newKra = {
        ...action.payload,
        id: state.kras.length + 1,
      };
      state.kras.push(newKra);
      state.accordionOpen = newKra?.id;
    },
    deleteKra: (state, action) => {
      state.kras = state.kras.filter((kra) => kra?.id !== action.payload.kraId);
    },
    renameKra: (state, action) => {
      state.kras = state.kras.map((kra) =>
        kra?.id === action.payload?.kraId
          ? { ...kra, name: action.payload?.editedTitle }
          : kra
      );
    },

    addNewKpi: (state, action) => {
      state.kras.map((kra) => {
        if (kra?.id === action.payload?.kraId) {
          kra?.questions?.push(action.payload?.kpi);
          return kra;
        }
        return kra;
      });
    },
    deleteKpi: (state, action) => {
      state.kras = state.kras.map((kra) => {
        if (kra?.id === action.payload?.kraId) {
          return {
            ...kra,
            questions: kra?.questions?.filter(
              (kpi) => kpi?.id !== action.payload?.kpiId
            ),
          };
        }
        return kra;
      });
    },

    updateKpi: (state, action) => {
      const { kraId, kpiId, key, value } = action.payload;
      state.kras = state?.kras?.map((kra: Kra) => {
        if (kra?.id === kraId) {
          return {
            ...kra,
            questions: kra?.questions?.map((kpi: Kpi) => {
              if (kpi?.id === kpiId) {
                return { ...kpi, [key]: value };
              }
              return kpi;
            }),
          };
        }
        return kra;
      });
    },
    kraTotal: (state) => {
      state.kras?.map((kra) => {
        const totalWeightage = kra?.questions?.reduce(
          (sum: number, kpi: any) => {
            const weightage = parseInt(kpi?.weightage);
            return sum + (isNaN(weightage) ? 0 : weightage);
          },
          0
        );
        kra.weightage = totalWeightage;
        return kra;
      });
      state.krasTotal = state.kras?.reduce((sum: number, kra: any) => {
        return sum + kra?.weightage;
      }, 0);
    },
    setExpandedPanel(state, action) {
      state.accordionOpen = action.payload;
    },

    submitClicked: (state, action) => {
      state.isSubmit = action.payload;
    },
    setKpiValid: (state, action) => {
      state.kras = state.kras.map((kra) => {
        if (kra.id === action.payload?.kraId) {
          return {
            ...kra,
            questions: kra.questions.map((kpi) => {
              if (kpi.id === action.payload?.kpiId) {
                return {
                  ...kpi,
                  isValid: action.payload?.valid,
                };
              }
              return kpi;
            }),
          };
        }
        return kra;
      });
    },
    checkKraValid: (state) => {
      state.kras.map((kra) => {
        kra.isValid = kra.questions.every((kpi) => kpi.isValid);
        return kra;
      });
    },
    setOperation: (state, action) => {
      state.operation = action?.payload;
    },

    addNewKpiInPayload: (state, action) => {
      state.editPayload.map((kra) => {
        if (kra?.id === action.payload?.kraId) {
          kra?.questions?.push(action.payload?.kpi);
          return kra;
        }
        return kra;
      });
    },
    addNewKraInPayload: (state, action) => {
      const newKra = {
        ...action.payload,
        id: state.kras.length,
      };
      state.editPayload.push(newKra);
    },

    renameKraInPayload: (state, action) => {
      state.editPayload = state.editPayload?.map((kra) => {
        if (kra?.id === action.payload?.kraId) {
          if (kra?.isCreated === true)
            return { ...kra, name: action.payload?.editedTitle };
          else {
            //checking if kpis are updated or not
            const kpisEdited = kra?.questions?.some(
              (kpi) => kpi?.isEdited === true
            );
            return {
              ...kra,
              name: action.payload?.editedTitle,
              isEdited: action.payload?.isUpdated === true ? true : kpisEdited,
            };
          }
        }
        return kra;
      });
    },

    deleteKraInPayload: (state, action) => {
      state.editPayload = state.editPayload?.filter((kra) => {
        if (kra?.id === action.payload?.kraId) {
          if (kra?.isCreated === true) {
            return false; // Remove this KRA from the state
          } else {
            kra.name = action.payload?.editedTitle;
            kra.isDeleted = true;
          }
        }
        return true; // Keep this KRA in the state
      });
    },

    updateKpiInPayload: (state, action) => {
      const { kraId, kpiId, key, value } = action.payload;
      state.editPayload = state?.editPayload?.map((kra: Kra) => {
        if (kra?.id === kraId) {
          return {
            ...kra,
            questions: kra?.questions?.map((kpi: Kpi) => {
              if (kpi?.id === kpiId) {
                return {
                  ...kpi,
                  [key]: value,
                };
              }
              return kpi;
            }),
          };
        }
        return kra;
      });
    },
    setEditInPayload: (state, action) => {
      const { kraId, kpiId } = action.payload;
      state.editPayload = state?.editPayload?.map((kra: Kra) => {
        if (kra?.id === kraId) {
          return {
            ...kra,
            isEdited: kra?.isCreated === true ? false : true,

            questions: kra?.questions?.map((kpi: Kpi) => {
              if (kpi?.id === kpiId) {
                return {
                  ...kpi,
                  isEdited: kpi?.isCreated === true ? false : true,
                };
              }
              return kpi;
            }),
          };
        }
        return kra;
      });
    },
    setDeletedInPayload: (state, action) => {
      const { kraId, kpiId } = action.payload;

      const checkIsCreated = () => {
        let result;
        state?.editPayload?.map((kra: any) => {
          if (kra?.id === kraId) {
            kra?.questions?.map((kpi: any) => {
              if (kpi?.id === kpiId) {
                if (kpi?.isCreated === true) result = true;
                else result = false;
                return kpi;
              }
              return kpi;
            });
            return kra;
          }
          return kra;
        });
        return result;
      };

      state.editPayload = state?.editPayload?.map((kra) => {
        if (kra?.id === kraId) {
          return {
            ...kra,
            isEdited:
              kra?.isCreated === true ? false : checkIsCreated() ? false : true,
            questions: kra?.questions?.reduce((qAcc: any, kpi) => {
              if (kpi?.id === kpiId) {
                if (kra?.isCreated || kpi?.isCreated) {
                  // Skip adding this KPI as it should be removed
                  return qAcc;
                }
                // Mark the KPI as deleted
                qAcc.push({
                  ...kpi,
                  isDeleted: true,
                });
                return qAcc;
              }
              qAcc.push(kpi);
              return qAcc;
            }, []),
          };
        }
        return kra;
      });
    },
    kraTotalInPayload: (state) => {
      state.editPayload?.map((kra) => {
        const totalWeightage = kra?.questions?.reduce(
          (sum: number, kpi: any) => {
            const weightage = parseInt(kpi?.weightage);
            return sum + (isNaN(weightage) ? 0 : weightage);
          },
          0
        );
        kra.weightage = totalWeightage;
        return kra;
      });
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getKraAndKpiByDesignationAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getKraAndKpiByDesignationAsync.fulfilled,
        (state, action: any) => {
          state.kraKpiByDesignation = action?.payload;
          state.isLoading = false;
        }
      )
      .addCase(getKraAndKpiByDesignationAsync.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  resetKraKpi,
  resetEditPayload,
  resetSelectedKra,
  setKraSelected,
  addNewKra,
  renameKra,
  deleteKra,
  addNewKpi,
  deleteKpi,
  updateKpi,
  kraTotal,
  setExpandedPanel,
  submitClicked,
  setKpiValid,
  checkKraValid,
  setKraKpi,
  setOperation,
  setEditInPayload,
  setDeletedInPayload,
  updateKpiInPayload,
  setEditPayload,
  addNewKpiInPayload,
  addNewKraInPayload,
  kraTotalInPayload,
  renameKraInPayload,
  deleteKraInPayload,
} = kraKpiSlice.actions;
export default kraKpiSlice.reducer;
