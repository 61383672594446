import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserDetails } from "../../services";
import { HTTP_OK } from "../../constants/status-code-constant";
import { defaultExceptionHandler } from "../../utils/defaultExcpetionHandler";
import axiosInstance from "../../utils/httpInterceptor";
import {
  changeKanbanTaskStatusUrl,
  getBoardSettingListUrl,
  getTaskListWithFiltersUrl,
  kanbanTaskListUrl,
} from "../../constants/api-urls";
import { deleteTasksByProjectStatusIds } from "../../components/KanbanModule/utils";
import { getTaskListWithFilters } from "../../services/kanbanService";
import _ from "lodash";


interface WorklogData {
  timeSpentSeconds: number | string;
  timeSpent: number | string;
  comment: string;
  id: number | string;
  projectId: number | string;
  kanbanProjectId: number | string;
  taskId: number | string;
  resourceId: number | string;
  createdAt: number | string;
  created: number | string;
  started: string;
  lastModifiedAt: number | string;
  onLeave: boolean;
  onHoliday: boolean;
  timeZone: number | string;
  resourceName: string;
  deleted: boolean;
}

interface UserState {
  taskList: Array<any>;
  statusList: {
    projectNameOptionalInTask?: boolean;
    statusModels?: Array<any>;
  };
  allTaskData: Array<any>;
  taskPaginationData: Object;
  isLoading: boolean;
  taskId: string | number;
  projectId: string | number;
  editWorklogdata: WorklogData | undefined;
}

export const getKanbanTaskList = createAsyncThunk(
  "kanban/getKanbanTaskList",
  async (params: { id: string | number }) => {
    try {
      const response = await axiosInstance.get(
        `${kanbanTaskListUrl}/${params.id}`
      );

      if (response?.status !== HTTP_OK) {
        throw new Error(response?.data?.data?.error);
      }
      return response?.data?.data;
    } catch (error: any) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getBoardSettingList = createAsyncThunk(
  "kanban/getBoardSettingList",
  async (params: { id: string | number }) => {
    try {
      const response = await axiosInstance.get(
        `${getBoardSettingListUrl}?projectId=${params.id}`
      );

      if (response?.status !== HTTP_OK) {
        throw new Error(response?.data?.data?.error);
      }
      return response?.data?.data;
    } catch (error: any) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const changeKanbanTaskStatus = createAsyncThunk(
  "kanban/changeKanbanTaskStatus",
  async (params: { payload: any }) => {
    try {
      const response = await axiosInstance.post(
        `${changeKanbanTaskStatusUrl}`,
        params.payload
      );

      if (response?.status !== HTTP_OK) {
        throw new Error(response?.data?.data?.error);
      }
      return response?.data?.data;
    } catch (error: any) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const callSpecificKanbanColumn = createAsyncThunk(
  "kanban/callSpecificKanbanColumn",
  async (
    params: { ids: Array<number>; projectId: number | string },
    { getState, dispatch }
  ) => {
    const state: any = getState();
    try {  
        let pagination = { ..._.cloneDeep(state?.kanban?.taskPaginationData) };

        let allList = deleteTasksByProjectStatusIds(state?.kanban?.allTaskData, params.ids);
        params.ids.map(id => {
          let payload = {
            searchStr: null,
            resourceId: null,
            isMyTasks: false,
            deletedTasks: false,
            statusId: id,
            kanbanProjectId: params.projectId,
          };
          getTaskListWithFilters({
            payload: payload,
            page: 0,
          })
            .then(response => {
              allList = [...allList, ...response.models];
              let object = { ...response };
              delete object.models;
              object.page = 0;
              pagination = {
                ...pagination,
                [id]: { ...object },
              };
            })
            .catch(err => {
              throw defaultExceptionHandler(err);
            })
            .finally(() => {
              dispatch(setTaskPaginationData(pagination));
              dispatch(setAllTaskData(allList));
            });
        });
    } catch (error: any) {
      throw defaultExceptionHandler(error);
    }
  }
);

const initialState: UserState = {
  taskList: [],
  statusList: {
    projectNameOptionalInTask: false,
    statusModels: [],
  },
  allTaskData: [],
  taskPaginationData: {},
  isLoading: false,
  taskId: "",
  projectId: "",
  editWorklogdata: undefined,
};

export const kanbanReducer = createSlice({
  name: "kanban",
  initialState,
  reducers: {
    setTaskId: (state, action) => {
      state.taskId = action.payload?.taskId;
      state.projectId = action.payload?.projectId;
    },
    setEditWorklogData: (state, action) => {
      state.editWorklogdata = action.payload;
    },
    setAllTaskData: (state, action) => {
      state.allTaskData = action.payload;
    },
    setTaskPaginationData: (state, action) => {
      state.taskPaginationData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKanbanTaskList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getKanbanTaskList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.taskList = action?.payload;
      })
      .addCase(getKanbanTaskList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changeKanbanTaskStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changeKanbanTaskStatus.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changeKanbanTaskStatus.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getBoardSettingList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBoardSettingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.statusList = action.payload;
      })
      .addCase(getBoardSettingList.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { setTaskId, setAllTaskData, setTaskPaginationData, setEditWorklogData } =
  kanbanReducer.actions;
export default kanbanReducer.reducer;
