import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  FormHelperText,
  Stack,
  Icon,
  InputAdornment,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { StyledMenuItem } from './style';
import { ExpandMoreRounded } from '@mui/icons-material';
import { gray, mecGrey, red } from '../../constants/theme/colors';
import UserAvatar from '../Avatar';

const DEFAULT_VALUE = '';

interface MuiBasicOutlinedSelectProps {
  sx?: SxProps<Theme>;
  options?: any[];
  name?: string;
  value?: string;
  placeholder?: string;
  isRequired?: boolean;
  disabled?: boolean;
  onChange?: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void) | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: boolean;
  label?: string;
  endAdornment?: any;
  endIcon?: React.ElementType<any>;
  height?: string;
  helperText?: string;
  userAvatar?: boolean;
}

const MuiBasicOutlinedSelect = ({
  sx = {},
  options = [],
  isRequired,
  disabled,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  error,
  label,
  endAdornment,
  endIcon,
  height = '38px',
  helperText,
  userAvatar,
}: MuiBasicOutlinedSelectProps) => {
  const theme = useTheme();
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <Box sx={{ minWidth: 120 }} className="flex flex-col space-y-0.5">
      <Typography variant="mec_body1" className="!mb-1">
        {label}
        {isRequired ? (
          <Typography variant="mec_body1" component="span" color={red[650]}>
            {"*"}
          </Typography>
        ) : null}
      </Typography>
      <FormControl fullWidth error={error}>
        {!value && (
          <Box
            className="placeholder text-gray font-normal absolute w-full left-0 top-0 z-10 flex items-center pl-4 cursor-pointer mt-[2px]"
            sx={{ height }}
            onClick={() => (disabled ? null : setOpenMenu(true))}
          >
            <Typography variant="mec_subtitle1" color={gray[700]} className="opacity-40">
              {placeholder}
            </Typography>
          </Box>
        )}
        <Select
          id={`mui-basic-outlined-${name}`}
          name={name}
          value={value ?? DEFAULT_VALUE}
          onChange={onChange}
          onBlur={onBlur}
          onOpen={() => setOpenMenu(true)}
          onClose={() => setOpenMenu(false)}
          open={openMenu}
          fullWidth
          placeholder={placeholder}
          IconComponent={endIcon ?? ExpandMoreRounded}
          endAdornment={
            endAdornment && <InputAdornment position="start">{endAdornment}</InputAdornment>
          }
          sx={{
            height: height,
            borderRadius: '10px',
            border: `1px solid ${mecGrey[300]}`,
            boxShadow: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            fontSize: '14px',
            ...sx,
          }}
          MenuProps={{
            PaperProps: {
              className: 'shadow-lg',
            },

            MenuListProps: {
              sx: {
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
                rowGap: 1,
                maxHeight: '320px',
              },
            },
          }}
          disabled={disabled}
        >
          {options?.map((item, index) => (
            <StyledMenuItem value={item.value} key={item.value} disabled={item?.disabled}>
              <div>
                <Typography variant="menuItem" sx={{ display: 'flex', alignItems: 'center' }}>
                  {userAvatar && (
                    <div className="mr-4">
                      <UserAvatar key={index as number} name={item.label} />
                    </div>
                  )}
                  {item.label}
                  {endAdornment && (
                    <Icon>
                      {
                        <item.icon
                          sx={{
                            marginLeft: '5px',
                            color: item.color,
                            fontSize: '22px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        />
                      }
                    </Icon>
                  )}
                </Typography>
              </div>
            </StyledMenuItem>
          ))}
        </Select>
        {error && helperText ? (
          <FormHelperText>
            <Stack className="h-0">
              <Typography variant="mec_errorHelperText">{helperText}</Typography>
            </Stack>
          </FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default MuiBasicOutlinedSelect;
