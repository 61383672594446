import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedProjectState {
  selectedProject: string;
}

const initialState: SelectedProjectState = {
  selectedProject: "Mindbowser",
};

const selectedProjectReducer = createSlice({
  name: "selectedProject",
  initialState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<string>) => {
      state.selectedProject = action.payload;
    },
  },
});

export const { setSelectedProject } = selectedProjectReducer.actions;
export default selectedProjectReducer.reducer;
