import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { StyledAvatar } from "./style";
import { Tooltip } from "@mui/material";
import { mecPrimary } from "../../constants/theme/colors";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const UserAvatar = ({
  name,
  selected = false,
  isFilter = false,
  onClick = () => {},
}: {
  name: string;
  selected?: boolean;
  isFilter?: boolean;
  onClick?: () => void;
}) => {
 function stringAvatar(name: string) {
   const nameParts = name.trim().split(" ");
   const firstInitial = nameParts[0]?.[0] || "";
   const secondInitial = nameParts[1]?.[0] || "";

   return {
     sx: {
       bgcolor: stringToColor(name),
       transition: 'transform 0s ease',
       backgroundColor: `${selected ? mecPrimary[500] : mecPrimary[100]} !important`,
       color: `${selected ? mecPrimary[100] : mecPrimary[500]} !important`,
       ':hover': {
         transform: isFilter ? 'translateY(-10px)' : '',
       },
     },
     children: `${firstInitial}${secondInitial}`,
   };
 }
  return (
    <Stack direction="row" spacing={2}>
      <Tooltip title={name}>
        <StyledAvatar {...stringAvatar(name)} onClick={onClick} />
      </Tooltip>
    </Stack>
  );
};
export default UserAvatar;
