export enum PROJECT_TYPE {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  KANBAN = 'KANBAN',
}

export enum COST_TYPE {
  TANDM = 'TANDM',
  FIXED_COST = 'FIXED_COST',
}

export enum STATUS {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  UPCOMING = 'UPCOMING',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum ROLE {
  FRONTEND = 'FRONTEND',
  BACKEND = 'BACKEND',
  FULLSTACK = 'FULLSTACK',
  PM = 'PM',
  DEVOPS = 'DEVOPS',
  QA = 'QA',
  DESIGNER = 'DESIGNER',
  MOBILE = 'MOBILE',
  DATASCIENCE = 'DATASCIENCE',
  HR = 'HR',
  SALES = 'SALES',
  ADMIN = 'ADMIN',
}
