import { Typography } from "@mui/material";
import svg from "../../assets";
import { mecGrey } from "../../constants/theme/colors";

const ComingSoon = () => {
 
  return (
    <div className="flex flex-col items-center w-full px-8 py-8">
      <img
        src={svg["comingSoonIcon"]}
        alt="Coming Soon"
        height={300}
        width={300}
      />
      <Typography variant="mec_bold" color={mecGrey[600]}>
        Coming Soon
      </Typography>

      <Typography variant="mec_medium500" sx={{ marginTop: "10px" }}>
        Exciting new features and updates are on the way!
      </Typography>
      <Typography variant="mec_medium500">
        Stay tuned for more information.
      </Typography>
    </div>
  );
};

export default ComingSoon;
