import React, { useState, useEffect, useRef } from 'react';
import { Box, Checkbox, Chip, Radio, Switch, Typography, styled } from '@mui/material';

import CustomDialog from '../../../components/Dialog';
import InputField from '../../../components/InputField';
import { gray, mecGrey, red } from '../../../constants/theme/colors';
import CloseIcon from '@mui/icons-material/Close';
import StyledSwitch from '../../../components/Switch';
import { optionsType, FilterProfitLossProjectDialogProps } from '../../../constants/app-constant';
import AutocompleteMultiSelectWithCheckBox from '../../../components/AutocompleteMultiSelectWithCheckBox';
import {
  createProfitLossFilters,
  getResourcesForFilter,
  updateProfitLossFilter,
} from '../../../services/profitLossService';
import {
  SelectedChipsBox,
  StyledChip,
  StyledProfitLossDialogBox,
} from '../../../pages/ProfitLoss/style';
import { setSnackBar } from '../../../store/reducers/snackbar';
import { severity } from '../../../constants/const';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  clearFilterState,
  setCurrentFilter,
} from '../../../store/reducers/profitLossFilterReducer';
import { capitalizeFirstLetter } from '../../../utils';

const FilterProfitLossProjectDialog: React.FC<FilterProfitLossProjectDialogProps> = ({
  open,
  onClose,
}) => {
  const currentFilter = useAppSelector(state => state?.profitLossFilterReducer?.currentFilter);
  const dispatch = useAppDispatch();

  const [filterName, setFilterName] = useState<string>(
    currentFilter?.title ? currentFilter?.title : '',
  );
  const [selectedOptions, setSelectedOptions] = useState<optionsType[]>([]);
  const [filterType, setFilterType] = useState<string>('PRIVATE');
  const [options, setOptions] = useState<optionsType[]>([]);

  useEffect(() => {
    loadResources();
  }, []);
  useEffect(() => {
    if (currentFilter) {
      setFilterName(currentFilter.title);
      setFilterType(currentFilter.status);
      setSelectedOptions(
        currentFilter.resourceIds?.map(
          (id: number) =>
            options?.find(option => option.value === id) || {
              label: '',
              value: id,
            },
        ),
      );
    }
  }, [currentFilter]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterName(value);
  };

  const loadResources = async () => {
    try {
      const res = await getResourcesForFilter();

      const data = res?.data?.data || [];

      const newOptions: optionsType[] = data?.map((item: any) => ({
        label: `${capitalizeFirstLetter(item.firstName)} ${capitalizeFirstLetter(item.lastName)}`,
        value: item.id,
      }));

      setOptions(newOptions);
    } catch (error) {
      console.log(`Error while fetching resources`, error);
    }
  };

  const handleOptionsChange = (event: any, newValue: optionsType[]) => {
    setSelectedOptions(newValue);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(event.target.checked ? 'PUBLIC' : 'PRIVATE');
  };

  const isFormValid = filterName.trim() !== '' && selectedOptions?.length > 0;

  const handleAddButtonClick = async () => {
    const payload = {
      title: filterName,
      resourceIds: selectedOptions.map(option => option.value),
      status: filterType,
    };

    try {
      let res;
      if (!currentFilter) {
        res = await createProfitLossFilters(payload);
      } else {
        const updatedPayload = { id: currentFilter?.id, ...payload };
        res = await updateProfitLossFilter(updatedPayload);
      }

      if (res?.status === 200) {
        dispatch(
          setSnackBar({
            severity: severity?.success,
            message: res?.data?.data,
          }),
        );
      }

      clearStatesAndClose();
    } catch (error) {
      console.log(`Error creating filter`, error);
    }
  };
  const clearStatesAndClose = () => {
    setFilterName('');
    setSelectedOptions([]);
    setFilterType('PRIVATE');
    dispatch(clearFilterState());
    onClose();
  };
  return (
    <CustomDialog
      dialogId="filterProjectsDialog"
      dialogButtonElement={<button onClick={clearStatesAndClose}></button>}
      dialogTitle="Create Filter"
      showCloseButton={true}
      cancelButtonLabel="Cancel"
      confirmButtonLabel={currentFilter ? 'Update' : 'Add'}
      disableButton={!isFormValid}
      showConfirmButton={true}
      showCancelButton={true}
      submitButtonType="submit"
      dialogWidth="40%"
      dialogHeight="auto"
      onClose={clearStatesAndClose}
      onCancelButtonClick={clearStatesAndClose}
      onConfirmButtonClick={handleAddButtonClick}
      children={
        <Box className="flex flex-col gap-2 px-1">
          {/* filter name */}
          <Box>
            <Typography variant="mec_body1" sx={{ mb: '10px' }}>
              Filter Name <span style={{ color: red[500] }}>*</span>
            </Typography>
            <InputField
              id="name-input"
              name="filterName"
              type="text"
              placeholder="Enter team name"
              onChange={handleInputChange}
              value={filterName}
              fullWidth={true}
              helperText={'Filter name is required'}
              error={false}
              sx={{
                '&:hover': {
                  cursor: 'default',
                },
                mt: '5px',
              }}
            />
          </Box>

          {/* select resources */}
          <Box>
            <Typography variant="mec_body1">
              Resources <span style={{ color: red[500] }}>*</span>
            </Typography>
            <Box className={`border border-[${mecGrey[100]}] rounded-lg p-2 mt-1`}>
              <AutocompleteMultiSelectWithCheckBox
                options={options}
                value={selectedOptions}
                onChange={handleOptionsChange}
                placeholder="Select resources"
              />

              {/* render selected chips */}
              {selectedOptions?.length > 0 && (
                <SelectedChipsBox>
                  {selectedOptions?.map(option => (
                    <StyledChip
                      key={option.value}
                      label={option.label}
                      onDelete={() =>
                        setSelectedOptions(prev => prev.filter(o => o.value !== option.value))
                      }
                      variant="outlined"
                      deleteIcon={<CloseIcon />}
                    />
                  ))}
                </SelectedChipsBox>
              )}
            </Box>
          </Box>

          <Box>
            <Typography variant="mec_body1">Visibility</Typography>
            <StyledProfitLossDialogBox>
              <Box className="flex flex-col gap-1">
                <Typography variant="mec_body1">Public</Typography>
                <Typography variant="mec_body3">
                  Anyone with the access to P&L tab can access this filter.
                </Typography>
              </Box>
              <Box>
                <StyledSwitch checked={filterType === 'PUBLIC'} onChange={handleSwitchChange} />
              </Box>
            </StyledProfitLossDialogBox>
          </Box>
        </Box>
      }
    />
  );
};

export default FilterProfitLossProjectDialog;
