import styled from "@emotion/styled";
import { TableCell, TableContainer, TableHead } from "@mui/material";
import { mecGrey, themePalette } from "../../../constants/theme/colors";

export const StyledTableContainer = styled(TableContainer)({
  backgroundColor: "white",
  borderBottom: `1px solid ${mecGrey[100]}`,
});

export const StyledTableHead = styled(TableHead)({
  backgroundColor: themePalette.palette.common.white,
  borderBottom: `1px solid ${mecGrey[100]}`,
});

export const StyledTableCellHead = styled(TableCell)({
  padding: "12px 16px",
  fontWeight: "bold",
  textAlign: "center",
});

export const StyledTableCellBody = styled(TableCell)({
  padding: "14px 16px",
  borderBottom: "none",
});

export const tableCellOverridesTop = {
  "&.MuiTableCell-root": {
    padding: "0px 16px 14px 16px",
  },
};
export const tableCellOverridesBottom = {
  "&.MuiTableCell-root": {
    padding: "15px 16px 0px 16px",
  },
};
